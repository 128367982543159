import React, { JSX, FC } from "react";
import { Tooltip } from "antd";

interface ITextWithTooltipProps {
  text: string | null | undefined;
  className?: string;
  length?: number;
}

const TextWithTooltip: FC<ITextWithTooltipProps> = ({
  text,
  className = "",
}): JSX.Element => {
  return (
    !!text && (
      <Tooltip title={text}>
        <span className={`inline ${className}`}>
          {text}
        </span>
      </Tooltip>
    )
  );
};

export default TextWithTooltip;
