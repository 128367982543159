import React, { JSX, FC, useEffect } from "react";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { AppStateType } from "redux/reducers/mainReducer";
import { RangePickerProps } from "antd/es/date-picker";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { ProductsFiltersValuesType } from "app/types";
import { formatPrice } from "utils/formatPrice";

// components
import { Collapse, CollapseProps, DatePicker, Form, FormInstance, Slider } from "antd";
import FiltersCheckbox from "./FiltersCheckbox";
import FilterWrapper from "../../ui/Filters/FiltersDrawer/FilterWrapper";
import CategoryFilters from "./CategoryFilters";

interface IProductsFiltersProps {
  form: FormInstance<ProductsFiltersValuesType>;
  values: ProductsFiltersValuesType;
}

const productStatuses: { key: string, label: string, name: string }[] = [
  { key: "1", label: "Размещен", name: "published", },
  // { key: "2", label: "На модерации", value: "moderation", }, TODO на данный момент не используется
  // { key: "3", label: "Отклонен", value: "rejected", }, TODO на данный момент не используется
  // { key: "4", label: "Требует обновления", value: "needs_update", }, TODO на данный момент не используется
  { key: "5", label: "Ошибка", name: "error", },
  { key: "6", label: "Черновик", name: "draft", },
];

const ProductsFilters: FC<IProductsFiltersProps> = ({ form, values }): JSX.Element => {
  const { minPrice, maxPrice } = useSelector((state: AppStateType) => state.productsSlice.products);

  useEffect(() => {
    if (values?.price?.some((el: number) => el === null)) {
      form.setFieldsValue({ price: [minPrice, maxPrice] });
    }
  }, [values, minPrice, maxPrice]);

  const renderStatuses = (): JSX.Element[] => productStatuses.map(({ key, label, name }) => {
    const handleChange = (e: CheckboxChangeEvent): void => form.setFieldsValue({ [name]: e.target.checked });

    return (
      <FiltersCheckbox
        key={key}
        label={label}
        name={name}
        checked={values?.[name]}
        handleChange={handleChange}
      />
    );
  });

  const collapseItems: CollapseProps["items"] = [
    {
      key: "1",
      label: "По статусу",
      children: renderStatuses(),
      className: "p-0 font-bold",
    },
  ];

  const disabledDate: RangePickerProps["disabledDate"] = (current: dayjs.Dayjs): boolean => current > dayjs();

  return (
    <div>
      <Collapse
        ghost
        defaultActiveKey={["1"]}
        items={collapseItems}
        bordered={false}
        expandIconPosition="end"
        className="mb-5"
      />
      <FilterWrapper
        label="По цене"
        value={
          <span className="secondary-color">
            {formatPrice(values?.price?.[0] ?? minPrice ?? 0)} ₽ - {formatPrice(values?.price?.[1] ?? maxPrice ?? 0)} ₽
          </span>
        }
      >
        <Form.Item className="m-0" name="price" initialValue={[minPrice, maxPrice]}>
          <Slider
            range
            step={0.1}
            min={minPrice}
            max={maxPrice}
            styles={{
              track: { background: "#4786FF" }
            }}
          />
        </Form.Item>
      </FilterWrapper>
      <CategoryFilters/>
      <FilterWrapper label="По дате обновления товара">
        <Form.Item className="m-0" name="date" initialValue={null}>
          <DatePicker.RangePicker
            className="w-full"
            size="large"
            placeholder={["С даты", "По дату"]}
            defaultPickerValue={[dayjs().add(-1, "M"), dayjs()]}
            disabledDate={disabledDate}
            format="DD.MM.YYYY"
          />
        </Form.Item>
      </FilterWrapper>
    </div>
  );
};

export default ProductsFilters;
