import React, { JSX, FC, Dispatch, SetStateAction } from "react";
import { StaticFiltersType } from "../../../Catalog/TableFilters/utils/staticFilters";
import { Tag } from "antd";

import css from "./style.module.css";

interface IFiltersStaticTagsProps {
  staticFilters: StaticFiltersType[];
  setStaticFilters: Dispatch<SetStateAction<StaticFiltersType[]>>
  setIsFiltersConfirmed: Dispatch<SetStateAction<boolean>>;
  isSingleActiveMode?: boolean; // режим тогглера
}

const FiltersStaticTags: FC<IFiltersStaticTagsProps> = ({
  staticFilters,
  setStaticFilters,
  setIsFiltersConfirmed,
  isSingleActiveMode = false,
}): JSX.Element => {
  const handleTagAction = (filterName: string, action: "add" | "remove"): void => {
    const filter: StaticFiltersType = staticFilters.find(({ name }): boolean => name === filterName);

    if (!filter) return;

    const isActive: boolean = filter.isActive;
    const shouldAdd: boolean = action === "add" && !isActive;
    const shouldRemove: boolean = action === "remove" && isActive;

    if (!shouldAdd && !shouldRemove) return;

    const updatedFilters: StaticFiltersType[] = staticFilters.map((filter) => {
      if (isSingleActiveMode) {
        // Если включен режим тогглера, активен только один тег
        return {
          ...filter,
          isActive: filter.name === filterName ? action === "add" : false,
        };
      }

      if (filter.name === filterName) {
        return {
          ...filter,
          isActive: action === "add"
        };
      }

      return filter;
    });

    setStaticFilters(updatedFilters);
    // нулевая задержка для того, чтобы успели измениться staticFilters и запросы уходили корректно
    setTimeout(() => {
      setIsFiltersConfirmed(true);
    }, 0);
  };

  return (
    <div className="flex flex-wrap gap-2">
      {staticFilters.map(({ name, label, isActive }) => (
        <Tag
          key={name}
          className={`${css.commonTagStyle} ${isActive ? css.activeFilters : css.quickFilters}`}
          closable={isActive}
          onClick={() => handleTagAction(name, "add")}
          onClose={() => handleTagAction(name, "remove")}
        >
          <div className={isActive ? "mr-1" : ""}>{label}</div>
        </Tag>
      ))}
    </div>
  );
};

export default FiltersStaticTags;
