import React, {JSX, FC, Dispatch, SetStateAction} from "react";
import { TabModeType } from "redux/slices/importTaskSlice";
import { useSelector } from "react-redux";
import { AppStateType } from "redux/reducers/mainReducer";
import { StaticFiltersType } from "../../TableFilters/utils/staticFilters";
import FiltersStaticTags from "../../../ui/Filters/FiltersStaticTags/FiltersStaticTags";

interface IUploadingProductsFiltersPanelProps {
  periodicStaticFilters: StaticFiltersType[];
  sourcesStaticFilters: StaticFiltersType[];
  setPeriodicStaticFilters: Dispatch<SetStateAction<StaticFiltersType[]>>;
  setSourcesStaticFilters: Dispatch<SetStateAction<StaticFiltersType[]>>;
  setIsFiltersConfirmed: Dispatch<SetStateAction<boolean>>;
}

const UploadingProductsFiltersPanel: FC<IUploadingProductsFiltersPanelProps> = ({
  periodicStaticFilters,
  sourcesStaticFilters,
  setPeriodicStaticFilters,
  setSourcesStaticFilters,
  setIsFiltersConfirmed,
}): JSX.Element => {
  const currentTable: TabModeType = useSelector((state: AppStateType) => state.importTaskSlice)?.currentTable;

  return (
    <div className="mt-3">
      {currentTable === "importTasks" && (
        <div className="flex">
          <FiltersStaticTags
            staticFilters={periodicStaticFilters}
            setStaticFilters={setPeriodicStaticFilters}
            setIsFiltersConfirmed={setIsFiltersConfirmed}
          />
        </div>
      )}
      {currentTable === "sources" && (
         <FiltersStaticTags
           staticFilters={sourcesStaticFilters}
           setStaticFilters={setSourcesStaticFilters}
           setIsFiltersConfirmed={setIsFiltersConfirmed}
         />
      )}
    </div>
  );
};

export default UploadingProductsFiltersPanel;
