import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { AppStateType } from "redux/reducers/mainReducer";
import { Input, Select } from "antd";
import { CurrencyCharCodeType, CurrencyType } from "currencies/types";

export interface PriceValueType {
  price?: number;
  currency?: CurrencyCharCodeType;
}

interface PriceInputProps {
  id?: string;
  value?: PriceValueType;
  onChange?: (value: PriceValueType) => void;
  onFocus?: () => void;
  isDisabled?: boolean;
}

const PriceInput: React.FC<PriceInputProps> = ({
  id,
  value = {},
  onChange,
  onFocus,
  isDisabled
}) => {
  const { list: currenciesList } = useSelector((state: AppStateType) => state.currenciesSlice);

  const [price, setPrice] = useState<string>(value?.price !== undefined ? String(value.price) : "");
  const [currency, setCurrency] = useState<CurrencyCharCodeType>(value?.currency || null);

  useEffect(() => {
    const { price, currency } = value || {};
    setPrice(price !== undefined ? String(price) : "");
    setCurrency(currency);
  }, [value]);

  const triggerChange = (changedValue: { price?: number; currency?: CurrencyCharCodeType }): void => {
    onChange?.({
      price: price === "" ? undefined : parseFloat(price.replace(",", ".")),
      currency,
      ...value,
      ...changedValue
    });
  };

  const onPriceBlur = (): void => {
    triggerChange({ price: price === "" ? undefined : parseFloat(price.replace(",", ".")) });
  };

  const onNumberChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const newValue: string = e.target.value.replace(",", ".");

    if (newValue === "" || /^\d*(\.\d{0,2})?$/.test(newValue)) {
      if (newValue === "0" || newValue.startsWith("0") && newValue !== "0.") return;
      setPrice(newValue);
    }
  };

  const onCurrencyChange = (newCurrency: CurrencyCharCodeType): void => {
    if (!("currency" in value)) setCurrency(newCurrency);

    triggerChange({ currency: newCurrency });
  };

  return (
    <span id={id} className="flex gap-2 items-end">
      <Input
        type="text"
        size="large"
        placeholder="Введите цену"
        value={price}
        onChange={onNumberChange}
        onFocus={onFocus}
        onBlur={onPriceBlur}
        disabled={!!isDisabled}
      />
      <Select
        size="large"
        placeholder="Выберите валюту"
        style={{ minWidth: 180, maxWidth: 200 }}
        value={currency}
        options={currenciesList?.map((currency: CurrencyType) => ({
          value: currency.char_code,
          label: currency.name
        }))}
        onChange={onCurrencyChange}
        onFocus={onFocus}
        disabled={!!isDisabled}
      />
    </span>
  );
};

export default PriceInput;
