import { FC, useMemo } from "react";
import { FormInstance, useWatch } from "antd/es/form/Form";
import { Modal, Form, Checkbox, Radio } from "antd";

import css from "./style.module.scss";
import { KboOrEulaType } from "terms/types";

interface IModalTermsProps {
  isOpen: boolean;
  title: string;
  description?: string;
  okHandler: (values: any) => void;
  cancelHandler: () => void;
  isOkDisabled?: boolean;
  needAccept?: boolean;
  data: KboOrEulaType;
  form: FormInstance<any>;
  initialValues?: any;
  className?: string;
}
const ModalTerms: FC<IModalTermsProps> = ({
  isOpen,
  title,
  description,
  okHandler,
  cancelHandler,
  isOkDisabled = false,
  needAccept = false,
  data,
  form,
  initialValues,
  className,
}) => {
  const values = useWatch<FormInstance<any>>([], form);
  const isDisabled: boolean = useMemo(() => (
    needAccept
      ? !values?.isAccepted || isOkDisabled
      : isOkDisabled
  ), [needAccept, values, isOkDisabled]);

  return (
    <Modal
      open={isOpen}
      onOk={() => okHandler(values)}
      onCancel={cancelHandler}
      title={title}
      okText="Подтвердить"
      cancelText="Отменить"
      wrapClassName="overflow-auto"
      className={css.modal_wrapper}
      style={{ minWidth: "min-content" }}
      styles={{ content: { maxHeight: "720px" } }}
      centered
      okButtonProps={{ size: "large", disabled: isDisabled }}
      cancelButtonProps={{ size: "large" }}
    >
      <div className="flex flex-col gap-2">
        {description && (
          <div className={css.infoText}>
            {description}
          </div>
        )}

        <div className={`${css.body} ${css?.[className]} p-2`}>
          <div dangerouslySetInnerHTML={{ __html: data?.docText }}></div>
        </div>

        <Form form={form} initialValues={initialValues}>
          <Form.Item name="id" hidden>
            <input type="hidden" />
          </Form.Item>
          {needAccept ? (
            <Form.Item name="isAccepted" valuePropName="checked" className="m-0 p-0">
              <Checkbox>Согласен с условиями оферты</Checkbox>
            </Form.Item>
          ) : (
            <Form.Item name="otherDocResponse" className="m-0 p-0">
              <Radio.Group className="w-full">
                {data?.otherDocsResponseOptions?.map((option, idx) => (
                  <Radio.Button key={idx} value={option}>{option}</Radio.Button>
                ))}
              </Radio.Group>
            </Form.Item>
          )}
        </Form>
      </div>
    </Modal>
  );
};

export default ModalTerms;
