import { DraggerFileType } from "./DragAndDrop";
import { ImageType } from "products/types";

export const transformLoadedFiles = (files: ImageType[]): DraggerFileType[] => {
  return files?.map((file: ImageType) => {
    return {
      uid: file.id.toString(),
      url: file.image,
      name: file.filename,
      percent: 100,
      status: "success",
      isSent: true,
      size: file.file_size,
      responseId: file.id.toString(),
      loadingType: "loaded",
    };
  });
};
