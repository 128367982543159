import React, { FC } from "react";

import { Button, Layout } from "antd";
import Header from "components/ImportMaster/ImportMasterLayout/Header/Header";

interface IImportMasterLayoutProps {
  title: string;
  cancelHandler: () => void;
  children: React.ReactNode;
}

const ImportMasterLayout: FC<IImportMasterLayoutProps> = ({ title, cancelHandler, children }) => {

  return (
    <Layout className="min-h-screen h-full">
      <Header title={{ content: title }}>
        <Button
          size="large"
          type="default"
          onClick={() => cancelHandler?.()}
        >
          Вернуться в каталог
        </Button>
      </Header>
      <Layout.Content style={{ backgroundColor: "#f7f8f9" }}>{children}</Layout.Content>
    </Layout>
  );
};

export default ImportMasterLayout;
