import React, { JSX, FC, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavigateFunction, useNavigate } from "react-router";
import axios from "axios";
import dayjs, { Dayjs } from "dayjs";
import {
  periodicUploadingStaticFilters,
  sourceUploadingStaticFilters,
  StaticFiltersType
} from "../TableFilters/utils/staticFilters";

// redux
import { AppDispatch } from "redux/store/store";
import { AppStateType } from "redux/reducers/mainReducer";
import { deleteImportTask, fetchAllImportTasks } from "redux/thunks/importTask.thunk";
import {
  fetchAllImportTasksSuccess,
  InitialImportTaskStateType,
  setCurrentTable,
  TabModeType
} from "redux/slices/importTaskSlice";
import { APP_PATHES } from "constants/appPathes";

// utils
import generateSorter from "utils/generateSorter";
import useCancelableDebounce from "hooks/useCancelableDebounce";
import { formatDateRange } from "utils/formatDateRange";

// components
import { Dropdown, FormInstance, MenuProps, message, Segmented } from "antd";
import CatalogTable, { CatalogColumnType } from "components/Catalog/CatalogTable/CatalogTable";
import EmptyBlock from "components/EmptyBlock/EmptyBlock";
import InfoCard from "components/ui/InfoCard/InfoCard";
import UploadingProductsFiltersPanel from "./UploadingProductsFiltersPanel/UploadingProductsFiltersPanel";
import RangePicker from "../../ui/RangePicker/RangePicker";
import PrimaryButton from "components/ui/PrimaryButton/PrimaryButton";

// icons
import { ReactComponent as MenuIcon } from "assets/icons/three_dots_icon.svg";
import { ReactComponent as LoupeIcon } from "assets/icons/search-square-icon.svg";
import { ReactComponent as CloseIcon } from "assets/icons/close-icon.svg";

// types
import { ImportTaskType, PaginatedImportTasksRequestType } from "importTask/types";
import { SorterResult, TablePaginationConfig } from "antd/es/table/interface";
import { RequestPaginationType } from "app/types";
import { RangePickerProps } from "antd/es/date-picker";

interface IUploadingProductsTabProps {
  form: FormInstance;
}

type DatesFilterType = {
  periodic: {
    createdAt: [dayjs.Dayjs, dayjs.Dayjs] | null,
    updatedAt: [dayjs.Dayjs, dayjs.Dayjs] | null,
  },
  source: [dayjs.Dayjs, dayjs.Dayjs] | null
}

const importTasksColumns: CatalogColumnType[] = [
  "created_at", "updated_at", "periodicity",
  "status", "source", "file", "actions"
];
const sourcesColumns: CatalogColumnType[] = ["date_time", "catalogue_type", "status", "source", "file", "actions"];

const UploadingProductsTab: FC<IUploadingProductsTabProps> = (): JSX.Element => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate: NavigateFunction = useNavigate();

  const importTask: InitialImportTaskStateType = useSelector((state: AppStateType) => state.importTaskSlice);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isShowInfoCard, setIsShowInfoCard] = useState<boolean>(
    localStorage.getItem("uploadingProductsInfo") !== "true"
  );
  const [sorterInfo, setSorterInfo] = useState<SorterResult<any> | SorterResult<any>[]>(null);
  // TODO: реализовать логику параметров запроса и работать с одним стейтом
  const [searchParams, setSearchParams] = useState<PaginatedImportTasksRequestType>({
  });
  const [paginationInfo, setPaginationInfo] = useState<TablePaginationConfig>({
    current: 1,
    pageSize: 10,
    locale: {
      items_per_page: "/ на странице",
    },
  });
  const [periodicStaticFilters, setPeriodicStaticFilters] = useState<StaticFiltersType[]>(
    periodicUploadingStaticFilters
  );
  const [sourcesStaticFilters, setSourcesStaticFilters] = useState<StaticFiltersType[]>(
    sourceUploadingStaticFilters
  );
  const [dateFilter, setDateFilter] = useState<DatesFilterType>({
    periodic: {
      createdAt: null,
      updatedAt: null,
    },
    source: null
  });
  const [isFiltersConfirmed, setIsFiltersConfirmed] = useState<boolean>(false);

  useEffect(() => {
    fetchImportTasks({
      ...getRequestPagination(),
      is_periodic: importTask?.currentTable === "importTasks",
      ...getRequestFilters(),
      ...(sorterInfo ? { order_by: generateSorter(sorterInfo) } : {}),
    });
  }, [paginationInfo.current, paginationInfo.pageSize, sorterInfo]);

  useEffect(() => {
    if (isFiltersConfirmed) {
      setIsFiltersConfirmed(false);

      fetchImportTasks({
        page: 1,
        page_size: paginationInfo.pageSize,
        is_periodic: importTask?.currentTable === "importTasks",
        ...getRequestFilters(),
        ...(sorterInfo ? { order_by: generateSorter(sorterInfo) } : {}),
      });
    }
  }, [isFiltersConfirmed]);

  const { debouncedFunction: fetchImportTasks } = useCancelableDebounce((params, token) => {
    setIsLoading(true);

    const searchParams: PaginatedImportTasksRequestType = {
      ...getRequestPagination(),
      is_periodic: true,
    };

    dispatch(fetchAllImportTasks(params || searchParams, token))
      .catch((error: any) => {
        if (!axios.isCancel(error)) {
          handleError("Ошибка при загрузке списка задач.", error);
        }
      })
      .finally(() => setIsLoading(false));
  });

  const handleError = (messageText: string, error: Error): void => {
    message.error(messageText);
    console.error(error?.message || error);
  };

  const getRequestPagination = (): RequestPaginationType => ({
    page: paginationInfo.current,
    page_size: paginationInfo.pageSize,
  });

  const getRequestFilters = (): PaginatedImportTasksRequestType => {
    const getActiveStatuses = (filters: StaticFiltersType[]) => filters.filter(({ isActive }) => isActive);

    const getDates = (): Record<string, string> => {
      if (importTask?.currentTable === "importTasks") {
        return {
          ...(formatDateRange(["created_at_from", "created_at_to"], dateFilter.periodic.createdAt)),
          ...(formatDateRange(["updated_at_from", "updated_at_to"], dateFilter.periodic.updatedAt)),
        };
      } else {
        return { ...(formatDateRange(["created_at_from", "created_at_to"], dateFilter.source)) };
      }
    };

    const statuses: string[] = importTask?.currentTable === "importTasks"
      ? getActiveStatuses(periodicStaticFilters)?.map(({ name }) => name)
      : getActiveStatuses(sourcesStaticFilters)?.map(({ name }) => name);

    return {
      ...(statuses?.length ? { status: statuses } : {}),
      ...getDates()
    };
  };

  const renderEmptyBlock = (): JSX.Element => {
    const handleOnClick = (): void => navigate(APP_PATHES.IMPORT_MASTER);

    return (
      <EmptyBlock
        icon={<LoupeIcon className="mb-4" />}
        style={{ height: "calc(100vh - 35rem)", minHeight: "400px" }}
        title="Загрузок нет"
        description={<>Ранее вы не загружали товары, загрузите их сейчас</>}
        btn={<PrimaryButton onClickHandler={handleOnClick} text="Загрузить товары" />}
      />
    );
  };

  const handlerDeleteImportTask = (taskId: string): void => {
    dispatch(deleteImportTask({ import_task_id: taskId }))
      .then(() => message.success("Задача успешно удалена"))
      .catch(() => message.error("Ошибка при удалении задачи"));
  };

  const renderUploadAutoActions = (entity: ImportTaskType, idx: number): React.ReactNode => {
    const { id, status, source } = entity;

    const isShowPause: boolean = status !== "Остановлено"
      && status !== "Успешно"
      && status !== "Ошибка";

    const items: MenuProps["items"] = [
      {
        label: <a>Редактировать</a>,
        onClick: () => handleEdit(id, source),
        key: `edit-${idx}`,
      },
      importTask?.currentTable === "importTasks" && {
        label: <a onClick={() => { }}>{isShowPause ? "Остановить" : "Запустить"}</a>,
        key: `pause-${idx}`,
        disabled: true
      },
      {
        label: <a style={{ color: "#FF4D4F" }}>Удалить</a>,
        onClick: () => handlerDeleteImportTask(id),
        key: `delete-${idx}`,
      },
    ];

    return (
      <Dropdown menu={{ items }} trigger={["click"]}>
        <MenuIcon className="m-auto cursor-pointer secondary-color" />
      </Dropdown>
    );
  };

  const handleEdit = (id: string, source: string): void => {
    if (source === "YML") {
      navigate(APP_PATHES.IMPORT);
    } else {
      navigate(`${APP_PATHES.IMPORT_MASTER_EXCEL}/${id}?edit`);
      dispatch(fetchAllImportTasksSuccess({ results: null, total: 0, page: 1, page_size: 10 }));
    }
  };

  const renderTable = (): JSX.Element => {
    return (
      <CatalogTable
        columns={importTask?.currentTable === "importTasks" ? importTasksColumns : sourcesColumns}
        list={importTask.list}
        count={importTask.count}
        isFetching={isLoading}
        isShowEmptyBlock={!isLoading}
        emptyBlock={renderEmptyBlock()}
        renderActions={renderUploadAutoActions}
        setPaginationInfo={setPaginationInfo}
        setSorterInfo={setSorterInfo}
        onClickHandler={handleEdit}
      />
    );
  };

  const handleTabChange = (value: TabModeType): void => {
    fetchImportTasks({
      ...getRequestPagination(),
      is_periodic: value === "importTasks",
    });

    setPeriodicStaticFilters(periodicUploadingStaticFilters);
    setSourcesStaticFilters(sourceUploadingStaticFilters);

    dispatch(setCurrentTable(value));
  };

  const hideInfoCard = (): void => {
    localStorage.setItem("uploadingProductsInfo", "true");
    setIsShowInfoCard(false);
  };

  const disableDate: RangePickerProps["disabledDate"] = (current: dayjs.Dayjs): boolean => current > dayjs();

  const handleChangeSourcesDate = (dates: [Dayjs | null, Dayjs | null]): void => {
    setDateFilter((prev) => ({ ...prev, source: dates }));
    setIsFiltersConfirmed(true);
  };

  const handleChangePeriodDate = (dates: [Dayjs | null, Dayjs | null], type: "createdAt" | "updatedAt"): void => {
    setDateFilter((prev) => {
      return {
        ...prev,
        periodic: { ...prev.periodic, [type]: dates },
      };
    });

    setIsFiltersConfirmed(true);
  };
  return (
    <div>
      {isShowInfoCard && (
        <InfoCard
          text={{
            content: `Вы можете настроить автоматическое обновление каталога ваших товаров, цен и остатков.
              Для этого необходимо загрузить ссылку на YML каталог и выбрать опцию периодической загрузки.`
          }}
          actionBtn={<CloseIcon onClick={hideInfoCard} className="cursor-pointer" />}
          icon={{ type: "exclamation" }}
        />
      )}
      <div className="flex">
        <Segmented
          options={[
            { label: "Автообновление", value: "importTasks" },
            { label: "Разовые загрузки", value: "sources" },
          ]}
          onChange={handleTabChange}
          value={importTask?.currentTable}
          size="large"
          className="mr-3"
        />
        {importTask?.currentTable === "sources" && (
          <RangePicker disabledDate={disableDate} handleChange={handleChangeSourcesDate} />
        )}
        {importTask?.currentTable === "importTasks" && (
          <div className="flex w-full gap-3">
            <RangePicker
              placeholder={["С даты создания", "По дату"]}
              showSuffixIcon
              tooltipTitle="Фильтр по дате создания автообновления"
              disabledDate={disableDate}
              handleChange={(dates: [Dayjs | null, Dayjs | null]) => handleChangePeriodDate(dates, "createdAt")}
            />
            <RangePicker
              placeholder={["С даты обновления", "По дату"]}
              showSuffixIcon
              tooltipTitle="Фильтр по дате обновления"
              disabledDate={disableDate}
              handleChange={(dates: [Dayjs | null, Dayjs | null]) => handleChangePeriodDate(dates, "updatedAt")}
            />
          </div>
        )}
      </div>
      <UploadingProductsFiltersPanel
        periodicStaticFilters={periodicStaticFilters}
        sourcesStaticFilters={sourcesStaticFilters}
        setPeriodicStaticFilters={setPeriodicStaticFilters}
        setSourcesStaticFilters={setSourcesStaticFilters}
        setIsFiltersConfirmed={setIsFiltersConfirmed}
      />
      {renderTable()}
    </div>
  );
};

export default UploadingProductsTab;
