import React, { JSX, FC, CSSProperties, ReactNode } from "react";
import { Layout } from "antd";

import css from "./style.module.scss";

type TitleSizeType = "normal" | "large" | "x-large";

interface IHeaderProps {
  title?: {
    content?: ReactNode;
    size?: TitleSizeType;
    className?: string;
  };
  children: ReactNode;
}

const Header: FC<IHeaderProps> = ({ title, children }): JSX.Element => {
  const headerStyle: CSSProperties = {
    textAlign: "center",
    alignContent: "center",
    height: 64,
    lineHeight: "17px",
    borderBottom: "1px solid var(--main-color-secondary)",
    backgroundColor: "#ffffff",
  };

  return (
    <Layout.Header style={headerStyle} className="p-2 py-0 sm:px-2 md:px-8 lg:px-16 xl:px-24 2xl:px-32">
      <div className="flex justify-between items-center">
        {title && (
          <div className={`title_wrapper ${title?.className || ""}`}>
            <h1 className={`${css.title_text} ${css[title?.size || "large"]}`}>
              {title.content}
            </h1>
          </div>
        )}
        <div className="flex items-center">
          {children}
        </div>
      </div>
    </Layout.Header>
  );
};

export default Header;
