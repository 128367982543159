import * as process from "process";
import axios from "../utils/axios";

const TP_API_BASE: string | undefined = process.env.REACT_APP_TP_BASE_URL?.trimEnd();

/** Получение количества товаров в корзине на ТП */
export const getBasketCount = async () => {
  return axios.GET(TP_API_BASE, "/api/rest/v2/usercart/count/");
};

/** Получение количества сообщений в чате ТП */
export const getMessengerCount = async () => {
  return axios.GET(TP_API_BASE, "/api/rest/v1/messenger/status/");
};
