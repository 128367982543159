import axios, { Canceler, CancelTokenStatic, Method as AxiosMethodType } from "axios";

import { logout } from "redux/thunks/account.thunk";

import getSearchParams from "api/utils/getSearchParams";
import * as cookieControls from "utils/cookieUtils";

import { message } from "antd";

const getToken = (): string | null => {
  const token: string | null = cookieControls.get("authtoken");

  axios.defaults.headers.common.Authorization = token ? token : undefined;

  return token;
};

export const setupResponseInterceptor = (store: any): void => {
  const serverErrors: number[] = [500, 502, 503, 504, 505];
  const clientErrors: number[] = [400, 403, 404];

  axios.interceptors.response.use(null, (error) => {
    if (error?.response && error?.response?.status === 401) {
      store.dispatch(logout());

      if (clientErrors.includes(error?.response?.status)) {
        message.error(error?.response?.data?.detail, 4);
      }

      if (serverErrors.includes(error?.response?.status)) {
        message.error("Произошла непредвиденная ошибка. Пожалуйста, попробуйте позднее", 4);
      }
    }

    return Promise.reject(error);
  });
};

const CancelToken: CancelTokenStatic = axios.CancelToken;
export let cancel: Canceler;

const createRequestConfig = (
  method: AxiosMethodType,
  host: string | undefined,
  path: string,
  body?: any,
  cancelToken?: any,
  isFormData: boolean = false,
) => {
  const queryString: string = path.endsWith("?") ? "" : "?";

  const needConvertBody: boolean = method === "get" || method === "delete";

  const searchParams: string = needConvertBody && !!body ? `${queryString}${getSearchParams(body).toString()}` : "";

  const url: string = searchParams ? `${host}${path}${searchParams}` : `${host}${path}`;

  const headers = {
    Authorization: `token ${getToken()}`,
    "Content-Type": isFormData ? "multipart/form-data" : "application/json",
  };

  return {
    headers,
    method,
    url,
    ...(body && { data: isFormData ? body : JSON.stringify(body) }),
    ...(cancelToken
      ? { cancelToken }
      : {
          cancelToken: new CancelToken(function executor(canceler: Canceler) {
            cancel = canceler;
          }),
        }),
  };
};

const request = (
  method: AxiosMethodType,
  host: string | undefined,
  path: string,
  body?: any,
  cancelToken?: any,
  isFormData: boolean = false,
) => {
  return axios(createRequestConfig(method, host, path, body, cancelToken, isFormData));
};

const get = (host: string | undefined, path: string, params?: any, cancelToken?: any) => {
  return request("get", host, path, params, cancelToken).then((config) => ({ ...config, params }));
};

const post = (host: string | undefined, path: string, body?: any, cancelToken?: any, isFormData: boolean = false) => {
  return request("post", host, path, body, cancelToken, isFormData);
};

const put = (host: string | undefined, path: string, body?: any, cancelToken?: any, isFormData: boolean = false) => {
  return request("put", host, path, body, cancelToken, isFormData);
};

const patch = (host: string | undefined, path: string, body?: any, cancelToken?: any, isFormData: boolean = false) => {
  return request("patch", host, path, body, cancelToken, isFormData);
};

const deleted = (host: string | undefined, path: string, body?: any, cancelToken?: any) => {
  return request("delete", host, path, body, cancelToken);
};

export default {
  GET: get,
  PUT: put,
  POST: post,
  PATCH: patch,
  DELETE: deleted,
};
