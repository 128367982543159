import { useEffect, useMemo, useState } from "react";

import { useForm, useWatch } from "antd/es/form/Form";
import { useDispatch, useSelector } from "react-redux";
import { AppStateType } from "redux/reducers/mainReducer";
import { AppDispatch } from "redux/store/store";
import { agreedKbo } from "redux/thunks/account.thunk";

import ModalTerms from "components/modals/ModalTerms";

const ModalKBO = (): JSX.Element => {
  const dispatch = useDispatch<AppDispatch>();

  const {
    kbo: { data: dataKbo, isAgreed: isAgreedKbo },
  } = useSelector((state: AppStateType) => state.accountSlice.siteTerms);

  const [form] = useForm<{ isAccepted: boolean; }>();
  const values = useWatch([], form);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const isOkDisabled: boolean = useMemo(() => !values?.isAccepted || isLoading, [values, isLoading]);

  useEffect(() => {
    setIsOpen(!!dataKbo && !isAgreedKbo);
  }, [dataKbo, isAgreedKbo]);

  const onOkHandler = (): void => {
    setIsLoading(true);
    dispatch(agreedKbo())
      .then(() => setIsOpen(false))
      .finally(() => setIsLoading(false));
  };

  const onCancelHandler = (): void => {
    setIsOpen(false);
  };

  return (
    <ModalTerms
      key="modalTerms"
      isOpen={isOpen}
      title="Изменение в условиях договора"
      okHandler={onOkHandler}
      cancelHandler={onCancelHandler}
      isOkDisabled={isOkDisabled}
      needAccept
      data={dataKbo}
      form={form}
      initialValues={{ isAccepted: false }}
      className="kbo"
    />
  );
};

export default ModalKBO;