import React, { JSX, FC, useState, Key, useEffect } from "react";
import axios from "axios";
import useCancelableDebounce from "hooks/useCancelableDebounce";
import { useForm, useWatch } from "antd/es/form/Form";
import { handleError } from "utils/handleError";
import generateSorter from "utils/generateSorter";
import { formatDateRange } from "utils/formatDateRange";

// redux
import { useDispatch, useSelector } from "react-redux";
import { AppStateType } from "redux/reducers/mainReducer";
import { AppDispatch } from "redux/store/store";
import { deleteAllProducts, deleteProduct, fetchProducts } from "redux/thunks/products.thunk";

//components
import { Dropdown, FormInstance, MenuProps, notification } from "antd";
import CatalogTable from "components/Catalog/CatalogTable/CatalogTable";
import ProductsTableStub from "components/Catalog/ProductsTableStub/ProductsTableStub";
import ConfirmModal from "components/ui/modals/ConfirmModal/ConfirmModal";
import InfoCard from "components/ui/InfoCard/InfoCard";
import UploadedProductsFiltersPanel from "./UploadedProductsFiltersPanel/UploadedProductsFiltersPanel";

// icons
import { ReactComponent as MenuIcon } from "assets/icons/three_dots_icon.svg";

// types
import { SorterResult, TablePaginationConfig, TableRowSelection } from "antd/es/table/interface";
import { ProductType, ProductsRequestType } from "products/types";
import { NavigateFunction, useNavigate } from "react-router";
import { APP_PATHES } from "constants/appPathes";
import { ProductsFiltersValuesType } from "app/types";

interface IUploadedProductsTabProps {
  form: FormInstance;
  setActiveTab: React.Dispatch<React.SetStateAction<string>>;
}

const selectionOptions: { key: string, text: string, isAllSelected: boolean }[] = [
  { key: "all-data", text: "Выбрать все", isAllSelected: true },
  { key: "all-page", text: "Выбрать все на странице", isAllSelected: false },
  { key: "none", text: "Очистить выбор", isAllSelected: false },
];

const UploadedProductsTab: FC<IUploadedProductsTabProps> = ({ setActiveTab }): JSX.Element => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate: NavigateFunction = useNavigate();

  const [form] = useForm<ProductsFiltersValuesType>();
  const values: ProductsFiltersValuesType = useWatch<FormInstance>([], form);

  const {
    list,
    count,
    isFetching,
    minPrice,
    maxPrice,
  } = useSelector((state: AppStateType) => state.productsSlice.products);

  // фильтры, сортировки, пагинация, поиск
  const [previousFilters, setPreviousFilters] = useState<ProductsFiltersValuesType>({
    categoryBK: [],
    date: null,
    draft: false,
    error: false,
    price: [minPrice, maxPrice],
    published: false,
    supplierCategory: []
  });
  const [paginationInfo, setPaginationInfo] = useState<TablePaginationConfig>({
    current: 1,
    pageSize: 10,
    locale: {
      items_per_page: "/ на странице",
    },
  });
  const [sorterInfo, setSorterInfo] = useState<SorterResult<any> | SorterResult<any>[]>(null);
  const [searchValue, setSearchValue] = useState<string>(null);
  const [isFiltersConfirmed, setIsFiltersConfirmed] = useState<boolean>(false);

  const [productsToDelete, setProductsToDelete] = useState<ProductType[]>([]);
  const [isShowDeleteModal, setIsShowDeleteModal] = useState<boolean>(false);
  const [isShowCreateCopyModal, setIsShowCreateCopyModal] = useState<boolean>(false);
  const [selectedRows, setSelectedRows] = useState<ProductType[]>(null);
  const [selectedKeys, setSelectedKeys] = useState<Key[]>(null);
  const [countProductsForDelete, setCountProductsForDelete] = useState<number>(0);
  const [isAllProductsSelected, setIsAllProductsSelected] = useState<boolean>(false);
  const [isSingleProductDelete, setIsSingleProductDelete] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [confirmText, setConfirmText] = useState<string>("");

  useEffect(() => {
    if (isAllProductsSelected) {
      setCountProductsForDelete(count);
      setSelectedRows(list?.map((product, idx) => ({ key: idx.toString(), ...product })));
    }
  }, [isAllProductsSelected, list]);

  useEffect(() => {
    fetchProductsList({
      page: paginationInfo.current,
      page_size: paginationInfo.pageSize,
      search: searchValue,
      ...(values ? getRequestFilters(previousFilters) : {}),
      ...(sorterInfo ? { order_by: generateSorter(sorterInfo) } : {}),
    });

    !isAllProductsSelected && setSelectedRows(null);
  }, [paginationInfo.current, paginationInfo.pageSize, searchValue, sorterInfo]);

  useEffect(() => {
    if (isFiltersConfirmed) {
      setIsFiltersConfirmed(false);

      fetchProductsList(getRequestPaginationWithFilters(values));
    }
  }, [isFiltersConfirmed]);

  useEffect(() => {
    setSelectedKeys(selectedRows?.map((value) => value.key));
  }, [selectedRows]);

  useEffect(() => {
    if (!isShowDeleteModal) {
      setIsSingleProductDelete(false);
    }
  }, [isShowDeleteModal]);

  // Логика работы с чекбоксами у таблицы
  const handleSelection = (isAllSelected: boolean, keys: Key[] | null, rows: ProductType[], count: number): void => {
    setIsAllProductsSelected(isAllSelected);
    setSelectedKeys(keys);
    setSelectedRows(rows);
    setCountProductsForDelete(count);
  };

  const rowSelection: TableRowSelection<any> = {
    selections: selectionOptions.map(({ key, text, isAllSelected }) => ({
      key,
      text,
      onSelect: (selectedRowKeys: Key[]): void => {
        const keys: Key[] = key === "none" ? null : selectedRowKeys;
        const rows: ProductType[] = key === "all-page"
          ? list.map((product, idx) => ({ key: idx.toString(), ...product }))
          : [];

        const productsCount: Record<string, number> = {
          "all-data": count,
          "all-page": selectedRowKeys.length,
          "none": 0
        };

        if (key !== "all-data" || !isAllProductsSelected) {
          handleSelection(isAllSelected, keys, rows, productsCount[key]);
        }
      }
    })),
    selectedRowKeys: selectedKeys,
    onChange: (selectedRowKeys: Key[], selectedRows: ProductType[]) => handleSelection(
      false,
      selectedRowKeys,
      selectedRows,
      selectedRows?.length
    ),
  };

  const { debouncedFunction: fetchProductsList } = useCancelableDebounce((params, token) => {
    const searchParams: ProductsRequestType = {
      page: paginationInfo.current,
      page_size: paginationInfo.pageSize,
    };

    dispatch(fetchProducts(params || searchParams, token))
      .catch((error: any) => {
        if (!axios.isCancel(error)) {
          handleError("Ошибка при загрузке списка товаров.", error);
        }
      });
  });

  const getRequestPaginationWithFilters = (filters: ProductsFiltersValuesType): ProductsRequestType => ({
    page: 1,
    page_size: paginationInfo.pageSize,
    ...(searchValue ? { search: searchValue } : {}),
    ...getRequestFilters(filters),
    ...(sorterInfo ? { order_by: generateSorter(sorterInfo) } : {}),
  });

  const getRequestFilters = (filters: ProductsFiltersValuesType): ProductsRequestType => {
    const statuses: string[] = Object.entries({
      "Размещен": filters.published,
      "Ошибка": filters.error,
      "Черновик": filters.draft,
    })
      .filter(([, isActive]) => isActive)
      .map(([status]) => status);

    const categorySupplierIds: number[] = filters?.supplierCategory?.map(({ value }) => (value as number));
    const categoryTpIds: number[] = filters?.categoryBK?.map(({ value }) => (value as number));
    const needSendPrice: boolean = filters.price?.[0] !== minPrice || filters.price?.[1] !== maxPrice;

    return {
      ...(needSendPrice && filters.price?.[0] ? { price_from: filters.price[0] } : {}),
      ...(needSendPrice && filters.price?.[1] ? { price_to: filters.price[1] } : {}),
      ...(formatDateRange(["updated_at_from", "updated_at_to"], filters.date)),
      ...(statuses.length ? { status: statuses } : {}),
      ...(filters.supplierCategory?.length ? { category_supplier: categorySupplierIds } : {}),
      ...(filters.categoryBK?.length ? { category_bk: categoryTpIds } : {})
    };
  };

  const showDeleteModal = (values: ProductType[], text: string): void => {
    setConfirmText(text);
    setProductsToDelete(values);
    setIsShowDeleteModal(true);
  };

  const closeDeleteModal = (): void => setIsShowDeleteModal(false);

  const handleSuccessDelete = (description: string): void => {
    notification.success({ message: "Товары успешно удалены", description });

    fetchProductsList(getRequestPaginationWithFilters(previousFilters));
  };

  const handleFailureDelete = (error: any): void => {
    handleError(
      "При удалении товара(ов) произошла ошибка, пожалуйста, повторите позже.",
      error
    );
  };

  const clearSelectedProducts = (): void => {
    setSelectedRows(null);
    setProductsToDelete([]);
    setCountProductsForDelete(0);
  };

  const deleteProducts = (): void => {
    setIsLoading(true);
    closeDeleteModal();

    const ids: number[] = productsToDelete.map(({ id }) => id);

    dispatch(deleteProduct(ids))
      .then(() => {
        handleSuccessDelete(`${isSingleProductDelete ? "1" : countProductsForDelete} шт.`);

        if (!isAllProductsSelected) {
          clearSelectedProducts();
        }
      })
      .catch((error) => handleFailureDelete(error))
      .finally(() => setIsLoading(false));
  };

  const deleteAllProductsList = (): void => {
    setIsLoading(true);
    closeDeleteModal();

    dispatch(deleteAllProducts({
      ...getRequestFilters(previousFilters),
      ...(searchValue ? { search: searchValue } : {}),
    }))
      .then(({ data }) => {
        if (data?.failed) {
          notification.warning({
            message: `По техническим причинам ${data?.failed} товаров удалить не удалось. 
              Повторите попытку позже или обратитесь в службе тех. поддержки по телефону 8(800)222-42-14 
              или по адресу help@vtbconnect.ru`
          });
        }

        handleSuccessDelete(`${data?.failed ? (countProductsForDelete - data?.failed) : countProductsForDelete} шт.`);
        setIsAllProductsSelected(false);
        clearSelectedProducts();
      })
      .catch((error) => handleFailureDelete(error))
      .finally(() => setIsLoading(false));
  };

  const showCreateCopyModal = (): void => setIsShowCreateCopyModal(true);

  const closeCreateCopyModal = (): void => setIsShowCreateCopyModal(false);

  const renderProductActions = (value: ProductType, idx: number): React.ReactNode => {
    const onEditHandler = (): void => {
      navigate(`${APP_PATHES.IMPORT_MASTER_MANUAL}/${value.id}`);
    };

    const items: MenuProps["items"] = [
      {
        label: <a>Редактировать</a>,
        key: `edit-${idx}`,
        onClick: onEditHandler,
      },
      // TODO пока скрываем
      // {
      //   label: <a>Создать копию</a>,
      //   key: `copy-${idx}`,
      //   onClick: showCreateCopyModal,
      //   disabled: true
      // },
      // {
      //   label: <a>Редактировать цену</a>,
      //   key: `edit-price-${idx}`,
      //   disabled: true
      // },
      {
        label: <a style={{ color: "#FF4D4F" }}>Удалить</a>,
        key: `delete-${idx}`,
        onClick: () => {
          setIsSingleProductDelete(true);
          showDeleteModal([value], "Товар будет удален без возможности восстановления");
        },
      },
    ];

    return (
      <Dropdown menu={{ items }} trigger={["click"]}>
        <MenuIcon className="m-auto cursor-pointer secondary-color" />
      </Dropdown>
    );
  };

  return (
    <div>
      <UploadedProductsFiltersPanel
        form={form}
        values={values}
        selectedRows={selectedRows}
        isLoading={isLoading}
        isFiltersConfirmed={isFiltersConfirmed}
        showDeleteModal={showDeleteModal}
        setSearchValue={setSearchValue}
        previousFilters={previousFilters}
        setPreviousFilters={setPreviousFilters}
        setIsFiltersConfirmed={setIsFiltersConfirmed}
        countProductsForDelete={countProductsForDelete}
      />
      <CatalogTable
        columns={[
          "name",
          "category",
          "bk_category",
          "price",
          "status",
          "actions"
        ]}
        list={list}
        count={count}
        isFetching={isFetching}
        isShowEmptyBlock={!isFetching}
        rowSelection={rowSelection}
        emptyBlock={<ProductsTableStub setActiveTab={setActiveTab} />}
        renderActions={renderProductActions}
        setSorterInfo={setSorterInfo}
        setPaginationInfo={setPaginationInfo}
      />
      {isShowDeleteModal && (
        <ConfirmModal
          text={confirmText}
          isOpen={isShowDeleteModal}
          onCancelHandler={closeDeleteModal}
          onOkHandler={(isAllProductsSelected && !isSingleProductDelete) ? deleteAllProductsList : deleteProducts}
        />
      )}
      {isShowCreateCopyModal && (
        <ConfirmModal
          text="Вы уверены, что хотите создать копию товара?"
          isOpen={isShowCreateCopyModal}
          isApplyDisabled={isLoading}
          onCancelHandler={closeCreateCopyModal}
          onOkHandler={() => { }}
        >
          <InfoCard
            className="px-3 py-2"
            icon={{ type: "exclamation", className: "mr-2" }}
            background="grey"
            text={{ content: "В начало наименования скопированного товара будет добавлена приписка “Копия”" }}
          />
        </ConfirmModal>
      )}
    </div>
  );
};

export default UploadedProductsTab;
