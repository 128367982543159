import { JSX, FC, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "redux/store/store";
import { AppStateType } from "redux/reducers/mainReducer";
import { InitialStateAccountType } from "redux/slices/accountSlice";
import { logout } from "redux/thunks/account.thunk";

import { ReactComponent as ArrowDownIcon } from "assets/icons/arrow-down-icon.svg";
import { Dropdown, MenuProps } from "antd";
import { Link } from "react-router-dom";

import css from "./style.module.css";
import { APP_PATHES } from "constants/appPathes";

interface IHeaderUserInfoProps { }

const HeaderUserInfo: FC<IHeaderUserInfoProps> = (): JSX.Element => {
  const dispatch = useDispatch<AppDispatch>();
  const account: InitialStateAccountType = useSelector((state: AppStateType) => state.accountSlice);

  const [isShow, setIsShow] = useState<boolean>(false);

  const logoutHandler = (): void => {
    dispatch(logout());
  };

  const menuData: MenuProps = {
    items: [
      {
        key: 0,
        label: <Link to={APP_PATHES.SETTINGS}>Настройки</Link>,
      },
      {
        key: 1,
        label: <span>Выйти</span>,
        onClick: logoutHandler
      }
    ]
  };
  
  return (
    <Dropdown menu={menuData} trigger={["click"]}>
      <div
        className={`flex items-center cursor-pointer ${css.userInfoContainer}`}
        onClick={() => setIsShow(!isShow)}
      >
        <div className="flex flex-col items-start px-4">
          <span className="light-gray-color text-start">{account.user?.firstName} {account.user?.lastName}</span>
          <span className="font-bold text-start text-white">{account.user?.username}</span>
        </div>
        <ArrowDownIcon className={`${isShow ? css.rotate : ""} ${css.arrow}`} />
      </div>
    </Dropdown>
  );
};

export default HeaderUserInfo;
