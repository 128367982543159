import React, { JSX, FC, ReactNode } from "react";
import { ReactComponent as QuestionIcon } from "assets/icons/question-icon.svg";
import { ReactComponent as ExclamationIcon } from "assets/icons/exclamation-icon.svg";

import css from "./style.module.css";

type IconType = "question" | "exclamation";

interface IInfoCardProps {
  text: {
    content: string;
    className?: string;
  };
  title?: string;
  icon?: {
    type: IconType;
    className?: string;
  };
  actionBtn?: ReactNode;
  background?: "blue" | "grey" | "orange";
  className?: string;
}

const InfoCard: FC<IInfoCardProps> = ({
  text = { 
    content: "",
    className: "" 
  },
  title,
  actionBtn,
  icon,
  background = "blue",
  className = ""
}): JSX.Element => {
  const renderingIcon: Record<IconType, JSX.Element> = {
    question: <QuestionIcon className={`${icon.className ?? "mr-5"}`} />,
    exclamation: <ExclamationIcon className={`${icon.className ?? "mr-5"}`} />,
  };

  return (
    <div
      className={`
        flex justify-between items-center py-3 px-6 rounded-xl mb-5 
        ${css[background]}
        ${className}
      `}
    >
      <div className="flex items-center">
        {icon && renderingIcon[icon.type]}
        <div className="mr-6">
          {title && <h5 className="text-base mb-1">{title}</h5>}
          <p className={`text-14-r ${css.text} ${text.className}`}>{text.content}</p>
        </div>
      </div>
      {actionBtn}
    </div>
  );
};

export default InfoCard;
