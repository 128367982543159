import axios from "../utils/axios";
import { AxiosResponse, CancelToken } from "axios";
import {
  DefaultPaginationRequestType,
  MinMaxCatalogPriceType,
  ModificationCreateRequestType,
  ModificationCreateType,
  ModificationDeleteRequestType,
  ModificationType,
  PaginatedModificationsType,
  PaginatedProductsType,
  PaginatedPropertiesRequestType,
  PaginatedPropertiesType,
  PaginatedVendorCatalogType,
  ProductCreateRequestType,
  ProductsRequestType,
  ProductType,
  ProductUpdateRequestType,
} from "products/types";

const API_BASE: string | undefined = process.env.REACT_APP_API_BASE_URL?.trimEnd();

{/**** Товары ****/ }

/** Синхронизация способов оплат поставщиков ТП */
export const getPaymentTypeSync = (
  cancelToken?: CancelToken
): Promise<AxiosResponse<any>> => {
  return axios.GET(API_BASE, "/api/v1/product/tp/payment_type_sync/", null, cancelToken);
};

/** Получение листинга товаров */
export const getProducts = (
  params: ProductsRequestType,
  cancelToken?: CancelToken
): Promise<AxiosResponse<PaginatedProductsType>> => {
  return axios.GET(API_BASE, "/api/v1/product/all/", params, cancelToken);
};

/** Получение минимальной и максимальной цен в каталоге */
export const getMinMaxCatalogPrice = (cancelToken?: CancelToken): Promise<AxiosResponse<MinMaxCatalogPriceType>> => {
  return axios.GET(API_BASE, "/api/v1/product/catalog_min_max_price/", null, cancelToken);
};

/** Получение товара */
export const getProduct = (
  productId: number,
  cancelToken?: CancelToken
): Promise<AxiosResponse<ProductType>> => {
  return axios.GET(API_BASE, `/api/v1/product/product/${productId}`, null, cancelToken);
};

/** Получение листинга свойств товаров */
export const getProductProperties = (
  body: PaginatedPropertiesRequestType,
  cancelToken?: CancelToken,
): Promise<AxiosResponse<PaginatedPropertiesType>> => {
  return axios.GET(API_BASE, "/api/v1/product/properties/", body, cancelToken);
};

/** Создание товара */
export const postProduct = (
  body: ProductCreateRequestType,
  cancelToken?: CancelToken
): Promise<AxiosResponse<ProductType>> => {
  return axios.POST(API_BASE, "/api/v1/product/create/", body, cancelToken);
};

/** Массовое или единичное удаление товаров из каталога */
export const deleteProduct = (
  productIds: number[],
  cancelToken?: CancelToken
): Promise<AxiosResponse<any>> => {
  return axios.DELETE(API_BASE, "/api/v1/product/delete/", productIds, cancelToken);
};

/** Удаление всего списка продуктов с учетом фильтров и поиска */
export const deleteAllProducts = (
  params: ProductsRequestType,
  cancelToken?: CancelToken
): Promise<AxiosResponse<any>> => {
  return axios.DELETE(API_BASE, "/api/v1/product/all/", params, cancelToken);
};

/** Редактирование товара */
export const patchProduct = (
  productId: number,
  body: ProductUpdateRequestType,
  cancelToken?: CancelToken
): Promise<AxiosResponse<ProductType>> => {
  return axios.PATCH(API_BASE, `/api/v1/product/update/${productId}`, body, cancelToken);
};

/** Получение листинга модификаций товара */
export const getProductModifications = (
  productId: number,
  body: DefaultPaginationRequestType,
  cancelToken?: CancelToken,
): Promise<AxiosResponse<PaginatedModificationsType>> => {
  return axios.GET(API_BASE, `/api/v1/product/${productId}/modifications/`, body, cancelToken);
};

/** Получение модификации */
export const getModification = (
  modificationId: number,
  cancelToken?: CancelToken,
): Promise<AxiosResponse<ModificationType>> => {
  return axios.GET(API_BASE, `/api/v1/product/modifications/${modificationId}`, null, cancelToken);
};

/** Создание модификации */
export const postModification = (
  body: ModificationCreateRequestType,
  cancelToken?: CancelToken,
): Promise<AxiosResponse<ModificationCreateType>> => {
  return axios.POST(API_BASE, "/api/v1/product/modification/create/", body, cancelToken);
};

/** Дублирование модификации */
export const duplicateModification = (
  modificationId: number,
  cancelToken?: CancelToken,
): Promise<AxiosResponse<ModificationType>> => {
  return axios.POST(API_BASE, `/api/v1/product/modifications/${modificationId}/duplicate/`, null, cancelToken);
};

/** Удаление модификации */
export const deleteModification = (
  body: ModificationDeleteRequestType,
  cancelToken?: CancelToken,
): Promise<AxiosResponse<any>> => {
  return axios.DELETE(API_BASE, "/api/v1/product/modification/delete/", body, cancelToken);
};

/** Получение листинга каталогов */
export const getVendorCatalogs = (
  cancelToken?: CancelToken,
): Promise<AxiosResponse<PaginatedVendorCatalogType>> => {
  return axios.GET(API_BASE, "/api/v1/product/vendor_catalogs/", null, cancelToken);
};
