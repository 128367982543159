import { notification } from "antd";

export const handleError = (messageText: string, error: Error): void => {
  notification.error({
    message: "Произошла ошибка",
    description: messageText,
    duration: 15
  });

  console.error(error?.message || error);
};
