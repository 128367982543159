import React, { JSX } from "react";

import Column from "antd/es/table/Column";
import TextWithTooltip from "components/ui/TextWithTooltip/TextWithTooltip";
import { ModificationType } from "products/types";
import { SorterResult } from "antd/es/table/interface";

export const renderPriceColumn = (
  renderColumnTitle: (title: string) => JSX.Element,
  sorter: SorterResult<any>,
): JSX.Element => {
  return (
    <Column
      key="Price"
      title={renderColumnTitle("Цена")}
      dataIndex="modifications"
      width={160}
      sorter={{ multiple: 3 }}
      sortOrder={sorter?.field === "modifications" ? sorter?.order : undefined}
      showSorterTooltip={false}
      sortDirections={["ascend", "descend"]}
      render={(value: ModificationType[]) => {
        const baseModification: ModificationType = value.find(({ is_base }) => is_base);
        const { currency_price, currency } = baseModification || {};

        return <TextWithTooltip text={`${currency_price ?? ""} ${currency ?? ""}`} />;
      }}
    />
  );
};
