import React, { FC, JSX } from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { ProductType, ModificationType } from "products/types";
import { APP_PATHES } from "constants/appPathes";
import { SorterResult } from "antd/es/table/interface";
import useImageUrl from "hooks/useImageUrl";

// components
import ImageContainer from "components/ui/ImageContainer/ImageContainer";
import Column from "antd/es/table/Column";
import TextWithTooltip from "components/ui/TextWithTooltip/TextWithTooltip";

import css from "../style.module.css";

interface INameColumnProps {
  text: string;
  entity: ProductType;
}

const NameColumn: FC<INameColumnProps> = ({ text, entity }): JSX.Element => {
  const navigate: NavigateFunction = useNavigate();

  const { id, modifications } = entity || {};
  const baseModification: ModificationType = modifications?.find(({ is_base }) => is_base);

  const { images } = baseModification || {};

  const getFirstImageProperty = <T extends keyof { image: string; filename: string }>(
    images: { position: number; image: string; filename: string }[] | undefined,
    property: T
  ): string | undefined => {
    return images?.find(({ position }) => position === 1)?.[property] ?? images?.[0]?.[property];
  };

  const photo: string = getFirstImageProperty(images, "image") ?? "";
  const fileName: string = getFirstImageProperty(images, "filename") ?? "";
  const imageUrl: string = useImageUrl(fileName, photo);

  const navigateToEditMode = (): void => navigate(`${APP_PATHES.IMPORT_MASTER_MANUAL}/${id}`);

  return (
    <div
      className="flex items-center cursor-pointer"
      onClick={navigateToEditMode}
    >
      <ImageContainer
        src={imageUrl}
        alt="Фото"
        imageContainerClass={css.photo}
      />
      <TextWithTooltip text={text}/>
    </div>
  );
};

export const renderNameColumn = (
  renderColumnTitle: (title: string) => JSX.Element,
  sorter: SorterResult<any>,
): JSX.Element => {
  return (
    <Column
      key="Name"
      title={renderColumnTitle("Наименование товара")}
      dataIndex="name"
      width={250}
      onCell={() => ({
        style: {
          whiteSpace: "normal",
          WordWrap: "break-word",
        }
      })}
      ellipsis
      sorter={{ multiple: 3 }}
      sortOrder={sorter?.field === "name" ? sorter?.order : undefined}
      showSorterTooltip={false}
      sortDirections={["ascend", "descend"]}
      render={(text: string, entity: ProductType) => <NameColumn text={text} entity={entity} />}
    />
  );
};
