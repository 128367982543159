import React, { JSX, FC, CSSProperties } from "react";
import { Form, Select } from "antd";
import PrimaryButton from "components/ui/PrimaryButton/PrimaryButton";
import { ReactComponent as CheckOutlinedIcon } from "assets/icons/check_icon.svg";
import { DefaultOptionType } from "antd/es/select";

interface ISelectWithButtonProps {
  name: string;
  value: number | string;
  options: DefaultOptionType[];
  placeholder: string;
  showSearch?: boolean;
  isDisabledBtn?: boolean;
  wrapStyle?: CSSProperties;
  selectStyle?: CSSProperties;
  onClickHandler?: () => void;
  onChangeHandler?: (value: number | string) => void;
  onSearchHandler?: (value: string) => void;
}

const SelectWithButton: FC<ISelectWithButtonProps> = ({
  name,
  value,
  options,
  placeholder,
  onClickHandler,
  onChangeHandler,
  onSearchHandler,
  showSearch = false,
  isDisabledBtn = false,
  wrapStyle = {},
  selectStyle = {},
}): JSX.Element => {
  return (
    <Form.Item style={wrapStyle} name={name} valuePropName="value">
      <div className="flex select-with-button">
        <Select
          value={value}
          showSearch={showSearch}
          placeholder={placeholder}
          options={options}
          style={selectStyle}
          onChange={onChangeHandler}
          onSearch={onSearchHandler}
          filterOption={false}
        />
        <PrimaryButton
          icon={<CheckOutlinedIcon/>}
          size="middle"
          onClickHandler={onClickHandler}
          isDisabled={isDisabledBtn}
        />
      </div>
    </Form.Item>
  );
};

export default SelectWithButton;
