import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, useWatch } from "antd/es/form/Form";

import { AppStateType } from "redux/reducers/mainReducer";
import { AppDispatch } from "redux/store/store";
import { agreedOtherDoc } from "redux/thunks/account.thunk";

import { KboOrEulaType } from "terms/types";
import ModalTerms from "components/modals/ModalTerms";

const ModalDocuments = (): JSX.Element => {
  const dispatch = useDispatch<AppDispatch>();

  const {
    eula: { isAgreed: isAgreedEula },
    kbo: { isAgreed: isAgreedKbo },
    otherDocs: { data: dataDocs, notResponded, },
  } = useSelector((state: AppStateType) => state.accountSlice.siteTerms);

  const [form] = useForm<{ id: number; otherDocResponse: string; }>();
  const values = useWatch([], form);
  
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [currentDoc, setCurrentDoc] = useState<any>(null);

  const isOkDisabled: boolean = useMemo(() => !values?.otherDocResponse || isLoading, [values, isLoading]);

  useEffect(() => {
    dataDocs?.length && setCurrentDoc(dataDocs?.[0]);
  }, [dataDocs]);

  useEffect(() => {
    currentDoc?.id && form.setFieldValue("id", currentDoc?.id);
    const canOpen: boolean = isAgreedKbo && isAgreedEula && !!notResponded?.length && currentDoc;
    canOpen && setIsOpen(true);
  }, [isAgreedKbo, isAgreedEula, notResponded, currentDoc]);

  const onOkHandler = useCallback((values: { id: number; otherDocResponse: string }): void => {
    setIsLoading(true);
    dispatch(agreedOtherDoc(values))
      .then(() => {
        if ((dataDocs as KboOrEulaType[])?.length > 1) {
          const nextDoc: KboOrEulaType = (dataDocs as KboOrEulaType[])?.find((doc) => doc.id !== values.id);
          if (nextDoc) {
            setCurrentDoc(nextDoc);
            form.resetFields();
          }
        }
        setIsOpen(false);
      })
      .catch((error) => console.error(error))
      .finally(() => setIsLoading(false));
  }, [currentDoc, dataDocs]);

  const onCancelHandler = (): void => {
    form.resetFields();
    setIsOpen(false);
  };

  return (
    <ModalTerms
      key={`modalTerms-${currentDoc?.id}`}
      isOpen={isOpen}
      title={currentDoc?.title || ""}
      okHandler={onOkHandler}
      cancelHandler={onCancelHandler}
      isOkDisabled={isOkDisabled}
      data={currentDoc}
      form={form}
      initialValues={{ id: currentDoc?.id, otherDocResponse: "" }}
      className="doc"
    />
  );
};

export default ModalDocuments;
