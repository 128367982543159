import { FC, JSX, useEffect, useMemo } from "react";
import { Navigate, Route, Routes } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "redux/store/store";
import { fetchAccount } from "redux/thunks/account.thunk";

import * as cookieControls from "utils/cookieUtils";

// components
import ExternalRedirect from "components/ui/ExternalRedirect";
import SiteTerms from "components/SiteTerms";
import MainLayout from "components/MainLayout/MainLayout";
import CatalogPage from "pages/CatalogPage";
import ImportMasterPage from "pages/ImportMasterPage";
import MatchingTableColumnsPage from "pages/MatchingTableColumnsPage";

import { APP_PATHES } from "constants/appPathes";
import { AppStateType } from "redux/reducers/mainReducer";

const TP_API_BASE: string | undefined = process.env.REACT_APP_TP_BASE_URL?.trimEnd();

const App: FC = (): JSX.Element => {
  const dispatch = useDispatch<AppDispatch>();

  const currentUrl: URL = new URL(window.location.href);

  const { user } = useSelector((state: AppStateType) => state.accountSlice);

  const hasData = useMemo(() => {
    return Object.values(user)
      .filter((value) => typeof value !== "boolean")
      .some((value) => value !== null);
  }, [user]);

  useEffect(() => {
    const token: string = cookieControls.get("authtoken");

    if (!token) {
      window.location.href = `${TP_API_BASE}${APP_PATHES.LOGIN}?logout=1&redirect=${currentUrl}`;
    } else {
      !hasData && !user.isFetching && dispatch(fetchAccount());
    }
  }, [hasData]);

  return (
    <>
      <SiteTerms />
      <Routes>
        <Route path={APP_PATHES.GENERAL} element={<Navigate to={APP_PATHES.IMPORT} replace />} />
        <Route
          path={APP_PATHES.LOGIN}
          element={
            <ExternalRedirect
              urlOrigin={TP_API_BASE}
              to={`${APP_PATHES.LOGIN}?logout=1&redirect=${window.location.origin}`}
            />
          }
        />
        <Route
          path={APP_PATHES.PLATFORM}
          element={<ExternalRedirect urlOrigin={TP_API_BASE} to={APP_PATHES.PLATFORM} />}
        />
        <Route
          path={APP_PATHES.TRADES}
          element={<ExternalRedirect urlOrigin={TP_API_BASE} to={APP_PATHES.TRADES} />}
        />
        <Route
          path={APP_PATHES.CATALOG}
          element={<ExternalRedirect urlOrigin={TP_API_BASE} to={APP_PATHES.CATALOG} />}
        />
        <Route
          path={APP_PATHES.SUPPLIERS}
          element={<ExternalRedirect urlOrigin={TP_API_BASE} to={APP_PATHES.SUPPLIERS} />}
        />
        <Route
          path={APP_PATHES.ORDERS}
          element={<ExternalRedirect urlOrigin={TP_API_BASE} to={APP_PATHES.ORDERS} />}
        />
        <Route
          path={APP_PATHES.REPORTS}
          element={<ExternalRedirect urlOrigin={TP_API_BASE} to={APP_PATHES.REPORTS} />}
        />
        <Route
          path={APP_PATHES.WORKFLOW}
          element={<ExternalRedirect urlOrigin={TP_API_BASE} to={APP_PATHES.WORKFLOW} />}
        />
        <Route
          path={APP_PATHES.SMART_BUSINESS}
          element={<ExternalRedirect urlOrigin={TP_API_BASE} to={APP_PATHES.SMART_BUSINESS} />}
        />
        <Route
          path={APP_PATHES.CART}
          element={<ExternalRedirect urlOrigin={TP_API_BASE} to={APP_PATHES.CART} />}
        />
        <Route
          path={APP_PATHES.HELP_MAIN}
          element={<ExternalRedirect urlOrigin={TP_API_BASE} to={APP_PATHES.HELP_MAIN} />}
        />
        <Route
          path={APP_PATHES.CHAT}
          element={<ExternalRedirect urlOrigin={TP_API_BASE} to={APP_PATHES.CHAT} />}
        />
        <Route
          path={APP_PATHES.SETTINGS}
          element={<ExternalRedirect urlOrigin={TP_API_BASE} to={APP_PATHES.SETTINGS} />}
        />
        <Route
          path={APP_PATHES.WALLET}
          element={<ExternalRedirect urlOrigin={TP_API_BASE} to={APP_PATHES.WALLET} />}
        />
        <Route
          path={`${APP_PATHES.IMPORT}/:tab?`}
          element={
            <MainLayout>
              <CatalogPage />
            </MainLayout>
          }
        />
        <Route path={`${APP_PATHES.IMPORT_MASTER}/:mode?/:dataId?/:modId?`} element={<ImportMasterPage />} />
        <Route path={`${APP_PATHES.IMPORT_MASTER_EXCEL}/:taskId`} element={<MatchingTableColumnsPage />} />
      </Routes>
    </>
  );
};

export default App;
