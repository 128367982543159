import { useEffect, useMemo, useState } from "react";

import { useForm, useWatch } from "antd/es/form/Form";
import { useDispatch, useSelector } from "react-redux";
import { AppStateType } from "redux/reducers/mainReducer";
import { AppDispatch } from "redux/store/store";
import { agreedEula } from "redux/thunks/account.thunk";

import ModalTerms from "components/modals/ModalTerms";

const ModalEULA = (): JSX.Element => {
  const dispatch = useDispatch<AppDispatch>();

  const {
    eula: { data: dataEula, isAgreed: isAgreedEula },
    kbo: { isAgreed: isAgreedKbo },
  } = useSelector((state: AppStateType) => state.accountSlice.siteTerms);

  const [form] = useForm<{ isAccepted: boolean; }>();
  const values = useWatch([], form);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const isOkDisabled: boolean = useMemo(() => !values?.isAccepted || isLoading, [values, isLoading]);

  useEffect(() => {
    const hasEula: boolean = dataEula && isAgreedEula !== null;

    setIsOpen(isAgreedKbo && hasEula && !isAgreedEula);
  }, [isAgreedKbo, dataEula, isAgreedEula]);

  const onOkHandler = (): void => {
    setIsLoading(true);
    dispatch(agreedEula())
      .then(() => setIsOpen(false))
      .finally(() => setIsLoading(false));
  };

  const onCancelHandler = (): void => {
    setIsOpen(false);
  };

  return (
    <ModalTerms
      key="modalTerms"
      isOpen={isOpen}
      title="Изменение в условиях обработки персональных данных"
      description="Здравствуйте! Для продолжения работы на платформе Бизнес Коннект
        необходимо ознакомиться и согласовать условия обработки персональных данных."
      okHandler={onOkHandler}
      cancelHandler={onCancelHandler}
      isOkDisabled={isOkDisabled}
      needAccept
      data={dataEula}
      form={form}
      initialValues={{ isAccepted: false }}
      className="eula"
    />
  );
};

export default ModalEULA;