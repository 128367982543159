import { JSX, FC, useState, useMemo, useEffect } from "react";
import { useParams } from "react-router";
import { ReactComponent as HelpOutlinedIcon } from "../../../assets/icons/help_outlined_icon.svg";

// components
import { FormInstance } from "antd";
import DragAndDrop, { DraggerFileType } from "components/ui/DragAndDrop/DragAndDrop";
import { transformLoadedFiles } from "components/ui/DragAndDrop/transformLoadedFiles";
import MediaRequirementsModal from "components/modals/MediaRequirementsModal/MediaRequirementsModal";
import { ProductMasterFormType } from "pages/ImportMasterPage";

import useFormInstance from "antd/es/form/hooks/useFormInstance";
import { ImageType } from "products/types";
import { ModificationType } from "products/types";

interface IPhotoBlockProps {
  addMedia: (file: DraggerFileType) => DraggerFileType;
  deleteMedia: (fileList: DraggerFileType[]) => DraggerFileType[];
  modification: ModificationType;
  resetFileList?: boolean;
  isDisabledFields: boolean;
}

const PhotoBlock: FC<IPhotoBlockProps> = ({
  addMedia,
  deleteMedia,
  modification,
  resetFileList,
  isDisabledFields
}): JSX.Element => {
  const { modId } = useParams<{ mode: string, dataId?: string, modId?: string }>();

  const [currentModification, setCurrentModification] = useState(null);

  const [
    isShowRequirementsModal,
    setIsShowRequirementsModal
  ] = useState<boolean>(false);

  const form: FormInstance<ProductMasterFormType> = useFormInstance<ProductMasterFormType>();
  const { getFieldValue, setFieldsValue } = form;

  const photos = useMemo((): ImageType[] => currentModification?.images
    ?.map((image: ImageType) => ({
      ...image,
      image: `${image?.image}`
    }))
    ?.filter((image: ImageType) => {
      return /\.(jpeg|jpg|png|heic|webp|JPEG|JPG|PNG|HEIC|WEBP)/.test(image.filename);
    }), [currentModification]);

  useEffect(() => {
    return () => {
      setCurrentModification(null);
    };
  }, []);

  useEffect(() => {
    const isIdEqual: boolean = !!modification && modification.id?.toString() === modId;
    isIdEqual && setCurrentModification(modification);
  }, [modification, modId]);

  const filterFilesFromDragger = (
    fileUid: string,
    valueName: keyof ProductMasterFormType
  ): DraggerFileType[] => {
    if (!!getFieldValue(valueName) && "fileList" in getFieldValue(valueName)) {
      return getFieldValue(valueName)?.fileList?.filter(i => i.uid !== fileUid);
    }

    return getFieldValue(valueName)?.filter(({ uid }) => uid !== fileUid);
  };

  const handleAfterUpload = (file: DraggerFileType, fieldName: string): void => {
    const files: DraggerFileType[] = filterFilesFromDragger(file.uid, fieldName as keyof ProductMasterFormType);

    setFieldsValue({ [fieldName]: [...files, file] });
  };

  const handleOnRemove = (file: DraggerFileType, fieldName: string): void => {
    const files: DraggerFileType[] = filterFilesFromDragger(file.uid, fieldName as keyof ProductMasterFormType);

    setFieldsValue({ [fieldName]: files, });
  };

  return (
    <div>
      <DragAndDrop
        type="photo"
        form={form}
        isMultiple
        action="/api/v1/temp_documents/create_image/"
        title="Фото"
        placeholder="Загрузите файлы или перетащите их сюда"
        description="Не более 10 мб одно фото. Формат: jpg, jpeg, png, heic, webp"
        infoTitle={(
          <p className="flex items-center cursor-pointer blue-color" onClick={() => setIsShowRequirementsModal(true)}>
            Какими должны быть фото
            <HelpOutlinedIcon />
          </p>
        )}
        showCounter
        isDisabled={isDisabledFields}
        maxQuantity={10}
        additionalAddRequest={addMedia}
        additionalDeleteRequest={deleteMedia}
        loadedFilelist={photos?.length
          ? transformLoadedFiles(photos)
          : []
        }
        resetFilesList={resetFileList}
        acceptedFileType={{ extensions: ["jpeg", "jpg", "png", "heic", "webp"], maxItemSize: 10 * 1024 * 1024 }}
        afterUpload={(file: DraggerFileType) => handleAfterUpload(file, "photo")}
        onRemove={(file: DraggerFileType) => handleOnRemove(file, "photo")}
      />
      {isShowRequirementsModal && (
        <MediaRequirementsModal
          isOpen={isShowRequirementsModal}
          onCancelHandler={() => setIsShowRequirementsModal(false)}
        />
      )}
    </div>
  );
};

export default PhotoBlock;
