import * as ProductsAPI from "api/products";
import { ThunkAction } from "redux-thunk";
import { AppStateType } from "redux/reducers/mainReducer";
import { AxiosResponse, CancelToken } from "axios";

import {
  fetchProductPropertiesRequest,
  fetchProductPropertiesSuccess,
  fetchProductPropertiesFailure,

  createProductRequest,
  createProductSuccess,
  createProductFailure,

  deleteProductRequest,
  deleteProductSuccess,
  deleteProductFailure,

  deleteAllProductsRequest,
  deleteAllProductsSuccess,
  deleteAllProductsFailure,

  updateProductRequest,
  updateProductSuccess,
  updateProductFailure,

  fetchProductModificationsRequest,
  fetchProductModificationsSuccess,
  fetchProductModificationsFailure,
  clearFetchedModificationsList,

  fetchModificationRequest,
  fetchModificationSuccess,
  fetchModificationFailure,

  createModificationRequest,
  createModificationSuccess,
  createModificationFailure,

  deleteModificationRequest,
  deleteModificationSuccess,
  deleteModificationFailure,

  duplicateModificationRequest,
  duplicateModificationSuccess,
  duplicateModificationFailure,

  fetchProductRequest,
  fetchProductSuccess,
  fetchProductFailure,
  
  fetchProductsAllRequest,
  fetchProductsAllSuccess,
  fetchProductsAllFailure,

  fetchVendorCatalogsRequest,
  fetchVendorCatalogsSuccess,
  fetchVendorCatalogsFailure,

  clearSelectedProduct,
} from "redux/slices/productsSlice";

import {
  ProductsRequestType,
  PaginatedProductsType,
  ProductCreateRequestType,
  ProductUpdateRequestType,
  DefaultPaginationRequestType,
  ModificationType,
  PaginatedVendorCatalogType,
  ModificationCreateType,
  ModificationCreateRequestType,
  ModificationDeleteRequestType,
  ProductType,
  PaginatedPropertiesType,
  PaginatedModificationsType,
  MinMaxCatalogPriceType,
} from "products/types";

/** Получение списка товаров */
export const fetchProducts = (
  body?: ProductsRequestType,
  cancelToken?: CancelToken
): ThunkAction<
  Promise<any>,
  AppStateType,
  undefined,
  any
> => async (dispatch) => {
  dispatch(fetchProductsAllRequest(body));
  try {
    const response: AxiosResponse<PaginatedProductsType, any> = await ProductsAPI.getProducts(body, cancelToken);
    const minMaxPrice: AxiosResponse<
      MinMaxCatalogPriceType, any
    > = await ProductsAPI.getMinMaxCatalogPrice(cancelToken);

    dispatch(fetchProductsAllSuccess({ ...response?.data, ...minMaxPrice?.data }));

    return Promise.resolve(response?.data);
  } catch (error: any) {
    dispatch(fetchProductsAllFailure(error?.message));

    return Promise.reject(error);
  }
};

/** Создание продукта */
export const createProduct = (
  body: ProductCreateRequestType,
  cancelToken?: CancelToken,
): ThunkAction<
  Promise<ProductType>,
  AppStateType,
  undefined,
  any
> => async (dispatch) => {
  dispatch(createProductRequest(body));
  try {
    const response: AxiosResponse<ProductType, any> = await ProductsAPI.postProduct(body, cancelToken);

    dispatch(createProductSuccess(response?.data));

    return Promise.resolve(response?.data);
  } catch (error: any) {
    dispatch(createProductFailure(error));

    return Promise.reject(error);
  }
};

/** Получение товара */
export const fetchProduct = (
  productId: number,
  cancelToken?: CancelToken,
): ThunkAction<
  Promise<ProductType>,
  AppStateType,
  undefined,
  any
> => async (dispatch) => {
  dispatch(fetchProductRequest(productId));
  try {
    const response: AxiosResponse<ProductType, any> = await ProductsAPI.getProduct(
      productId,
      cancelToken,
    );

    dispatch(fetchProductSuccess(response?.data));

    return Promise.resolve(response?.data);
  } catch (error: any) {
    dispatch(fetchProductFailure(error?.message));

    return Promise.reject(error);
  }
};
// Очистка товара
export const clearProduct = (): ThunkAction<
  Promise<void>,
  AppStateType,
  undefined,
  any
> => async (dispatch) => {
  dispatch(clearSelectedProduct(null));
};

/** Массовое или единичное удаление товаров из каталога */
export const deleteProduct = (
  productIds: number[],
  cancelToken?: CancelToken,
): ThunkAction<
  Promise<any>,
  AppStateType,
  undefined,
  any
> => async (dispatch) => {
  dispatch(deleteProductRequest({ productIds }));
  try {
    const response: AxiosResponse<any, any> = await ProductsAPI.deleteProduct(productIds, cancelToken);

    dispatch(deleteProductSuccess(response?.data));

    return Promise.resolve(response?.data);
  } catch (error: any) {
    dispatch(deleteProductFailure(error?.message));

    return Promise.reject(error);
  }
};

/** Удаление всего списка продуктов с учетом фильтров и поиска */
export const deleteAllProducts = (
  params: ProductsRequestType,
  cancelToken?: CancelToken
): ThunkAction<
  Promise<any>,
  AppStateType,
  undefined,
  any
> => async (dispatch) => {
  dispatch(deleteAllProductsRequest({}));
  try {
    const response: AxiosResponse<any, any> = await ProductsAPI.deleteAllProducts(params, cancelToken);

    dispatch(deleteAllProductsSuccess(response?.data));

    return Promise.resolve(response?.data);
  } catch (error: any) {
    dispatch(deleteAllProductsFailure(error?.message));

    return Promise.reject(error);
  }
};

/** Получение списка свойств товаров  */
export const fetchProperties = (
  body?: ProductsRequestType,
  cancelToken?: CancelToken
): ThunkAction<
  Promise<PaginatedPropertiesType>,
  AppStateType,
  undefined,
  any
> => async (dispatch) => {
  dispatch(fetchProductPropertiesRequest(body));
  try {
    const response: AxiosResponse<PaginatedPropertiesType, any> = await ProductsAPI.getProductProperties(
      body,
      cancelToken,
    );

    dispatch(fetchProductPropertiesSuccess(response?.data));

    return Promise.resolve(response?.data);
  } catch (error: any) {
    dispatch(fetchProductPropertiesFailure(error?.message));

    return Promise.reject(error);
  }
};

/** Обновление продукта */
export const updateProduct = (
  productId: number,
  body: ProductUpdateRequestType,
  cancelToken?: CancelToken,
): ThunkAction<
  Promise<ProductType>,
  AppStateType,
  undefined,
  any
> => async (dispatch) => {
  dispatch(updateProductRequest(body));
  try {
    const response: AxiosResponse<ProductType, any> = await ProductsAPI.patchProduct(
      productId,
      body,
      cancelToken,
    );

    dispatch(updateProductSuccess(response?.data));

    return Promise.resolve(response?.data);
  } catch (error: any) {
    dispatch(updateProductFailure(error?.message));

    return Promise.reject(error);
  }
};

/** Список модификаций товаров */
export const fetchProductModifications = (
  productId: number,
  body?: DefaultPaginationRequestType,
  cancelToken?: CancelToken,
): ThunkAction<
  Promise<PaginatedModificationsType>,
  AppStateType,
  undefined,
  any
> => async (dispatch) => {
  dispatch(fetchProductModificationsRequest(body));
  try {
    const response: AxiosResponse<PaginatedModificationsType, any> = await ProductsAPI.getProductModifications(
      productId,
      body,
      cancelToken,
    );

    dispatch(fetchProductModificationsSuccess(response?.data));

    return Promise.resolve(response?.data);
  } catch (error: any) {
    dispatch(fetchProductModificationsFailure(error?.message));

    return Promise.reject(error);
  }
};

// Очистка найденных модификаций
export const clearModificationsList = (): ThunkAction<
  Promise<void>,
  AppStateType,
  undefined,
  any
> => async (dispatch) => {
  dispatch(clearFetchedModificationsList(null));
};

/** Модификация товара */
export const fetchModification = (
  modificationId: number,
  cancelToken?: CancelToken,
): ThunkAction<
  Promise<ModificationType>,
  AppStateType,
  undefined,
  any
> => async (dispatch) => {
  dispatch(fetchModificationRequest({}));
  try {
    const response: AxiosResponse<ModificationType, any> = await ProductsAPI.getModification(
      modificationId,
      cancelToken,
    );

    dispatch(fetchModificationSuccess(response?.data));

    return Promise.resolve(response?.data);
  } catch (error: any) {
    dispatch(fetchModificationFailure(error?.message));

    return Promise.reject(error);
  }
};

/** Создание модификации */
export const createModification = (
  body: ModificationCreateRequestType,
  cancelToken?: CancelToken,
): ThunkAction<
  Promise<ModificationCreateType>,
  AppStateType,
  undefined,
  any
> => async (dispatch) => {
  dispatch(createModificationRequest(body));
  try {
    const response: AxiosResponse<ModificationCreateType, any> = await ProductsAPI.postModification(
      body,
      cancelToken,
    );

    dispatch(createModificationSuccess(response?.data));

    return Promise.resolve(response?.data);
  } catch (error: any) {
    dispatch(createModificationFailure(error?.message));

    return Promise.reject(error);
  }
};

/** Удаление модификации */
export const deleteModification = (
  body: ModificationDeleteRequestType,
  cancelToken?: CancelToken,
): ThunkAction<
  Promise<any>,
  AppStateType,
  undefined,
  any
> => async (dispatch) => {
  dispatch(deleteModificationRequest(body));
  try {
    const response: AxiosResponse<any, any> = await ProductsAPI.deleteModification(
      body,
      cancelToken,
    );

    dispatch(deleteModificationSuccess(body.modification_id));

    return Promise.resolve(response?.data);
  } catch (error: any) {
    dispatch(deleteModificationFailure(error));

    return Promise.reject(error);
  }
};

/** Дублирование модификации */
export const dublicateModification = (
  modificationId: number,
  cancelToken?: CancelToken,
): ThunkAction<
  Promise<ModificationType>,
  AppStateType,
  undefined,
  any
> => async (dispatch) => {
  dispatch(duplicateModificationRequest(modificationId));
  try {
    const response: AxiosResponse<ModificationType, any> = await ProductsAPI.duplicateModification(
      modificationId,
      cancelToken,
    );

    dispatch(duplicateModificationSuccess(response?.data));

    return Promise.resolve(response?.data);
  } catch (error: any) {
    dispatch(duplicateModificationFailure(error?.message));

    return Promise.reject(error);
  }
};

/** Листинг каталогов */
export const fetchVendorCatalogs = (
  cancelToken?: CancelToken,
): ThunkAction<
  Promise<PaginatedVendorCatalogType>,
  AppStateType,
  undefined,
  any
> => async (dispatch) => {
  dispatch(fetchVendorCatalogsRequest({}));
  try {
    const response: AxiosResponse<PaginatedVendorCatalogType, any> = await ProductsAPI.getVendorCatalogs(
      cancelToken,
    );

    dispatch(fetchVendorCatalogsSuccess(response?.data));

    return Promise.resolve(response?.data);
  } catch (error: any) {
    dispatch(fetchVendorCatalogsFailure(error?.message));

    return Promise.reject(error);
  }
};
