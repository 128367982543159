import React, { FC } from "react";

import {
  Input,
  Form,
} from "antd";

import css from "../style.module.scss";

interface IAuthSettingsBlockProps {
  needAuth: boolean;
}

const AuthSettingsBlock: FC<IAuthSettingsBlockProps> = ({ needAuth }) => {
  return (needAuth && (
    <div className="auth_wrapper flex flex-col">
      <div className="flex flex-col gap-2">
        <div className="input_wrapper">
          <p><span className={css.red}>*</span> Логин для авторизации</p>
          <Form.Item className="m-0" name="username">
            <Input placeholder="Введите логин" size="large" />
          </Form.Item>
        </div>
        <div className="input_wrapper">
          <p><span className={css.red}>*</span> Пароль</p>
          <Form.Item className="m-0" name="password">
            <Input
              placeholder="Введите пароль"
              type="password"
              size="large"
            />
          </Form.Item>
        </div>
      </div>
    </div>
  ));
};

export default AuthSettingsBlock;