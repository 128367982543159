import * as WarehousesAPI from "../../api/warehouses";
import { ThunkAction } from "redux-thunk";
import {
  fetchWarehousesRequest,
  fetchWarehousesSuccess,
  fetchWarehousesFailure
} from "../slices/warehousesSlice";

import { AppStateType } from "../reducers/mainReducer";
import { AxiosResponse, CancelToken } from "axios";
import { WarehouseType } from "app/types";

{/**** Задания на импорт ****/ }

/** Получение списка складов */
export const getWarehouses = (
  cancelToken?: CancelToken
): ThunkAction<
  Promise<any>,
  AppStateType,
  undefined,
  any
> => async (dispatch) => {
  dispatch(fetchWarehousesRequest({}));
  try {
    const response: AxiosResponse<WarehouseType[], any> = await WarehousesAPI.getWarehouses(cancelToken);

    dispatch(fetchWarehousesSuccess(response?.data));

    return Promise.resolve(response?.data);
  } catch (error: any) {
    dispatch(fetchWarehousesFailure(error?.message));

    return Promise.reject(error);
  }
};