import React, { JSX, FC } from "react";
import { Divider, Modal } from "antd";

//icons
import { ReactComponent as FileIcon } from "assets/icons/file-icon.svg";
import { ReactComponent as CropIcon } from "assets/icons/crop.svg";
import { ReactComponent as PictureInPicture } from "assets/icons/picture_in_picture_center.svg";

import GhostButton from "components/ui/GhostButton/GhostButton";

import css from "./style.module.scss";

type RequirementType = {
  icon: JSX.Element;
  content: React.ReactNode;
  hint?: string;
};

interface IMediaRequirementsModalProps {
  isOpen: boolean;
  onCancelHandler: () => void;
}

const photoRequirements: RequirementType[] = [
  {
    icon: <FileIcon />,
    content: "Формат: JPEG, JPG, PNG, HEIC, WEBP",
  },
  {
    icon: <PictureInPicture />,
    content: "Максимальный размер изображения до 10 МБ",
  },
  {
    icon: <CropIcon />,
    content: "Минимальное разрешение 900 x 900 до 4320 x 7680 пикселей",
    hint: "Разрешение может быть не менее 900 пикселей по наименьшей стороне",
  },
  {
    icon: <PictureInPicture />,
    content: (
      <div className="flex flex-col">
        <div className="flex flex-col">
          <span>Соотношение сторон</span>
          <span>Рекомендовано использовать:</span>
        </div>
        <ul className={css.list}>
          <li>вертикальное 3:4</li>
          <li>квадратное 1:1</li>
        </ul>
      </div>),
  },
];

const MediaRequirementsModal: FC<IMediaRequirementsModalProps> = ({ isOpen, onCancelHandler }): JSX.Element => {
  const renderRequirementsBlock = (title: string, requirements: RequirementType[]) => {
    return (
      <div>
        <p className="text-16-m mb-3">{title}</p>
        {requirements.map(({ icon, content, hint }: RequirementType, idx: number) => (
          <div key={idx} className="pr-14">
            <p className={`flex ${hint ? "" : "mb-2"}`}>
              {icon}
              <span className="ml-2">{content}</span>
            </p>
            {hint && (
              <p className="text-12-r mb-2 pl-6 secondary-color">{hint}</p>
            )}
          </div>
        ))}
      </div>
    );
  };

  return (
    <Modal
      width={572}
      open={isOpen}
      footer={null}
      centered
      onCancel={onCancelHandler}
      maskStyle={{ backgroundColor: "rgba(103, 118, 140, 0.5)" }}
    >
      <h4 className="mb-5">Требования для загрузки медиафайлов</h4>
      {renderRequirementsBlock("Фото", photoRequirements)}
      <Divider />
      <div className="flex justify-end">
        <GhostButton text="Назад" size="large" onClickHandler={onCancelHandler} />
      </div>
    </Modal>
  );
};

export default MediaRequirementsModal;
