import { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "redux/store/store";
import { checkSiteTerms } from "redux/thunks/account.thunk";

import ModalKBO from "./modals/ModalKBO";
import ModalEULA from "./modals/ModalEULA";
import ModalDocuments from "./modals/ModalDocuments";
import { AppStateType } from "redux/reducers/mainReducer";

const SiteTerms = (): JSX.Element => {
  const dispatch = useDispatch<AppDispatch>();

  const [isInit, setIsInit] = useState<boolean>(true);

  const {
    otherDocs: { notResponded: otherDocsIds },
  } = useSelector((state: AppStateType) => state.accountSlice.siteTerms);
  const {
    name
  } = useSelector((state: AppStateType) => state.accountSlice.user);

  useEffect(() => {
    if (isInit && name && !!otherDocsIds) {
      dispatch(checkSiteTerms(otherDocsIds)).then(() => {
        setIsInit(false);
      });
    }
  }, [isInit, name, otherDocsIds]);

  return (
    <div>
      <ModalKBO />
      <ModalEULA />
      <ModalDocuments />
    </div>
  );
};

export default SiteTerms;