import React, { JSX, FC, useEffect, useState, useRef, MutableRefObject } from "react";
import { getBasketCount, getMessengerCount } from "../../../api/businessConnect";
import { APP_PATHES } from "constants/appPathes";

import { ReactComponent as HelpIcon } from "assets/icons/help-icon.svg";
import { ReactComponent as NotificationsIcon } from "assets/icons/notifications-icon.svg";
import { ReactComponent as CartIcon } from "assets/icons/cart-icon.svg";
import { ReactComponent as MessagesIcon } from "assets/icons/messages-icon.svg";
import { ReactComponent as WalletIcon } from "assets/icons/wallet-icon.svg";
import { Badge } from "antd";
import { Link } from "react-router-dom";

import css from "./style.module.css";

interface IHeaderNavBarProps {
  isVendor: boolean;
}

const HeaderNavBar: FC<IHeaderNavBarProps> = ({isVendor}): JSX.Element => {
  const [cartCount, setCartCount] = useState<number | null>(null);
  const [messengerCount, setMessengerCount] = useState<number | null>(null);

  const timeoutRef: MutableRefObject<NodeJS.Timeout> = useRef<NodeJS.Timeout | null>(null);

  // Флаг для отслеживания первого рендера - лечит дублирование запросов
  const isFirstRender: MutableRefObject<boolean> = useRef<boolean>(true);

  useEffect(() => {
    if (isFirstRender.current) {
      fetchMessengerCount();

      getBasketCount()
        .then((res) => setCartCount(res.data?.count))
        .catch(console.error);
    }

    isFirstRender.current = false;

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  const fetchMessengerCount = async (): Promise<void> => {
    await getMessengerCount()
      .then(({ data }) => {
        const newCount: number = data.reduce((total: number, item: { total: number }) => total + item.total, 0);

        setMessengerCount(newCount);
      });

      timeoutRef.current = setTimeout(fetchMessengerCount, 30000);
  };

  const renderBadge = (icon: JSX.Element, count: number = 0): JSX.Element => {
    return (
      <Badge
        count={count || null}
        size="small"
        style={{ top: 5, right: 5 }}
      >
        {icon}
      </Badge>
    );
  };

  return (
    <div className={`flex ${css.navBarBlock}`}>
      {!isVendor && (
        <Link to={APP_PATHES.HELP_MAIN}>
          <HelpIcon className={`cursor-pointer ${css.icon}`} />
        </Link>
      )}
      {renderBadge(<NotificationsIcon className={`ml-4 cursor-pointer ${css.icon}`} />)}
      {!isVendor && (
        <>
          {renderBadge(
            <Link to={isVendor ? "/platform/cart" : APP_PATHES.CART}>
              <CartIcon className={`ml-4 cursor-pointer ${css.icon}`} />
            </Link>,
            cartCount
          )}
          {renderBadge(
            <Link to={APP_PATHES.CHAT}>
              <MessagesIcon className={`ml-4 cursor-pointer ${css.icon}`} />
            </Link>,
            messengerCount
          )}
          <Link to={APP_PATHES.WALLET}>
            <WalletIcon className={`mx-4 cursor-pointer ${css.icon}`} />
          </Link>
        </>
      )}
    </div>
  );
};

export default HeaderNavBar;
