export type StaticFiltersType = {
  name: string;
  label: string;
  isActive: boolean;
};

// статические фильтры для таблицы сопоставления категорий
export const comparisonStaticFilters: StaticFiltersType[] = [
  {
    name: "isMatched",
    label: "Есть привязка к категории",
    isActive: false,
  },
  {
    name: "isNotMatched",
    label: "Нет привязки к категории",
    isActive: false,
  }
];

// статические фильтры для таблицы периодических загрузок (автообновление)
export const periodicUploadingStaticFilters: StaticFiltersType[] = [
  {
    name: "Успешно",
    label: "Успешные",
    isActive: false,
  },
  {
    name: "Ошибка",
    label: "Ошибка",
    isActive: false,
  },
  {
    name: "Новый",
    label: "Новые",
    isActive: false,
  },
];

// статические фильтры для таблицы разовых загрузок
export const sourceUploadingStaticFilters: StaticFiltersType[] = [
  {
    name: "Успешно",
    label: "Успешные",
    isActive: false,
  },
  {
    name: "Ошибка",
    label: "Ошибка",
    isActive: false,
  },
  {
    name: "В загрузке",
    label: "В процессе загрузки",
    isActive: false,
  },
  {
    name: "Черновик",
    label: "Черновик",
    isActive: false,
  },
];
