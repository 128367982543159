import { createSlice } from "@reduxjs/toolkit";
import { SupplierSettingsType } from "suppliers/types";

export type InitialStateSupplierType = {
  settings: {
    useNds: boolean,
    ndsRate: number;
  },
  error: string | null;
  isFetching: boolean;
};

const initialState: InitialStateSupplierType = {
  settings: {
    useNds: null,
    ndsRate: null,
  },
  isFetching: false,
  error: null,
};

const supplierSlice = createSlice({
  name: "supplier",
  initialState,
  reducers: {
    /** Настройки аккаунта поставщика
     * - GET - v1/accounts/supplier/settings/ */
    fetchSupplierSettingsRequest(state) {
      state.error = null;
      state.isFetching = true;
    },
    fetchSupplierSettingsSuccess(state, action: { payload: SupplierSettingsType, type: any }) {
      state.settings.useNds = action.payload.use_nds;
      state.settings.ndsRate = action.payload.nds_rate;
      
      state.error = null;
      state.isFetching = false;
    },
    fetchSupplierSettingsFailure(state, action) {
      state.error = action.payload;
      state.isFetching = false;
    },
  },
});

const { actions, reducer } = supplierSlice;

export const {
  fetchSupplierSettingsRequest,
  fetchSupplierSettingsSuccess,
  fetchSupplierSettingsFailure,
} = actions;

export default reducer;
