import { FC, useCallback } from "react";

import PagePanel from "components/ui/Panel/PagePanel";
import ImportYML from "components/ImportMaster/ImportYML";
import ImportExcel from "components/ImportMaster/ImportExcel";
import ImportManual from "components/ImportMaster/ImportManual";
import { useParams } from "react-router";

export type ImportModeType = "yml" | "xls" | "manual";

interface IImportModePanelProps { };

const ImportModePanel: FC<IImportModePanelProps> = () => {
  const { mode } = useParams<{ mode: ImportModeType }>();

  const ImportModeBlock = useCallback((): JSX.Element => {
    switch (mode) {
      case "yml":
        return <ImportYML />;

      case "xls":
        return <ImportExcel />;

      case "manual":
        return <ImportManual />;

      default:
        return <ImportManual />;
    }
  }, [mode]);

  return (
    <PagePanel className="flex flex-row gap-4">
      <ImportModeBlock />
    </PagePanel>
  );
};

export default ImportModePanel;