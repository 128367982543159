import { FC, useState, useEffect, useMemo, useCallback, ReactNode, createContext } from "react";
import { useLocation, NavigateFunction, useNavigate, useParams, Routes, Route } from "react-router";
import { useDispatch, useSelector } from "react-redux";

// redux
import { AppDispatch } from "redux/store/store";
import { createFileImportTask, createSourceImportTask } from "redux/thunks/importTask.thunk";

// utils
import useCancelableDebounce from "hooks/useCancelableDebounce";
import { handleError } from "../../utils/handleError";

// components
import { Spin, Select, Tooltip, Button, Form, RadioChangeEvent, Segmented, notification } from "antd";
import ImportModePanel, { ImportModeType } from "components/ImportMaster/ImportModePanel";
import PageWrapper from "components/ui/PageWrapper";
import PagePanel from "components/ui/Panel/PagePanel";
import PanelBlock from "components/ui/Panel/PanelBlock";
import ImportMasterLayout from "components/ImportMaster/ImportMasterLayout";

// types
import { getWarehouses } from "redux/thunks/warehouses.thunk";
import { AppStateType } from "redux/reducers/mainReducer";
import {
  ImportTaskUploadSettingsType,
  ImportTaskFileRequestType,
  ImportTaskAuthorizationType,
  ImportTaskLinkRequestType
} from "importTask/types";
import { SETTINGS_TYPE } from "components/ImportMaster/ImportYML";
import { useWatch } from "antd/es/form/Form";

import { setCurrentTable } from "redux/slices/importTaskSlice";
import {
  clearModificationsList,
  clearProduct,
  createProduct,
  fetchVendorCatalogs,
  updateProduct,
} from "redux/thunks/products.thunk";
import {
  ModificationType,
  ProductCreateRequestType,
  ProductType,
  ProductUpdateRequestType,
} from "products/types";
import { APP_PATHES } from "constants/appPathes";
import { DraggerFileType } from "components/ui/DragAndDrop/DragAndDrop";
import { PriceValueType } from "components/ImportMaster/ImportManual/ImportProductForm/PriceInput";

/** Типы статусов у продукта */
export enum ProductStatus {
  DRAFT = "Черновик",
  ERROR = "Ошибка",
  PUBLISHED = "Размещен",
  MODERATION = "На модерации",
  REJECTED = "Отклонен",
}
/** Поля формы импорта YML */
export type ImportMasterFormType = {
  source: ImportModeType;
  settings: string[];
  fileField: any;
  fileLink: string;
  name: string;
  username: string;
  password: string;
}
/** Поля товара */
export type ProductFieldsFormType = {
  productName?: string;
  productCode?: string;
  categoryBK?: number[];
  nds?: string;
}
/** Тип свойства в значениях формы */
export type ProductMasterPropertyType = {
  propertyName: string;
  propertyValue: string;
};
/** Поля модификации */
export type ModificationFieldsFormType = {
  name: string;
  code?: string;
  minCount?: string;
  description?: string;
  price?: PriceValueType;
  properties?: ProductMasterPropertyType[];
  photo?: DraggerFileType[];
  showInCatalog?: boolean;
  isBase?: boolean;
}
/** Поля формы импорта MANUAL */
export interface ProductMasterFormType extends ProductFieldsFormType, ModificationFieldsFormType { }
/** Ключи формы импорта MANUAL */
export type ProductMasterFieldNameType = keyof ProductMasterFormType;
/** Все поля формы с лэйаутом */
export interface IFormFields extends ImportMasterFormType, ProductMasterFormType {
  uploadMode: "url" | "file" | "manual";
  warehouse: number;
}

interface ProductContextType {
  isFocused: boolean;
  setIsFocused: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ProductContext = createContext<ProductContextType>({
  isFocused: false,
  setIsFocused: () => { },
});

interface IImportMasterPage { }

const ImportMasterPage: FC<IImportMasterPage> = () => {
  const dispatch = useDispatch<AppDispatch>();

  const location = useLocation();
  const navigate: NavigateFunction = useNavigate();
  const { mode, dataId, modId } = useParams<{ mode: string, dataId?: string, modId?: string }>();

  const [form] = Form.useForm<IFormFields>();
  const { getFieldsValue, getFieldValue, resetFields } = form;
  useWatch([], form);

  const {
    selected: selectedProduct
  } = useSelector((state: AppStateType) => state.productsSlice.products);

  const {
    list: vendorCatalogsList,
    isFetching: isLoadingVendorCatalogs,
  } = useSelector((state: AppStateType) => state.productsSlice.vendorCatalogs);

  const {
    list: warehousesList,
    isFetching: isLoadingWarehouses
  } = useSelector((state: AppStateType) => state.warehousesSlice);

  const {
    isFetching: isUploading
  } = useSelector((state: AppStateType) => state.importTaskSlice);

  const {
    item: uploadedFile
  } = useSelector((state: AppStateType) => state.tempDocumentsSlice);

  const isManualMode: boolean = mode === "manual";
  const [selectedWarehouse, setSelectedWarehouse] = useState<number | null>(null);
  const [isUploadDisabled, setIsUploadDisabled] = useState<boolean>(true);
  const [isFocused, setIsFocused] = useState<boolean>(false);

  const params: URLSearchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);

  const { debouncedFunction: getVendorCatalogs } = useCancelableDebounce((_params, token) => {
    dispatch(fetchVendorCatalogs(token))
      .catch((error: any) => {
        showMessage("error", "", "Ошибка при загрузке списка каталогов.", error);
      });
  });

  const { debouncedFunction: fetchWarehouses } = useCancelableDebounce((_params, token) => {
    dispatch(getWarehouses(token))
      .catch((error: any) => {
        showMessage("error", "", "Ошибка при загрузке списка складов.", error);
      });
  });

  useEffect(() => {
    getVendorCatalogs({});

    return () => {
      // Очистка полей при закрытии формы
      resetFields([
        "productName", "productCode", "categoryBK", "name", "code", "minCount",
        "description", "price", "properties", "photo", "showInCatalog", "isBase", "nds"
      ]);
      dispatch(clearModificationsList());
      dispatch(clearProduct());
    };
  }, []);

  useEffect(() => {
    if (!dataId && !selectedWarehouse && warehousesList.length) {
      setSelectedWarehouse(Number(warehousesList?.[0]?.id));
      form.setFieldValue("warehouse", Number(warehousesList?.[0]?.id));
    }
  }, [dataId, warehousesList, selectedWarehouse]);

  useEffect(() => {
    fetchWarehouses?.({});
  }, [params, fetchWarehouses]);

  useEffect(() => {
    resetFields(["fileField", "fileLink"]);
  }, [mode]);

  useEffect(() => {
    if (!!isManualMode && !!selectedWarehouse && !!selectedProduct) {

      const warehouse: number = getFieldsValue()?.warehouse;
      const baseModification: ModificationType = selectedProduct?.modifications
        ?.find(({ is_base }) => !!is_base);
      const isNewWarehouseSelected: boolean = !!warehouse
        && warehouse !== baseModification?.warehouse_id;

      if (isNewWarehouseSelected && !!warehouse && !!baseModification)
        handlerUpdateProduct(null, () => showMessage("success", "Склад товара изменен"));
    }
  }, [isManualMode, selectedWarehouse, selectedProduct]);

  useEffect(() => {
    const { fileField, fileLink } = getFieldsValue() || {};

    const hasUploadLink: boolean = !!fileLink;
    const hasFiles: boolean = !!fileField?.file?.name && !!uploadedFile?.original_filename;
    const hasSelectedWarehouse: boolean = !!selectedWarehouse;
    const isUploaded: boolean = hasFiles && fileField?.file?.name === uploadedFile?.original_filename;

    setIsUploadDisabled(!(isUploaded || hasUploadLink) || !hasSelectedWarehouse || isUploading);
  }, [getFieldsValue(), uploadedFile, selectedWarehouse, mode, isUploading]);

  const onCancelHandler = (): void => {
    navigate(APP_PATHES.IMPORT);
  };

  const getAuthSetting = (): ImportTaskAuthorizationType => {
    const { username, password } = getFieldsValue();

    return ({
      username,
      password,
    });
  };

  const getUploadSettings = (): ImportTaskUploadSettingsType => {
    const { settings, uploadMode } = getFieldsValue();
    const isYml: boolean = mode === "yml";
    const isUrlMode: any = uploadMode === "url";

    return {
      is_periodic: isYml && isUrlMode && settings?.includes(SETTINGS_TYPE.isPeriodic),
      update_rest: isYml && settings?.includes(SETTINGS_TYPE.updateRest),
      update_price: isYml && settings?.includes(SETTINGS_TYPE.updatePrice),
      update_property: isYml && settings?.includes(SETTINGS_TYPE.updateProperty),
      clear_products: isYml && settings?.includes(SETTINGS_TYPE.clearProducts),
      first_line_skip: isYml && settings?.includes(SETTINGS_TYPE.firstLineSkip),
    };
  };

  const getRequestImportTaskBody = (): ImportTaskFileRequestType | ImportTaskLinkRequestType => {
    const { name, fileLink, settings } = getFieldsValue();

    const requestBody: ImportTaskFileRequestType | ImportTaskLinkRequestType = {
      ...getUploadSettings(),
      warehouse_id: selectedWarehouse,
      name: name,
      temp_document_id: uploadedFile?.id,
      link: fileLink,
      ...(settings?.includes(SETTINGS_TYPE.logIn) && getAuthSetting())
    };

    return requestBody;
  };

  const getRequestProductBody = (): ProductCreateRequestType => {
    const {
      categoryBK,
      productCode,
      productName,
      warehouse
    } = getFieldsValue();

    // TODO: Передавать vendor_catalog как будет апи
    return {
      category: categoryBK?.at(-1),
      warehouse_id: warehouse,
      name: productName,
      code: productCode,
    };
  };

  const showMessage = (
    type: "error" | "success",
    messageTitle: string,
    messageText?: string,
    error?: any
  ): void => {
    if (type === "success") {
      notification.success({
        message: messageTitle,
        description: messageText,
        duration: 15
      });
    } else {
      handleError(messageText, error);
    }
  };

  const handleSelectWarehouse = (value: number): void => {
    setSelectedWarehouse(value);
  };

  const createTaskHandle = (): void => {
    const { fileLink } = getFieldsValue() || {};
    const hasUploadLink: boolean = !!fileLink;
    const isPeriodic: boolean = !!getUploadSettings()?.is_periodic;
    const requestBody: ImportTaskFileRequestType | ImportTaskLinkRequestType = getRequestImportTaskBody();

    const createImportTaskThunkAction = mode === "yml" && hasUploadLink
      ? createSourceImportTask(mode, requestBody as ImportTaskLinkRequestType)
      : createFileImportTask(mode as ImportModeType, requestBody as ImportTaskFileRequestType);

    dispatch(createImportTaskThunkAction)
      .then(({ id: taskId, source, is_periodic }) => {
        const isYML: boolean = source === "YML";
        const path: string = isYML
          ? `${APP_PATHES.IMPORT}/1`
          : `${APP_PATHES.IMPORT_MASTER_EXCEL}/${taskId}`;

        dispatch(setCurrentTable(isYML && is_periodic ? "importTasks" : "sources"));

        navigate(path);
      })
      .catch((error: any) => showMessage(
        "error",
        "",
        "При добавлении каталога в очередь загрузки произошла ошибка",
        error
      ));
  };

  const createProductHandler = (): void => {
    const requestBody: ProductCreateRequestType = getRequestProductBody();

    dispatch(createProduct(requestBody))
      .then((response: ProductType) => {
        showMessage("success", "Товар создан");
        navigate(`${APP_PATHES.IMPORT_MASTER_MANUAL}/${response.id}`);
      })
      .catch((error: any) => showMessage("error", "", "Ошибка при создании товара", error));
  };

  const handlerUpdateProduct = (params?: ProductUpdateRequestType, onComplete?: () => void): void => {
    const { warehouse } = getFieldsValue();

    !!dataId && dispatch(updateProduct(Number(dataId), {
      ...params,
      modification: {
        id: Number(modId),
        warehouse_id: Number(warehouse),
        ...(params?.modification || {}),
      }
    }))
      .then(() => onComplete?.())
      .catch((error) => {
        showMessage("error", "", "Ошибка при обновлении", error);
      });
  };

  const handleSaveProduct = (): void => {
    const onSuccessHandler = (): void => {
      showMessage("success", "Товар успешно добавлен");
      navigate(APP_PATHES.IMPORT);
    };

    handlerUpdateProduct(
      {
        status: ProductStatus.PUBLISHED,
        modification: {
          id: Number(modId),
        }
      },
      onSuccessHandler
    );
  };

  const handleSaveDraft = (): void => {
    const onSuccessHandler = (): void => {
      showMessage("success", "Черновик сохранен");
      navigate(APP_PATHES.IMPORT);
    };

    handlerUpdateProduct({
      status: "Черновик",
      modification: {
        id: Number(modId),
      }
    },
      onSuccessHandler
    );
  };

  const ImportModeBlock = useCallback((): JSX.Element => {
    const handleChangeImportMode = (value: ImportModeType): void => {
      navigate(`${APP_PATHES.IMPORT_MASTER}/${value}`);
    };

    return (
      <PanelBlock title={{ content: "Выберите вариант импорта" }} wrapper={{ className: "" }}>
        <Segmented
          value={mode}
          onChange={handleChangeImportMode}
          defaultValue="manual"
          rootClassName="w-fit"
          options={[
            {
              label: (
                <Tooltip title="Добавьте продукты, используя ручное создание">
                  Добавить вручную
                </Tooltip>
              ),
              value: "manual"
            },
            {
              label: (
                <Tooltip title="Добавьте продукты, используя прейскурант в Excel (XLS, CSV)">
                  Импорт Excel
                </Tooltip>
              ),
              value: "xls"
            },
            {
              label: (
                <Tooltip title="Публикация продуктов с использованием формата YML, Yandex.Market или Commerce-ML">
                  Импорт YML
                </Tooltip>
              ),
              value: "yml"
            }
          ]}
          size="large"
        />
      </PanelBlock>
    );
  }, [mode]);

  // TODO: Отключаем блок пока нет каталогов в апи
  const CatalogBlock = useCallback((): JSX.Element => !!mode && (
    <PanelBlock title={{ content: "Куда загружать каталог", isRequired: true }}>
      <Form.Item name="warehouse" className="w-full m-0">
        <Select
          placeholder="Список складов"
          disabled={!vendorCatalogsList?.length}
          notFoundContent={isLoadingVendorCatalogs ? <Spin spinning={isLoadingVendorCatalogs} /> : null}
          className="w-full"
          size="large"
        >
          {vendorCatalogsList?.map(({ id, name }) => (
            <Select.Option key={id} value={id}>
              {name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </PanelBlock>
  ), [
    mode,
    vendorCatalogsList,
    isLoadingVendorCatalogs,
  ]);

  const isWarehouseDisabled: boolean = useMemo(() => {
    const { id } = selectedProduct?.modifications?.find(({ is_base }) => !!is_base) || {};

    return (isManualMode && modId !== id?.toString());
  }, [isManualMode, modId, selectedProduct]);

  const WarehouseBlock = useCallback((): JSX.Element => (
    <PanelBlock title={{ content: "Склад", isRequired: true }}>
      <Tooltip title={isWarehouseDisabled && "Склад можно изменить только у базовой модификации"}>
        <Form.Item name="warehouse" className="w-full m-0">
          <Select
            placeholder="Выберите склад"
            value={selectedWarehouse}
            onSelect={handleSelectWarehouse}
            disabled={!warehousesList?.length || isWarehouseDisabled}
            notFoundContent={isLoadingWarehouses ? <Spin spinning={isLoadingWarehouses} /> : null}
            className="w-full"
            size="large"
          >
            {warehousesList?.map(({ id, name }) => (
              <Select.Option key={id} value={id}>
                {name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Tooltip>
    </PanelBlock>
  ), [
    isWarehouseDisabled,
    selectedWarehouse,
    warehousesList,
    isLoadingWarehouses,
  ]);

  const ImportButton = (): JSX.Element => (
    <Tooltip
      title={
        !isUploading
        && isUploadDisabled
        && "Заполните все необходимые данные и обязательные поля, помеченные звездочкой (*)"
      }
    >
      <Button
        type="primary"
        size="large"
        onClick={createTaskHandle}
        disabled={isUploadDisabled}
      >
        Загрузить каталог
      </Button>
    </Tooltip>
  );

  const hasWarehouse: boolean = useMemo(() => !!getFieldValue("warehouse"), [getFieldValue("warehouse")]);
  const hasName: boolean = useMemo(() => !!getFieldValue("name"), [getFieldValue("name")]);
  const hasCategory: boolean = useMemo(() => !!getFieldValue("categoryBK")?.length, [getFieldValue("categoryBK")]);
  const hasPrice: boolean = useMemo(() => !!getFieldValue("price")?.price, [getFieldValue("price")]);
  const isShowInCatalog: boolean = useMemo(() => !!getFieldValue("showInCatalog"), [getFieldValue("showInCatalog")]);
  const baseModification: ModificationType = useMemo(() => selectedProduct?.modifications
    ?.find(({ is_base }) => !!is_base), [selectedProduct?.modifications]);
  const isShowBase: boolean = useMemo(() => baseModification?.show_in_catalog, [baseModification?.show_in_catalog]);
  const isButtonsDisabled: boolean = useMemo(() => (
    !hasName && !hasCategory || !dataId || !modId
  ), [hasName, hasCategory, dataId, modId]);

  const isSaveDisabled: boolean = useMemo(() => (
    isButtonsDisabled || !hasPrice || !isShowInCatalog || !isShowBase
  ), [isButtonsDisabled, hasPrice, isShowInCatalog, isShowBase]);

  const saveTooltip = useMemo(() => {
    const messages: { condition: boolean; message: string; }[] = [
      { condition: !dataId, message: "Заполните поля товара" },
      { condition: !modId, message: "Выберите модификацию" },
    ];

    return messages.find(({ condition }) => condition)?.message || "";
  }, [hasWarehouse, hasName, hasCategory, dataId, modId]);

  const saveProductTooltip = useMemo((): string => {
    const isBaseSelected: boolean = baseModification?.id?.toString() === modId;

    const messages: { condition: boolean; message: string; }[] = [
      { condition: !hasPrice, message: "Заполните цену" },
      {
        condition: !isShowInCatalog || !isShowBase,
        message: `Поставьте галочку 'Показать в каталоге'
          ${(!isBaseSelected && !isShowBase) ? " у базовой модификации" : ""}`
      },
    ];

    return messages.find(({ condition }) => condition)?.message || "";
  }, [modId, hasPrice, isShowInCatalog, isShowBase]);

  const ManualButtons = useCallback((): JSX.Element => (
    <div className="buttons_wrapper flex gap-2">
      <Tooltip title={saveTooltip}>
        <Button
          size="large"
          onClick={handleSaveDraft}
          disabled={isButtonsDisabled}
        >
          Сохранить как черновик
        </Button>
      </Tooltip>
      <Tooltip title={saveTooltip || saveProductTooltip}>
        <Button
          size="large"
          onClick={(!!dataId || !!dataId && !!modId) ? handleSaveProduct : createProductHandler}
          disabled={isSaveDisabled || isFocused}
        >
          Сохранить и завершить
        </Button>
      </Tooltip>
    </div>
  ), [getFieldsValue()]);

  const UploadPanel = useCallback((): JSX.Element => (
    <PagePanel className="apply_wrapper flex justify-end">
      {
        !mode || mode === "manual"
          ? <ManualButtons />
          : <ImportButton />
      }
    </PagePanel>
  ), [isUploadDisabled, mode, getUploadSettings()]);

  return (
    <ProductContext.Provider value={{ isFocused, setIsFocused }}>
      <ImportMasterLayout title="Добавление каталога" cancelHandler={onCancelHandler}>
        <PageWrapper className="gap-0 p-2 py-0 sm:px-2 md:px-8 lg:px-16 xl:px-24 2xl:px-32">
          <Form form={form} layout="vertical">
            <PagePanel className="flex flex-col md:flex-row gap-5">
              <ImportModeBlock />
              {/* TODO: Отключаем блок пока нет каталогов в апи */}
              {/* <CatalogBlock /> */}
              <WarehouseBlock />
            </PagePanel>
            <ImportModePanel />
          </Form>
          <UploadPanel />
        </PageWrapper>
      </ImportMasterLayout>
    </ProductContext.Provider>
  );
};

export default ImportMasterPage;
