import React, { JSX } from "react";
import Column from "antd/es/table/Column";
import { ImportTaskType } from "importTask/types";
import { EntityType } from "../CatalogTable";
import { SorterResult } from "antd/es/table/interface";

export const renderCatalogueTypeColumn = (
  renderColumnTitle: (title: string) => JSX.Element,
  sorter: SorterResult<any>,
  onClickHandler?: (...args: any[]) => void,
): JSX.Element => {
  return (
    <Column
      key="Catalogue_type"
      title={renderColumnTitle("Куда загружено")}
      dataIndex="catalog"
      width={250}
      sorter={{ multiple: 3 }}
      sortOrder={sorter?.field === "catalog" ? sorter?.order : undefined}
      showSorterTooltip={false}
      render={(value, entity: EntityType) => {
        const { id, status, source, is_periodic } = entity as ImportTaskType || {};

        const isNavigateToEdit: boolean = "is_periodic" in entity && !is_periodic && status === "Черновик";

        return (
          <div
            className={isNavigateToEdit && !!onClickHandler ? "cursor-pointer" : ""}
            onClick={() => isNavigateToEdit && onClickHandler?.(id, source)}
          >
            {value}
          </div>
        );
      }}
    />
  );
};
