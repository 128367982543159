import axios from "../utils/axios";
import { AxiosResponse, CancelToken } from "axios";
import { CurrencyResponseType, DefaultPaginationRequestType } from "currencies/types";

const API_BASE: string | undefined = process.env.REACT_APP_API_BASE_URL?.trimEnd();

{/**** Валюта ****/ }

/** Получение списка валют */
export const getCurrencies = (
  params: DefaultPaginationRequestType,
  cancelToken?: CancelToken
): Promise<AxiosResponse<CurrencyResponseType>> => {
  return axios.GET(API_BASE, "/api/v1/currency/all/", params, cancelToken);
};
