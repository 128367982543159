import { FC } from "react";

import {
  Checkbox,
  Form,
  CheckboxOptionType,
  Tooltip,
} from "antd";
import PanelBlock from "components/ui/Panel/PanelBlock";
import { ReactComponent as HelpIcon } from "assets/icons/help-icon.svg";

import css from "../style.module.scss";
import { SETTINGS_TYPE } from "..";

interface ISettingsBlockProps {
  uploadMode: "file" | "url";
}

const SettingsBlock: FC<ISettingsBlockProps> = ({
  uploadMode
}) => {
  const isUrlMode: boolean = uploadMode !== "url";

  const settingsList: CheckboxOptionType<any>[] = [
    {
      label: (
        <div className="flex justify-center">
          <p className="select-none">Периодическая загрузка</p>
          <Tooltip title="Периодическая загрузка возможна только при загрузке каталога по ссылке">
            <HelpIcon className={`cursor-pointer ${css.icon}`} />
          </Tooltip>
        </div>
      ),
      value: SETTINGS_TYPE.isPeriodic,
      disabled: isUrlMode,
    },
    {
      label: <p className="select-none">Остатки</p>,
      value: SETTINGS_TYPE.updateRest,
    },
    {
      label: <p className="select-none">Цены</p>,
      value: SETTINGS_TYPE.updatePrice,
    },
    {
      label: <p className="select-none">Свойства</p>,
      value: SETTINGS_TYPE.updateProperty,
    },
    {
      label: <p className="select-none">Удалить ранее загруженные товары</p>,
      value: SETTINGS_TYPE.clearProducts,
    },
    {
      label: <p className="select-none">Авторизация для загрузки каталога YML</p>,
      value: SETTINGS_TYPE.logIn,
      disabled: isUrlMode,
    }
  ];

  return (
    <PanelBlock title={{ content: "Настройки" }}>
      <Form.Item className="m-0" name="settings" valuePropName="checked">
        <Checkbox.Group
          options={settingsList}
          className="flex flex-col"
        />
      </Form.Item>
    </PanelBlock>
  );
};

export default SettingsBlock;
