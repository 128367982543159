import React, { JSX, FC, ReactNode } from "react";

interface IFilterWrapperProps {
  label: string;
  children: ReactNode;
  value?: ReactNode;
}

const FilterWrapper: FC<IFilterWrapperProps> = ({ label, value, children }: IFilterWrapperProps): JSX.Element => (
  <div className="flex flex-col mb-5">
    <div className="flex justify-between">
      <span className="inline-block mb-2 font-bold">{label}</span>
      {value && <div>{value}</div>}
    </div>
    {children}
  </div>
);

export default FilterWrapper;
