import { FC, ReactNode } from "react";

import css from "./style.module.scss";

type TitleSizeType = "normal" | "large" | "x-large";

export interface IPanelLayoutProps {
  title?: {
    content?: ReactNode;
    size?: TitleSizeType;
    className?: string;
  };
  description?: {
    content?: ReactNode;
    className?: string;
  };
  actions?: {
    content?: ReactNode;
    className?: string;
  };
  className?: string;
  children?: ReactNode;
}

const PanelLayout: FC<IPanelLayoutProps> = ({
  title = null,
  description = null,
  actions = null,
  className = "",
  children,
}) => {
  return (
    <div className="panel_layout flex flex-col gap-4 w-full">
      {(title || description || actions) && (
        <div className="header_wrapper flex flex-row justify-between">
          <div className="info_wrapper">
            {title && (
              <div className={`title_wrapper mb-1 ${title?.className || ""}`}>
                <h1 className={`${css.title_text} ${css[title?.size || "normal"]}`}>
                  {title.content}
                </h1>
              </div>
            )}
            {description && (
              <div className={`description_wrapper ${description?.className || ""}`}>
                <p className={`${css.description_text} ghost`}>
                  {description.content}
                </p>
              </div>
            )}
          </div>
          {actions && (
            <div className={`actions_wrapper ${actions?.className || ""}`}>
              {actions.content}
            </div>
          )}
        </div>
      )}
      {children && (
        <div className={`content_wrapper ${className || ""}`}>
          {children}
        </div>
      )}
    </div>
  );
};

export default PanelLayout;