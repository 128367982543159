import React, { JSX, FC, useEffect, useState } from "react";
import axios from "axios";

import { useDispatch, useSelector } from "react-redux";
import { AppStateType } from "redux/reducers/mainReducer";
import { AppDispatch } from "redux/store/store";
import { fetchCategories, fetchTPCategories } from "redux/thunks/categories.thunk";
import { ICategories, ICategoriesTP } from "redux/slices/categoriesSlice";
import useCancelableDebounce from "hooks/useCancelableDebounce";
import { PaginatedCategoryType } from "categories/types";
import { DefaultOptionType } from "antd/es/select";

// components
import FilterWrapper from "../../ui/Filters/FiltersDrawer/FilterWrapper";
import { Form, Select } from "antd";

interface ICategoryFiltersProps {

}

const CategoryFilters: FC<ICategoryFiltersProps> = (): JSX.Element => {
  const dispatch = useDispatch<AppDispatch>();

  const categories: ICategories = useSelector((state: AppStateType) => state.categoriesSlice).categories;
  const categoriesTP: ICategoriesTP = useSelector((state: AppStateType) => state.categoriesSlice).categoriesTP;

  const [categorySearchValue, setCategorySearchValue] = useState<string>(null);
  const [categoryTPSearchValue, setCategoryTPSearchValue] = useState<string>(null);
  const [supplierCategoriesOptions, setSupplierCategoriesOptions] = useState<DefaultOptionType[]>(null);
  const [supplierCategoriesTPOptions, setSupplierCategoriesTPOptions] = useState<DefaultOptionType[]>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (categoriesTP?.list) {
      setSupplierCategoriesTPOptions(categoriesTP?.list);
    }
  }, [categoriesTP?.list]);

  useEffect(() => {
    if (categories?.list) {
      setSupplierCategoriesOptions(categories.list.map(({ id, name }) => ({ value: id, label: name })));
    }
  }, [categories?.list]);

  useEffect(() => {
    if ((categorySearchValue !== null || !categories?.list) && !isLoading) {
      fetchSupplierCategories({ page: 1, page_size: 10, search: categorySearchValue });
    }
  }, [categorySearchValue, categories?.list]);

  useEffect(() => {
    if ((categoryTPSearchValue !== null || !categoriesTP?.list) && !isLoading) {
      getTPCategories({ search: categoryTPSearchValue });
    }
  }, [categoryTPSearchValue, categoriesTP?.list]);

  const { debouncedFunction: fetchSupplierCategories } = useCancelableDebounce((params, token) => {
    setIsLoading(true);

    dispatch(fetchCategories(params as PaginatedCategoryType, token))
      .then(() => setCategorySearchValue(null))
      .catch((error: any) => !axios.isCancel(error))
      .finally(() => setIsLoading(false));
  });

  const { debouncedFunction: getTPCategories } = useCancelableDebounce((params, token) => {
    setIsLoading(true);

    dispatch(fetchTPCategories(params as { search: string }, token))
      .then(() => setCategoryTPSearchValue(null))
      .catch((error: any) => !axios.isCancel(error))
      .finally(() => setIsLoading(false));
  });

  const handleSearchCategory = (value: string) => setCategorySearchValue(value);

  const handleSearchCategoryTP = (value: string) => setCategoryTPSearchValue(value);

  return (
    <>
      <FilterWrapper label="По категории">
        <Form.Item className="m-0" name="supplierCategory" initialValue={[]}>
          <Select
            mode="multiple"
            size="large"
            placeholder="Выберите категорию"
            showSearch
            options={supplierCategoriesOptions}
            onSearch={handleSearchCategory}
            labelInValue
            filterOption={false}
          />
        </Form.Item>
      </FilterWrapper>
      <FilterWrapper label="По категории БК">
        <Form.Item className="m-0" name="categoryBK" initialValue={[]}>
          <Select
            mode="multiple"
            size="large"
            placeholder="Выберите категорию"
            showSearch
            options={supplierCategoriesTPOptions}
            onSearch={handleSearchCategoryTP}
            labelInValue
            filterOption={false}
          />
        </Form.Item>
      </FilterWrapper>
    </>
  );
};

export default CategoryFilters;
