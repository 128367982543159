import React, { JSX, FC } from "react";
import GhostButton from "components/ui/GhostButton/GhostButton";
import { SearchInput } from "components/ui/SearchInput/SearchInput";

interface ISearchFilterPanelProps {
  placeholder: string;
  onSearch: (value: string) => void;
  onShowFiltersDrawer: () => void;
}

const SearchFilterPanel: FC<ISearchFilterPanelProps> = ({
  placeholder,
  onSearch,
  onShowFiltersDrawer,
}): JSX.Element => {
  return (
    <div className="flex w-full mb-3">
      <SearchInput
        placeholder={placeholder}
        onSearch={onSearch}
      />
      <GhostButton
        size="large"
        text="Фильтры"
        className="ml-3"
        onClickHandler={onShowFiltersDrawer}
      />
    </div>
  );
};

export default SearchFilterPanel;
