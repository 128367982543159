import axios from "../utils/axios";
import { AxiosResponse, CancelToken } from "axios";
import { RcFile } from "antd/es/upload";

import { TemporaryDocumentType } from "tempDocuments/types";

const API_BASE: string | undefined = process.env.REACT_APP_API_BASE_URL?.trimEnd();

{/**** Временный документ ****/ }

/** Создание временного документа */
export const postTempDocument = (
  file: RcFile,
  cancelToken?: CancelToken
): Promise<AxiosResponse<TemporaryDocumentType>> => {
  const formData: FormData = new FormData();
  formData.append("file", file);
  
  return axios.POST(API_BASE, "/api/v1/temp_documents/create/", formData, cancelToken, true);
};

/** Создание временного документа */
export const postTempDocumentImage = (
  file: RcFile,
  cancelToken?: CancelToken
): Promise<AxiosResponse<TemporaryDocumentType>> => {
  const formData: FormData = new FormData();
  formData.append("file", file);
  
  return axios.POST(API_BASE, "/api/v1/temp_documents/create_image/", formData, cancelToken, true);
};

/** Получение временного документа */
export const getTempDocument = (
  tempDocumentId: string,
  cancelToken?: CancelToken
): Promise<AxiosResponse<TemporaryDocumentType>> => {
  return axios.GET(API_BASE, `/api/v1/queues/temp_documents/${tempDocumentId}/`, null, cancelToken);
};