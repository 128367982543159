import { FC, useCallback } from "react";

import {
  Form,
  Segmented,
} from "antd";
import PanelBlock from "components/ui/Panel/PanelBlock";
import FileUploadButton from "components/FileUploadButton";
import UploadPeriodicBlock from "../UploadPeriodicBlock";

interface IFileUploadBlockProps {
  uploadMode: string;
  setUploadMode: (mode: "file" | "url") => void;
  needAuth: boolean;
  isPeriodic: boolean;
}
const FileUploadBlock: FC<IFileUploadBlockProps> = ({
  uploadMode,
  setUploadMode,
  needAuth,
  isPeriodic,
}) => {
  const onChangeHandler = (value: string): void => setUploadMode(value as "file" | "url");

  return (
    <PanelBlock title={{ content: "Загрузка файла" }} className="gap-5">
      <Form.Item className="m-0" name="uploadMode" initialValue="file">
        <Segmented
          onChange={onChangeHandler}
          value={uploadMode}
          options={[
            { label: "Из файла", value: "file" },
            { label: "По ссылке", value: "url" }
          ]}
          size="large"
        />
      </Form.Item>

      {uploadMode === "file"
        ? (
          <div className="flex items-center gap-4">
            <FileUploadButton
              acceptType=".yml, .xml"
              description="Файл XML с форматом данных YML"
            />
          </div>
        ) : (
          <UploadPeriodicBlock
            isPeriodic={isPeriodic}
            needAuth={needAuth}
          />
        )
      }
    </PanelBlock>
  );
};

export default FileUploadBlock;