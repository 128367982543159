import React, { JSX } from "react";
import { SorterResult } from "antd/es/table/interface";

import Column from "antd/es/table/Column";
import TextWithTooltip from "components/ui/TextWithTooltip/TextWithTooltip";

export const renderBKCategoryColumn = (
  renderColumnTitle: (title: string) => JSX.Element,
  sorter: SorterResult<any>,
): JSX.Element => {
  return (
    <Column
      key="Bk_category"
      title={renderColumnTitle("Категория БК")}
      dataIndex="category_bk"
      width={200}
      sorter={{ multiple: 3 }}
      sortOrder={sorter?.field === "category_bk" ? sorter?.order : undefined}
      showSorterTooltip={false}
      sortDirections={["ascend", "descend"]}
      render={(value: string) => value ? (
        <TextWithTooltip text={value} className="secondary-color" />
      ) : (
        <span>-</span>
      )}
    />
  );
};

