import React, { JSX } from "react";
import { SorterResult } from "antd/es/table/interface";

import Column from "antd/es/table/Column";
import TextWithTooltip from "components/ui/TextWithTooltip/TextWithTooltip";

export const renderProductsCountColumn = (
  renderColumnTitle: (title: JSX.Element) => JSX.Element,
  sorter: SorterResult<any>,
): JSX.Element => {
  return (
    <Column
      key="Products_count"
      title={renderColumnTitle(<>Товаров <br/> в категории</>)}
      dataIndex="qty_products"
      width={110}
      sorter={{ multiple: 3 }}
      sortOrder={sorter?.field === "qty_products" ? sorter?.order : undefined}
      showSorterTooltip={false}
      sortDirections={["ascend", "descend"]}
      render={(value: number) => <TextWithTooltip text={value?.toString() ?? "0"} />}
    />
  );
};
