import React, { JSX, FC, Dispatch, SetStateAction } from "react";
import { FormInstance, Tag } from "antd";
import dayjs from "dayjs";

import css from "./style.module.css";

interface IFiltersDrawerTagsProps {
  form: FormInstance<Record<string, any>>;
  filters: Record<string, any>;
  setFilters: Dispatch<SetStateAction<Record<string, any>>>;
  resetFilters: () => void;
  setIsFiltersConfirmed: Dispatch<SetStateAction<boolean>>;
  defaultValues?: Record<string, any>;
}

const FiltersDrawerTags: FC<IFiltersDrawerTagsProps> = ({
  form,
  filters,
  setFilters,
  resetFilters,
  setIsFiltersConfirmed,
  defaultValues,
}): JSX.Element => {
  const clearFilterData = (): void => {
    resetFilters();
    setIsFiltersConfirmed(true);
  };

  const deleteFilter = (key: string, defaultValue?: any): void => {
    const newFilters: Record<string, any> = { ...filters };
    const [fieldName, fieldValue] = key.split("-");

    delete newFilters[key];

    if (fieldValue && Array.isArray(form.getFieldValue(fieldName))) {
      // Для полей select multiple (например, supplierCategory-9198)
      form.setFieldsValue({
        [fieldName]: form.getFieldValue(fieldName).filter(({ value }) => value !== +fieldValue),
      });
    } else {
      // Для обычных полей
      form.setFieldsValue({ [key]: defaultValue !== undefined ? defaultValue : undefined });
    }

    setFilters({ ...newFilters });
    setIsFiltersConfirmed(true);
  };

  const tagRender = (index: string | number, key: string, value: string | number): JSX.Element => {
    const displayValue: string | number = dayjs(value, "YYYY-MM-DD", true).isValid()
      ? dayjs(value).format("DD.MM.YYYY")
      : value;

    return (
      <Tag
        key={index}
        className={`${css.commonTagStyle} ${css.alignedTag}`}
        closable
        onClose={() => deleteFilter(key, defaultValues[key])}
      >
        <div className={css.tagBlock}>
          <div className={`${css.tagValue} ml-1`}>{displayValue}</div>
        </div>
      </Tag>
    );
  };

  return (
    <div>
      <div className={css.tag}>
        {Object.entries(filters).map(([key, value], index: number) =>
          Array.isArray(value)
            ? value.map((item: string, subIndex: number) => tagRender(`${index}-${subIndex}`, key, item))
            : tagRender(index, key, value as string)
        )}
        {!!Object.keys(filters)?.length && (
          <Tag className={`${css.commonTagStyle} ${css.tagButton}`} onClick={clearFilterData}>
            Сбросить фильтры
          </Tag>
        )}
      </div>
    </div>
  );
};

export default FiltersDrawerTags;
