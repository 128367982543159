import React, {
  FC,
  JSX,
  Dispatch,
  SetStateAction,
  ReactNode,
} from "react";

import { Button, Drawer, FormInstance } from "antd";
import PrimaryButton from "../../../ui/PrimaryButton/PrimaryButton";

interface IFiltersDrawerProps {
  form: FormInstance<Record<string, any>>;
  isOpen: boolean;
  disabledSubmit: boolean;
  disabledClearAll: boolean;
  resetFilters: () => void;
  setIsFiltersOpen: Dispatch<SetStateAction<boolean>>;
  setIsFiltersConfirmed: Dispatch<SetStateAction<boolean>>;
  children: ReactNode;
}

const FiltersDrawer: FC<IFiltersDrawerProps> = ({
  form,
  isOpen,
  disabledSubmit,
  disabledClearAll,
  resetFilters,
  setIsFiltersOpen,
  setIsFiltersConfirmed,
  children,
}): JSX.Element => {
  const closeDrawer = (): void => {
    setIsFiltersOpen(false);
  };

  const confirmFilters = (): void => {
    closeDrawer();
    setIsFiltersConfirmed(true);
  };

  const clearActiveFilters = (): void => {
    form.resetFields();
    resetFilters();
    setIsFiltersConfirmed(true);
  };

  const renderDrawerFooter = (): JSX.Element => (
    <div className="pt-2 flex items-center justify-between">
      <Button
        size="large"
        className="mr-2.5 px-9"
        onClick={clearActiveFilters}
        disabled={disabledClearAll}
      >
        Сбросить все
      </Button>
      <PrimaryButton
        className="px-11"
        type="primary"
        text="Применить"
        isDisabled={disabledSubmit}
        onClickHandler={confirmFilters}
      />
    </div>
  );

  return (
    <Drawer
      title="Фильтры"
      placement="right"
      onClose={closeDrawer}
      open={isOpen}
      footer={renderDrawerFooter()}
      maskClosable={false}
    >
      {children}
    </Drawer>
  );
};

export default FiltersDrawer;
