import React, { JSX, FC, useState, useMemo } from "react";
import { NavigateFunction, useNavigate, useParams } from "react-router";
import useFormInstance from "antd/es/form/hooks/useFormInstance";
import { handleError } from "utils/handleError";

import { useDispatch, useSelector } from "react-redux";
import { AppStateType } from "redux/reducers/mainReducer";
import { AppDispatch } from "redux/store/store";
import {
  createModification,
  deleteModification,
  dublicateModification,
} from "redux/thunks/products.thunk";

import { Button, List, notification, Skeleton } from "antd";
import ModificationCard from "components/Products/ModificationCard";
import PrimaryButton from "components/ui/PrimaryButton/PrimaryButton";
import ConfirmModal from "components/ui/modals/ConfirmModal/ConfirmModal";

import "./style.scss";

import { ModificationCreateType, ModificationType } from "products/types";
import { IFormFields } from "pages/ImportMasterPage";

import { APP_PATHES } from "constants/appPathes";

interface IProductModificationsWrapperProps {
  isLoading: boolean;
}

const ProductModificationsWrapper: FC<IProductModificationsWrapperProps> = (): JSX.Element => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate: NavigateFunction = useNavigate();
  const { dataId, modId } = useParams<{ mode?: string, dataId?: string, modId?: string }>();

  const form = useFormInstance<IFormFields>();

  const {
    selected: selectedModification,
    isFetching: isLoadingModifications,
  } = useSelector((state: AppStateType) => state.productsSlice.modifications);
  const {
    selected: selectedProduct,
  } = useSelector((state: AppStateType) => state.productsSlice.products);

  const [isShowDeleteModal, setIsShowDeleteModal] = useState<boolean>(false);

  const isAddButtonDisabled: boolean = !selectedProduct?.modifications
    || selectedProduct?.modifications?.length === 0
    || selectedProduct?.modifications?.length === 30
    || !dataId;

  const getBaseModification = (): ModificationType => {
    return selectedProduct?.modifications?.find(({ id }) => id === Number(modId));
  };

  const deleteText: string = useMemo(() => {
    const { is_base } = getBaseModification() || {};

    return is_base
      ? "Продукт будет удален."
      : "Модификация будет удалена.";
  }, [getBaseModification()]);

  const onAddHandler = (): void => {
    if (!dataId) return;

    const { warehouse } = form.getFieldsValue();

    dispatch(createModification({
      name: "Новый вариант",
      product_id: Number(dataId),
      show_in_catalog: false,
      warehouse_id: warehouse
    }))
      .then((response: ModificationCreateType) => {
        // Очистка полей перед заполнением информации о созданной модификации
        form.resetFields([
          "name", "code", "minCount", "description", "price", "properties", "photo", "showInCatalog", "isBase",
        ]);

        navigate(`${APP_PATHES.IMPORT_MASTER_MANUAL}/${dataId}/${response.id}`);
      })
      .catch((error) => handleError("Ошибка при добавлении модификации", error));

  };

  const onDuplicationHandler = (): void => {
    dispatch(dublicateModification(Number(modId)))
      .then((response: ModificationType) => navigate(
        `${APP_PATHES.IMPORT_MASTER_MANUAL}/${dataId}/${response.id}`
      ))
      .catch((error: any) => handleError("Ошибка при дублировании модификации", error));
  };

  const onDeleteHandler = async (): Promise<void> => {
    const { is_base } = selectedProduct?.modifications
      ?.find(({ id }) => id === Number(modId)) || {};

    dispatch(deleteModification({ modification_id: selectedModification.id }))
      .then(() => {
        const navigateTo: string = is_base
          ? APP_PATHES.IMPORT
          : `${APP_PATHES.IMPORT_MASTER_MANUAL}/${dataId}/${selectedProduct.modifications?.at(0)?.id}`;

        setIsShowDeleteModal(false);
        navigate(navigateTo);
      })
      .catch((error: any) => {
        handleError("Ошибка при удалении модификации", error);
      });
  };

  return (
    <div className="modifications_wrapper h-full w-full">
      <div className="options_block">
        <List
          dataSource={selectedProduct?.modifications || []}
          renderItem={(modification: ModificationType) => (
            <Skeleton
              avatar={{ className: "skeleton-list-item" }}
              paragraph={{ rows: 1 }}
              className="px-4 py-3 cursor-pointer mb-3"
              style={{ border: "1px solid #1e4bd2", borderRadius: "12px" }}
              loading={isLoadingModifications}
              active={isLoadingModifications}
            >
              <ModificationCard
                key={modification?.id}
                modification={modification}
              />
            </Skeleton>
          )}>

        </List>
      </div>

      <div className="actions_wrapper flex flex-col gap-2">
        <div className="flex justify-between gap-2">
          <PrimaryButton
            className="w-full"
            text="Добавить вариант"
            onClickHandler={onAddHandler}
            isDisabled={isAddButtonDisabled}
          />
        </div>
        <div className="flex gap-2">
          <Button
            size="large"
            className="w-full grow-1/2"
            onClick={onDuplicationHandler}
            disabled={!selectedModification || !modId}
          >
            Дублировать
          </Button>
          <Button
            size="large"
            className="w-full grow-1/2"
            onClick={() => setIsShowDeleteModal(true)}
            disabled={!selectedModification || !modId}>
            Удалить выбранный
          </Button>
        </div>
      </div>
      <ConfirmModal
        text={deleteText}
        isOpen={!!isShowDeleteModal}
        onOkHandler={onDeleteHandler}
        onCancelHandler={() => setIsShowDeleteModal(false)}
      />
    </div>
  );
};

export default ProductModificationsWrapper;
