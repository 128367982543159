import { FC, useEffect } from "react";

type ExternalRedirectPropsType = {
  urlOrigin: string;
  to: string;
}

const ExternalRedirect: FC<ExternalRedirectPropsType> = ({ urlOrigin, to }) => {
  useEffect(() => {
    if (!!urlOrigin && !!to) {
      window.location.href = `${urlOrigin}${to}`;
    }
  }, [urlOrigin, to]);

  return null;
};

export default ExternalRedirect;