import React, { JSX, FC, Dispatch, ReactNode, Key, useState, useEffect } from "react";
import axios from "axios";
import useCancelableDebounce from "hooks/useCancelableDebounce";
import { useForm, useWatch } from "antd/es/form/Form";
import { comparisonStaticFilters, StaticFiltersType } from "../TableFilters/utils/staticFilters";
import { handleError } from "utils/handleError";
import generateSorter from "utils/generateSorter";

// redux
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "redux/store/store";
import { AppStateType } from "redux/reducers/mainReducer";
import {
  deleteCategoriesMatch,
  deleteSupplierCategories,
  fetchCategories,
  fetchTPCategories,
  saveCategoriesMatch,
} from "../../../redux/thunks/categories.thunk";

// types
import { SorterResult, TablePaginationConfig, TableRowSelection } from "antd/es/table/interface";
import { ICategories, ICategoriesTP } from "../../../redux/slices/categoriesSlice";
import { CategoryType, PaginatedCategoryType } from "categories/types";
import { ComparisonFiltersValuesType } from "app/types";

// components
import InfoCard from "components/ui/InfoCard/InfoCard";
import CatalogTable from "../CatalogTable/CatalogTable";
import ProductsTableStub from "../ProductsTableStub/ProductsTableStub";
import { Dropdown, FormInstance, MenuProps, notification, Spin } from "antd";
import ConfirmModal from "../../ui/modals/ConfirmModal/ConfirmModal";
import ComparisonFiltersPanel from "./ComparisonFiltersPanel/ComparisonFiltersPanel";

// icons
import { ReactComponent as MenuIcon } from "assets/icons/three_dots_icon.svg";
import { ReactComponent as CloseIcon } from "assets/icons/close-icon.svg";

interface IComparisonOfCategoriesTabProps {
  setActiveTab: Dispatch<React.SetStateAction<string>>;
}

const ComparisonOfCategoriesTab: FC<IComparisonOfCategoriesTabProps> = ({ setActiveTab }): JSX.Element => {
  const dispatch = useDispatch<AppDispatch>();
  const [form] = useForm<ComparisonFiltersValuesType>();
  const values: ComparisonFiltersValuesType = useWatch<FormInstance>([], form);

  const categories: ICategories = useSelector((state: AppStateType) => state.categoriesSlice).categories;
  const categoriesTP: ICategoriesTP = useSelector((state: AppStateType) => state.categoriesSlice).categoriesTP;

  const [searchParam, setSearchParam] = useState<string>(null);
  const [editingKey, setEditingKey] = useState<string>("");
  const [selectedRows, setSelectedRows] = useState<CategoryType[]>(null);
  const [categoriesToDelete, setCategoriesToDelete] = useState<CategoryType[]>([]);
  const [selectedKeys, setSelectedKeys] = useState<Key[]>(null);
  const [isShowInfoCard, setIsShowInfoCard] = useState<boolean>(localStorage.getItem("comparisonInfo") !== "true");
  const [paginationInfo, setPaginationInfo] = useState<TablePaginationConfig>({
    current: 1,
    pageSize: 10,
    locale: {
      items_per_page: "/ на странице",
    },
  });
  const [previousFilters, setPreviousFilters] = useState<ComparisonFiltersValuesType>({
    isMatched: false,
    isNotMatched: false,
    qtyProductsFrom: "",
    qtyProductsTo: "",
    categoryBK: [],
    supplierCategory: []
  });
  const [sorterInfo, setSorterInfo] = useState<SorterResult<any> | SorterResult<any>[]>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isShowDeleteModal, setIsShowDeleteModal] = useState<boolean>(false);
  const [isFiltersConfirmed, setIsFiltersConfirmed] = useState<boolean>(false);
  const [isStaticFilterConfirmed, setIsStaticFilterConfirmed] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>(null);
  const [staticFilters, setStaticFilters] = useState<StaticFiltersType[]>(comparisonStaticFilters);

  const isEditing = (categoryId: number): boolean => categoryId?.toString() === editingKey;

  useEffect(() => {
    fetchCategoriesWithComparisons({
      ...getRequestPagination(),
      search: searchValue,
      ...getRequestFilters(previousFilters),
      ...(sorterInfo ? { order_by: generateSorter(sorterInfo) } : {}),
    });
    setSelectedRows(null);
  }, [paginationInfo.current, paginationInfo.pageSize, searchValue, sorterInfo]);

  useEffect(() => {
    if (isFiltersConfirmed) {
      setIsFiltersConfirmed(false);

      fetchCategoriesWithComparisons({
        page: 1,
        page_size: paginationInfo.pageSize,
        ...(searchValue ? { search: searchValue } : {}),
        ...getRequestFilters(values),
        ...(sorterInfo ? { order_by: generateSorter(sorterInfo) } : {}),
      });

      // при изменении чекбоксов в основных фильтрах
      // также меняем состояние "быстрых" фильтров, но только после применения изменений
      setStaticFilters((prev) => {
        return prev.map((filter) => ({
          ...filter,
          isActive: values?.[filter.name] ?? filter.isActive
        }));
      });
    }
  }, [isFiltersConfirmed]);

  useEffect(() => {
    // при изменении состояния "быстрых" фильтров
    // также меняем состояние дублирующих чекбоксов в основных фильтрах
    staticFilters.forEach(({ name, isActive }) => {
      form.setFieldsValue({ [name]: isActive || false });
    });
  }, [staticFilters]);

  useEffect(() => {
    if (isStaticFilterConfirmed) {
      setIsStaticFilterConfirmed(false);

      fetchCategoriesWithComparisons({
        page: 1,
        page_size: paginationInfo.pageSize,
        ...(searchValue ? { search: searchValue } : {}),
        ...getRequestFilters(values),
        ...(sorterInfo ? { order_by: generateSorter(sorterInfo) } : {}),
      });
    }
  }, [isStaticFilterConfirmed]);

  useEffect(() => {
    setSelectedKeys(selectedRows?.map((value: CategoryType) => value.key));
  }, [selectedRows]);

  const { debouncedFunction: fetchCategoriesWithComparisons } = useCancelableDebounce((params, token) => {
    setIsLoading(true);

    dispatch(fetchCategories(params as PaginatedCategoryType, token))
      .catch((error: any) => !axios.isCancel(error) && handleError("Ошибка при загрузке списка категорий.", error))
      .finally(() => setIsLoading(false));
  });

  const getRequestPagination = (): PaginatedCategoryType => ({
    page: paginationInfo.current,
    page_size: paginationInfo.pageSize,
  });

  const getRequestFilters = (filters: ComparisonFiltersValuesType): PaginatedCategoryType => {
    const getMatchedFilterValue = (filterName: string): boolean =>
      filters?.[filterName] ?? staticFilters.find(f => f.name === filterName)?.isActive;

    const isMatched: boolean = getMatchedFilterValue("isMatched");
    const isNotMatched: boolean = getMatchedFilterValue("isNotMatched");
    const categorySupplierIds: number[] = filters?.supplierCategory?.map(({ value }) => (value as number));
    const categoryTpIds: number[] = filters?.categoryBK?.map(({ value }) => (value as number));

    return {
      ...(filters?.qtyProductsFrom ? { qty_products_from: +filters.qtyProductsFrom } : {}),
      ...(filters?.qtyProductsTo ? { qty_products_to: +filters.qtyProductsTo } : {}),
      ...(filters?.supplierCategory ? { category_supplier_ids: categorySupplierIds } : {}),
      ...(filters?.categoryBK ? { category_tp_ids: categoryTpIds } : {}),
      ...(isMatched ? { is_matched: true } : isNotMatched ? { is_matched: false } : {}),
    };
  };

  // Логика работы с чекбоксами у таблицы
  const rowSelection: TableRowSelection<any> = {
    selectedRowKeys: selectedKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRows(selectedRows);
      setSelectedKeys(selectedRowKeys);
    }
  };

  const getTPCategories = (search: string): void => {
    dispatch(fetchTPCategories({ search }))
      .catch((error: any) => {
        !axios.isCancel(error) && handleError("Ошибка при загрузке списка категорий Торговой площадки.", error);
      });
  };

  const edit = (categoryId: string): void => {
    setEditingKey(categoryId?.toString());

    !categoriesTP?.list?.length && getTPCategories(searchParam);
  };

  const saveComparison = (tpCategory: number): void => {
    setIsLoading(true);

    dispatch(saveCategoriesMatch(Number(editingKey), tpCategory))
      .then(() => {
        notification.success({ message: "Категория успешно привязана" });
        setEditingKey("");
      })
      .catch((error) => handleError(
        "При привязке к категории Бизнес Коннект произошла ошибка, пожалуйста, повторите позже.",
        error
      ))
      .finally(() => setIsLoading(false));
  };

  const deleteComparisons = (values: CategoryType[]): void => {
    setIsLoading(true);

    const ids: number[] = values
      ?.filter(({ category }) => Boolean(category))
      ?.map(({ id }) => id);

    dispatch(deleteCategoriesMatch(ids))
      .then(() => {
        notification.success({ message: "Привязки успешно удалены" });
        setSelectedRows(null);
      })
      .catch((error) => handleError("При удалении произошла ошибка, пожалуйста, повторите позже.", error))
      .finally(() => setIsLoading(false));
  };

  const deleteCategories = (): void => {
    setIsLoading(true);

    const ids: number[] = categoriesToDelete?.map(({ id }) => id);

    dispatch(deleteSupplierCategories(ids))
      .then(() => {
        notification.success({ message: "Категории успешно удалены" });
        setSelectedRows(null);
        setCategoriesToDelete([]);
        hideDeleteModal();
      })
      .then(() => fetchCategoriesWithComparisons({
        page: 1,
        page_size: paginationInfo.pageSize,
        ...(searchValue ? { search: searchValue } : {}),
        ...getRequestFilters(previousFilters),
      }))
      .catch((error) => handleError("При удалении категорий произошла ошибка, пожалуйста, повторите позже.", error))
      .finally(() => setIsLoading(false));
  };

  const hideDeleteModal = (): void => setIsShowDeleteModal(false);

  const hideInfoCard = (): void => {
    localStorage.setItem("comparisonInfo", "true");
    setIsShowInfoCard(false);
  };

  const renderActions = (value: CategoryType, idx: number): ReactNode => {
    const items: MenuProps["items"] = [
      value.category && {
        label: <a style={{ color: "#FF4D4F" }}>Удалить привязку <br /> к категории портала</a>,
        key: `delete-comparison-${idx}`,
        onClick: () => deleteComparisons([value]),
      },
      // TODO пока скрываем
      // {
      //   label: <a style={{color: "#FF4D4F"}}>Удалить категорию</a>,
      //   key: `delete-${idx}`,
      //   onClick: () => showDeleteModal([value]),
      // },
    ];

    return (
      <Dropdown menu={{ items }} trigger={["click"]}>
        <MenuIcon className="m-auto cursor-pointer secondary-color" />
      </Dropdown>
    );
  };

  return (
    <div>
      {isShowInfoCard && (
        <InfoCard
          text={{
            content: `Сопоставление категорий поставщика с категориями портала Бизнес Коннект необходимо для того,
              чтобы товары поставщика отображались в соответствующим им разделах каталога.`
          }}
          actionBtn={<CloseIcon onClick={hideInfoCard} className="cursor-pointer" />}
          icon={{ type: "question" }}
        />
      )}
      <ComparisonFiltersPanel
        form={form}
        values={values}
        showAutoMatchBtn={!!categories?.list?.length}
        deleteComparisons={deleteComparisons}
        setSearchValue={setSearchValue}
        selectedRows={selectedRows}
        setIsLoading={setIsLoading}
        isFiltersConfirmed={isFiltersConfirmed}
        isStaticFilterConfirmed={isStaticFilterConfirmed}
        setIsFiltersConfirmed={setIsFiltersConfirmed}
        setIsStaticFilterConfirmed={setIsStaticFilterConfirmed}
        staticFilters={staticFilters}
        setStaticFilters={setStaticFilters}
        previousFilters={previousFilters}
        setPreviousFilters={setPreviousFilters}
      />
      <Spin spinning={!categories?.list && isLoading}>
        <CatalogTable
          columns={[
            "category_supplier",
            "editable_bk_category",
            "products_count",
            "actions"
          ]}
          list={categories?.list}
          count={categories?.count}
          isFetching={isLoading}
          isShowEmptyBlock={!isLoading}
          rowSelection={rowSelection}
          emptyBlock={<ProductsTableStub setActiveTab={setActiveTab} />}
          renderActions={renderActions}
          setSorterInfo={setSorterInfo}
          setPaginationInfo={setPaginationInfo}
          editProps={{
            isLoading,
            setEditingKey,
            editingKey,
            setIsEditing: isEditing,
            save: saveComparison,
            edit
          }}
        />
      </Spin>
      {isShowDeleteModal && (
        <ConfirmModal
          text="Вы уверены, что хотите удалить выбранные вами категории? Это действие необратимо."
          isOpen={isShowDeleteModal}
          onOkHandler={deleteCategories}
          onCancelHandler={hideDeleteModal}
        >
          <div className="mb-5">
            <p className="secondary-color">Выбранные категории</p>
            {categoriesToDelete?.length && categoriesToDelete.map(({ chain, id }) => (
              <p key={id}>{chain}</p>
            ))}
          </div>
        </ConfirmModal>
      )}
    </div>
  );
};

export default ComparisonOfCategoriesTab;
