import { createSlice } from "@reduxjs/toolkit";
import { KboOrEulaType } from "terms/types";

export type InitialStateAccountType = {
  user: {
    username: string;
    name: string;
    firstName: string;
    lastName: string;
    email: string;
    error: string | null;
    isFetching: boolean;
  };
  siteTerms: {
    kbo: {
      isAgreed: boolean;
      data: KboOrEulaType;
      isFetching: boolean;
      error: any;
    };
    eula: {
      isAgreed: boolean;
      data: KboOrEulaType;
      isFetching: boolean;
      error: any;
    };
    otherDocs: {
      data: KboOrEulaType[];
      notResponded: number[];
      isFetching: boolean;
      error: any;
    };
    isFetching: boolean;
    error: any;
  };
};

const initialState: InitialStateAccountType = {
  user: {
    username: null,
    name: null,
    firstName: null,
    lastName: null,
    email: null,
    isFetching: false,
    error: null,
  },
  siteTerms: {
    kbo: {
      isAgreed: null,
      data: null,
      isFetching: false,
      error: null,
    },
    eula: {
      isAgreed: null,
      data: null,
      isFetching: false,
      error: null,
    },
    otherDocs: {
      data: null,
      notResponded: null,
      isFetching: false,
      error: null,
    },
    isFetching: null,
    error: null,
  },
};

const accountSlice = createSlice({
  name: "account",
  initialState,
  reducers: {
    /** Деавторизация */
    handleLogout(state) {
      state.user = initialState.user;
    },

    /** Параметры аккаунта пользователя
     * - GET - account/me/ */
    fetchAccountRequest(state) {
      state.user.error = null;
      state.user.isFetching = true;
    },
    fetchAccountSuccess(state, action) {
      state.user.username = action.payload.username;
      state.user.name = action.payload.name;
      state.user.firstName = action.payload.first_name;
      state.user.lastName = action.payload.last_name;
      state.user.name = action.payload.name;
      state.user.email = action.payload.email;
      state.user.error = null;
      state.user.isFetching = false;

      state.siteTerms.kbo.isAgreed = action.payload.agreed_kbo;
      state.siteTerms.eula.isAgreed = action.payload.agreed_eula;
      state.siteTerms.otherDocs.notResponded = action.payload.not_responded_other_docs;
    },
    fetchAccountFailure(state, action) {
      state.user.error = action.payload;
      state.user.isFetching = false;
      state.user.name = "";
      state.user.email = "";
    },

    /** Запрос соглашений */
    checkSiteTermsRequest(state) {
      state.siteTerms = {
        ...state.siteTerms,
        isFetching: true,
      };
    },
    checkSiteTermsSuccess({ siteTerms }, action: { payload: {
          kbo: KboOrEulaType;
          eula: KboOrEulaType;
          documents?: KboOrEulaType[];
        };
        type: any;
      }) {
      siteTerms.isFetching = false;
      siteTerms.kbo.data = action.payload.kbo;
      siteTerms.eula.data = action.payload.eula;
      siteTerms.otherDocs.data = action.payload.documents;
    },
    checkSiteTermsFailure({ siteTerms }, action) {
      siteTerms.isFetching = false;
      siteTerms.error = action.payload;
    },

    /** Запрос дополнительных документов */
    checkOtherDocsRequest({ siteTerms: { otherDocs } }) {
      otherDocs.isFetching = true;
    },
    checkOtherDocsSuccess({ siteTerms: { otherDocs } }, action: { payload: KboOrEulaType[] }) {
      otherDocs.isFetching = false;
      otherDocs.data = action.payload;
    },
    checkOtherDocsFailure({ siteTerms: { otherDocs } }) {
      otherDocs.isFetching = false;
    },

    /** Подтверждение кбо */
    agreedKboRequest({ siteTerms: { kbo } }) {
      kbo.isFetching = true;
    },
    agreedKboSuccess({ siteTerms: { kbo } }) {
      kbo.isAgreed = true;
      kbo.isFetching = false;
    },
    agreedKboFailure({ siteTerms: { kbo } }, action) {
      kbo.isFetching = false;
      kbo.error = action.payload;
    },

    /** Подтверждение eula */
    agreedEulaRequest({ siteTerms: { eula } }) {
      eula.isFetching = true;
    },
    agreedEulaSuccess({ siteTerms: { eula } }) {
      eula.isAgreed = true;
      eula.isFetching = true;
    },
    agreedEulaFailure({ siteTerms: { eula } }, action) {
      eula.isFetching = false;
      eula.error = action.payload;
    },

    /** Подтверждение документа */
    agreedOtherDocRequest({ siteTerms: { otherDocs } }) {
      otherDocs.isFetching = true;
    },
    agreedOtherDocSuccess({ siteTerms: { otherDocs } }, action: { payload: number; type: string }) {
      const filteredDocsIds: number[] = [...otherDocs.notResponded]?.filter((id) => id !== action.payload);
      const filteredDocs: KboOrEulaType[] = [...otherDocs.data]?.filter(({ id }) => id !== action.payload);

      otherDocs.notResponded = filteredDocsIds;
      otherDocs.data = filteredDocs;
      otherDocs.isFetching = false;
    },
    agreedOtherDocFailure({ siteTerms: { otherDocs } }, action) {
      otherDocs.isFetching = false;
      otherDocs.error = action.payload;
    },
  },
});

const { actions, reducer } = accountSlice;

export const {
  fetchAccountRequest,
  fetchAccountSuccess,
  fetchAccountFailure,

  checkSiteTermsRequest,
  checkSiteTermsSuccess,
  checkSiteTermsFailure,

  checkOtherDocsRequest,
  checkOtherDocsSuccess,
  checkOtherDocsFailure,

  agreedKboRequest,
  agreedKboSuccess,
  agreedKboFailure,

  agreedEulaRequest,
  agreedEulaSuccess,
  agreedEulaFailure,

  agreedOtherDocRequest,
  agreedOtherDocSuccess,
  agreedOtherDocFailure,

  handleLogout,
} = actions;

export default reducer;
