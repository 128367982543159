import { createSlice } from "@reduxjs/toolkit";
import {
  ModificationCreateType,
  ModificationType,
  PaginatedVendorCatalogType,
  VendorCatalogType,
  ProductType,
  PaginatedModificationsType,
} from "products/types";

type DefautDataStateType = {
  error: string | null;
  isFetching: boolean;
};

export interface IProducts extends DefautDataStateType {
  list: ProductType[];
  count: number;
  minPrice: number;
  maxPrice: number;
  selected: ProductType;
}

export interface IModifications extends DefautDataStateType {
  list: ModificationType[];
  count: number;
  selected: ModificationType;
}

export interface IVendorCatalogs extends DefautDataStateType {
  list: VendorCatalogType[];
  count: number;
}

export type InitialProductsStateType = {
  products: IProducts;
  modifications: IModifications;
  vendorCatalogs: IVendorCatalogs;
};

// TODO: Доработать логику
const initialState: InitialProductsStateType = {
  products: {
    count: null,
    list: null,
    minPrice: null,
    maxPrice: null,
    selected: null,
    error: null,
    isFetching: false,
  },
  modifications: {
    list: null,
    count: null,
    selected: null,
    error: null,
    isFetching: false,
  },
  vendorCatalogs: {
    list: null,
    count: null,
    error: null,
    isFetching: false,
  },
};

const productsSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    /** Получение списка продуктов
     * - GET /product/all/ */
    fetchProductsAllRequest(state, _action) {
      state.products.error = null;
      state.products.isFetching = true;
    },
    fetchProductsAllSuccess(state, action) {
      state.products.list = action.payload?.results;
      state.products.count = action.payload?.total;
      state.products.minPrice = action.payload?.min_catalog_price;
      state.products.maxPrice = action.payload?.max_catalog_price;
      state.products.error = null;
      state.products.isFetching = false;
    },
    fetchProductsAllFailure(state, action) {
      state.products.list = null;
      state.products.error = action.payload;
      state.products.isFetching = false;
    },

    /** Получение списка свойств товаров
     * - GET /product/properties/ */
    fetchProductPropertiesRequest(state, _action) {
      state.products.error = null;
      state.products.isFetching = true;
    },
    fetchProductPropertiesSuccess(state, action) {
      state.products.list = action.payload?.results;
      state.products.error = null;
      state.products.isFetching = false;
    },
    fetchProductPropertiesFailure(state, action) {
      state.products.list = null;
      state.products.error = action.payload;
      state.products.isFetching = false;
    },

    /** Создание продукта
     * - POST /product/create/ */
    createProductRequest(state, _action) {
      state.products.error = null;
      state.products.isFetching = true;
    },
    createProductSuccess(state, _action) {
      state.products.error = null;
      state.products.isFetching = false;
    },
    createProductFailure(state, action) {
      state.products.list = null;
      state.products.error = action.payload;
      state.products.isFetching = false;
    },

    /** Получение продукта
     * - GET /product/product/{product_id} */
    fetchProductRequest(state, _action) {
      state.products.selected = null;
      state.products.isFetching = true;
    },
    fetchProductSuccess(state, action) {
      state.products.selected = action.payload;
      state.products.error = null;
      state.products.isFetching = false;
    },
    fetchProductFailure(state, action) {
      state.products.error = action.payload;
      state.modifications.isFetching = false;
    },
    clearSelectedProduct(state, _action) {
      state.products.selected = null;
    },

    /** Удаление продукта
     * - DELETE /product/delete/ */
    deleteProductRequest(state, _action) {
      state.products.error = null;
      state.products.isFetching = true;
    },
    deleteProductSuccess(state, _action) {
      state.products.error = null;
      state.products.isFetching = false;
    },
    deleteProductFailure(state, action) {
      state.products.list = null;
      state.products.error = action.payload;
      state.products.isFetching = false;
    },

    /** Удаление всего списка продуктов с учетом фильтров
     * - DELETE /product/delete/ */
    deleteAllProductsRequest(state, _action) {
      state.products.error = null;
      state.products.isFetching = true;
    },
    deleteAllProductsSuccess(state, _action) {
      state.products.error = null;
      state.products.isFetching = false;
    },
    deleteAllProductsFailure(state, action) {
      state.products.list = null;
      state.products.error = action.payload;
      state.products.isFetching = false;
    },

    /** Обновление продукта
     * - PATCH /product/update/{product_id} */
    updateProductRequest(state, _action) {
      state.products.error = null;
      state.products.isFetching = true;
    },
    updateProductSuccess(
      state,
      action: {
        payload: ProductType;
        type: any
      }
    ) {
      const isBaseUpdated: boolean = action.payload.modifications?.[0]?.is_base; 

      state.products.selected = {
        ...state.products.selected,
        ...action.payload,
        modifications: state.products.selected?.modifications?.map((modification: ModificationType) => {
          if (modification.id !== action.payload.modifications?.[0]?.id) {
            const newModification: ModificationType = isBaseUpdated 
              ? { ...modification, is_base: false } as ModificationType 
              : modification;

            return newModification;
          } else {
            return action.payload.modifications?.[0];
          }
        }) || []
      };
      state.modifications.selected = action.payload.modifications?.[0];
      state.products.error = null;
      state.products.isFetching = false;
    },
    updateProductFailure(state, action) {
      state.products.error = action.payload;
      state.products.isFetching = false;
    },

    /** Список модификаций товаров
     * - GET /product/{product_id}/modifications/ */
    fetchProductModificationsRequest(state, _action) {
      state.products.error = null;
      state.products.isFetching = true;
    },
    fetchProductModificationsSuccess(state, action: { payload: PaginatedModificationsType; type: any }) {
      state.modifications.list = action.payload.results;
      state.modifications.count = action.payload.total;
      state.products.error = null;
      state.products.isFetching = false;
    },
    fetchProductModificationsFailure(state, action) {
      state.modifications.list = null;
      state.products.error = action.payload;
      state.products.isFetching = false;
    },
    clearFetchedModificationsList(state, _action) {
      state.products.list = null;
      state.products.isFetching = false;
    },

    /** Модификация товара
     * - GET /product/modifications/{modification_id} */
    fetchModificationRequest(state, _action) {
      state.modifications.selected = null;
      state.products.error = null;
      state.products.isFetching = true;
    },
    fetchModificationSuccess(state, action: { payload: ModificationType; type: any }) {
      state.products.selected = {
        ...state.products.selected,
        modifications: state.products.selected?.modifications?.map((modification: ModificationType) => {
          if (modification.id !== action.payload?.id) {
            return modification;
          } else {
            return action.payload;
          }
        }) || []
      };
      state.modifications.selected = action.payload;
      state.products.error = null;
      state.products.isFetching = false;
    },
    fetchModificationFailure(state, action) {
      state.products.list = null;
      state.products.error = action.payload;
      state.products.isFetching = false;
    },

    /** Листинг каталогов
     * - GET /product/vendor_catalogs/ */
    fetchVendorCatalogsRequest(state, _action) {
      state.vendorCatalogs.error = null;
      state.vendorCatalogs.isFetching = true;
    },
    fetchVendorCatalogsSuccess(state, action: { payload: PaginatedVendorCatalogType; type: any }) {
      state.vendorCatalogs.list = action.payload.results;
      state.vendorCatalogs.error = null;
      state.vendorCatalogs.isFetching = false;
    },
    fetchVendorCatalogsFailure(state, action) {
      state.vendorCatalogs.error = action.payload;
      state.vendorCatalogs.isFetching = false;
    },

    /** Создание модификации товара
     * - POST /product/modification/create */
    createModificationRequest(state, _action) {
      state.modifications.selected = null;
      state.modifications.isFetching = true;
    },
    createModificationSuccess(state, action: { payload: ModificationCreateType; type: any }) {
      state.products.selected.modifications = [...(state?.products?.selected?.modifications || []), action.payload];
      state.modifications.list = [...(state.modifications?.list || []), action.payload];
      state.modifications.error = null;
      state.modifications.isFetching = false;
    },
    createModificationFailure(state, action) {
      state.modifications.error = action.payload;
      state.modifications.isFetching = false;
    },

    /** Удаление модификации товара
     * - DELETE /product/modification/delete/ */
    deleteModificationRequest(state, _action) {
      state.modifications.selected = null;
      state.modifications.isFetching = true;
    },
    deleteModificationSuccess(state, action: { payload: number; type: any }) {
      state.products.selected = {
        ...state.products.selected,
        modifications: state.products.selected?.modifications?.filter(({ id }) => id !== action.payload),
      };
      state.modifications.error = null;
      state.modifications.isFetching = false;
    },
    deleteModificationFailure(state, action) {
      state.modifications.error = action.payload;
      state.modifications.isFetching = false;
    },

    /** Дублирование модификации товара
     * - POST /product/modifications/{modification_id}/duplicate/ */
    duplicateModificationRequest(state, _action) {
      state.modifications.selected = null;
      state.modifications.isFetching = true;
    },
    duplicateModificationSuccess(state, action: { payload: ModificationType }) {
      state.products.selected.modifications.push(action.payload);
      state.modifications.error = null;
      state.modifications.isFetching = false;
    },
    duplicateModificationFailure(state, action) {
      state.modifications.error = action.payload;
      state.modifications.isFetching = false;
    },
  },
});

const { actions, reducer } = productsSlice;

export const {
  fetchProductsAllRequest,
  fetchProductsAllSuccess,
  fetchProductsAllFailure,

  fetchProductPropertiesRequest,
  fetchProductPropertiesSuccess,
  fetchProductPropertiesFailure,

  createProductRequest,
  createProductSuccess,
  createProductFailure,

  fetchProductRequest,
  fetchProductSuccess,
  fetchProductFailure,
  clearSelectedProduct,

  deleteProductRequest,
  deleteProductSuccess,
  deleteProductFailure,

  deleteAllProductsRequest,
  deleteAllProductsSuccess,
  deleteAllProductsFailure,

  updateProductRequest,
  updateProductSuccess,
  updateProductFailure,

  fetchProductModificationsRequest,
  fetchProductModificationsSuccess,
  fetchProductModificationsFailure,
  clearFetchedModificationsList,

  fetchModificationRequest,
  fetchModificationSuccess,
  fetchModificationFailure,

  fetchVendorCatalogsRequest,
  fetchVendorCatalogsSuccess,
  fetchVendorCatalogsFailure,

  createModificationRequest,
  createModificationSuccess,
  createModificationFailure,

  deleteModificationRequest,
  deleteModificationSuccess,
  deleteModificationFailure,

  duplicateModificationRequest,
  duplicateModificationSuccess,
  duplicateModificationFailure,
} = actions;

export default reducer;
