import React, { JSX, FC } from "react";
import dayjs, { Dayjs } from "dayjs";
import { DatePicker, Tooltip } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { RangePickerProps } from "antd/es/date-picker";

interface IRangePickerProps {
  handleChange?: (dates: [Dayjs | null, Dayjs | null]) => void;
  placeholder?: [string, string];
  disabledDate?: RangePickerProps["disabledDate"];
  showSuffixIcon?: boolean
  suffixIcon?: JSX.Element;
  tooltipTitle?: string;
}

const RangePicker: FC<IRangePickerProps> = ({
  handleChange,
  disabledDate,
  suffixIcon,
  placeholder= ["С даты", "По дату"],
  showSuffixIcon = false,
  tooltipTitle = null
}): JSX.Element => {
  const icon: JSX.Element = suffixIcon ?? <ExclamationCircleOutlined className="text-black" />;

  return (
    <DatePicker.RangePicker
      size="large"
      placeholder={placeholder}
      defaultPickerValue={[dayjs().add(-1, "M"), dayjs()]}
      disabledDate={disabledDate}
      format="DD.MM.YYYY"
      onChange={handleChange}
      suffixIcon={showSuffixIcon ? (
        <Tooltip
          title={tooltipTitle}
          overlayStyle={{ whiteSpace: "normal", maxWidth: 300 }}
          getPopupContainer={(triggerNode) => triggerNode.parentElement}
        >
          {/* Обертка нужна для всплытия Tooltip, иначе он не появлялся */}
          <span
            className="inline-flex m-0 p-0 pointer-events-auto"
            style={{ margin: 0, padding: 0 }}
          >
            {icon}
          </span>
        </Tooltip>
      ) : null}
    />
  );
};

export default RangePicker;
