import { useState, useEffect } from "react";
import heic2any from "heic2any";

const useImageUrl = (fileName: string, fileUrl: string): string => {
  const isHeic: boolean = fileName.toLowerCase().endsWith(".heic");

  const [imageUrl, setImageUrl] = useState<string>("");

  useEffect(() => {
    const fetchImageUrl = async () => {
      try {
        let url: string;

        // Конвертация для формата HEIC
        if (isHeic) {
          const response: Response = await fetch(fileUrl);
          const blob: Blob = await response.blob();
          const convertedBlob: Blob = await heic2any({ blob, toType: "image/jpeg" }) as Blob;

          url = URL.createObjectURL(convertedBlob); // Создаем временный URL
        } else {
          url = fileUrl;
        }

        setImageUrl(url);
      } catch (error) {
        console.error("Error loading or converting image:", error);
      }
    };

    fetchImageUrl();
  }, [fileName, fileUrl]);

  return imageUrl;
};

export default useImageUrl;
