import { AxiosResponse } from "axios";
import { ThunkAction } from "redux-thunk";
import { AppStateType } from "../reducers/mainReducer";
import * as SuppliersAPI from "api/suppliers";
import {
  fetchSupplierSettingsRequest,
  fetchSupplierSettingsSuccess,
  fetchSupplierSettingsFailure,
} from "../slices/suppliersSlice";
import { SupplierSettingsType } from "suppliers/types";

/** Настройки аккаунта поставщика */
export const fetchSupplierSettings = (): ThunkAction<
  Promise<SupplierSettingsType>,
  AppStateType,
  undefined,
  any
> => async (dispatch) => {
  dispatch(fetchSupplierSettingsRequest());
  try {
    const response: AxiosResponse<SupplierSettingsType> = await SuppliersAPI.getSupplierSettings();

    dispatch(fetchSupplierSettingsSuccess(response?.data));

    return Promise.resolve(response?.data);
  } catch (error: any) {
    dispatch(fetchSupplierSettingsFailure(error?.message));

    return Promise.reject(error);
  }
};