import React, { JSX, FC, useEffect, useState, useMemo, useContext, useCallback } from "react";
import { NavigateFunction, useNavigate, useParams } from "react-router";
import useFormInstance from "antd/es/form/hooks/useFormInstance";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "redux/store/store";
import { createProduct, updateProduct } from "redux/thunks/products.thunk";
import * as CategoriesAPI from "api/categories";

import { Button, Cascader, Checkbox, Form, Input, notification, Select, Tooltip } from "antd";
import PhotoBlock from "components/Products/PhotoBlock";
import GhostButton from "components/ui/GhostButton/GhostButton";
import { ReactComponent as TrashIcon } from "assets/icons/trash-icon.svg";

import "./style.scss";

import { FormInstance, useWatch } from "antd/es/form/Form";
import { CategoryTreeType } from "categories/types";
import { DraggerFileType } from "components/ui/DragAndDrop/DragAndDrop";
import { AppStateType } from "redux/reducers/mainReducer";
import {
  IFormFields,
  ProductContext,
  ProductFieldsFormType,
  ProductMasterFieldNameType,
  ProductMasterFormType,
  ProductMasterPropertyType,
  ProductStatus
} from "pages/ImportMasterPage";
import {
  CurrencyCharCodeType,
  ModificationType,
  ProductCreateRequestType,
  ProductType,
  ProductUpdateRequestType
} from "products/types";
import { CheckboxChangeEventTarget } from "antd/es/checkbox/Checkbox";
import { APP_PATHES } from "constants/appPathes";
import {
  buildCategoryTreeFromChain,
  OptionType,
  transformDataToCategory,
  updateCategory
} from "utils/formatCategories";
import PriceInput, { PriceValueType } from "./PriceInput";
import { DefaultOptionType } from "antd/es/select";
import { handleError } from "utils/handleError";

type UpdateTargetType = EventTarget & (HTMLInputElement | HTMLElement)
  | CheckboxChangeEventTarget
  | React.MouseEvent<HTMLElement, MouseEvent>
  | EventTarget
  | string;
type FieldHandlerType = (value?: string | number | PriceValueType) => void;
type FieldMappingsType = Record<ProductMasterFieldNameType, FieldHandlerType | null>;
type updateCharcPropsType = { target: UpdateTargetType }

interface IImportProductForm {
}

const ImportProductForm: FC<IImportProductForm> = (): JSX.Element => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate: NavigateFunction = useNavigate();
  const { dataId, modId } = useParams<{ mode: string, dataId?: string, modId?: string }>();

  const form = useFormInstance<IFormFields>();
  const values = useWatch<FormInstance<IFormFields>>([], form);

  const { setIsFocused } = useContext(ProductContext);

  const { settings: { ndsRate } } = useSelector((state: AppStateType) => state.suppliersSlice);
  const { list: categoriesTreeList } = useSelector((state: AppStateType) => state.categoriesSlice?.categoriesTree);
  const { selected: selectedProduct } = useSelector((state: AppStateType) => state.productsSlice?.products);
  const { selected: selectedModification } = useSelector((state: AppStateType) => state.productsSlice?.modifications);

  const ndsOptions: DefaultOptionType[] = [
    { label: "Без НДС", value: "no_nds" },
    { label: "НДС 0%", value: "0" },
    { label: "НДС 5%", value: "5" },
    { label: "НДС 7%", value: "7" },
    { label: "НДС 10%", value: "10" },
    { label: "НДС 20%", value: "20" },
  ];

  const [isResetFileList, setIsResetFileList] = useState<boolean>(false);
  const [categoryOptions, setCategoryOptions] = useState<OptionType[]>(
    transformDataToCategory(categoriesTreeList || [])
  );
  const [selectedCategoryOption, setSelectedCategoryOption] = useState<OptionType[]>([]);

  const isRequiredFields: boolean = useMemo(() => {
    return !!values?.price?.price
      && !!values?.price?.currency
      && !!values?.name
      && !!values?.productName
      && !!values?.productCode
      && !!values?.categoryBK;
  }, [
    values?.price?.price,
    values?.price?.currency,
    values?.name,
    values?.productName,
    values?.productCode,
    values?.categoryBK
  ]);
  const hasWarehouse: boolean = useMemo(() => !!values?.warehouse, [values?.warehouse]);
  const isEditMode: boolean = useMemo(() => !!dataId, [dataId]);
  const isModificationSelected: boolean = useMemo(() => !!modId, [modId]);
  const isFieldsDisabled: boolean = useMemo(() => (
    !hasWarehouse || !!dataId && !isModificationSelected
  ), [hasWarehouse, dataId, isModificationSelected]);
  const isAddDisabled = useMemo(() => {
    const properties: ProductMasterPropertyType[] = form.getFieldValue("properties") || [];
    if (!properties.length) return false;

    const lastProperty: ProductMasterPropertyType = properties?.at(-1);
    return !lastProperty?.propertyName || !lastProperty?.propertyValue;
  }, [form.getFieldValue("properties")]);

  const productTooltip: string = useMemo(() => {
    const messages: { condition: boolean; message: string; }[] = [
      { condition: !!dataId && !isModificationSelected, message: "Выберите модификацию из списка" },
    ];

    return messages.find(({ condition }) => condition)?.message || "";
  }, [hasWarehouse, isModificationSelected]);

  const baseModification: ModificationType = useMemo(() => (
    selectedProduct?.modifications?.find(({ is_base }) => !!is_base)
  ), [selectedProduct?.modifications]);

  const isBase = useMemo(() => (
    baseModification?.id?.toString() === modId
  ), [modId, baseModification?.id]);

  const isShowInCatalogDisabled: boolean = useMemo(() => (
    isFieldsDisabled
    || !isEditMode
    || (!isBase && !baseModification?.show_in_catalog)
    || !isRequiredFields
  ), [
    baseModification?.show_in_catalog,
    isBase,
    isEditMode,
    isFieldsDisabled,
    isRequiredFields
  ]);

  useEffect(() => {
    return () => { // Очистка картинок
      setIsResetFileList(false);
      setIsResetFileList(true);
    };
  }, []);

  useEffect(() => {
    if (!isRequiredFields && values?.showInCatalog) {
      form.setFieldsValue({ showInCatalog: false });

      updateCharc({ target: { type: "checkbox", checked: false } });
    }
  }, [isRequiredFields, values?.showInCatalog]);

  // Заполнение формы товара
  useEffect(() => {
    selectedProduct && !!Number(modId) && fillForm(selectedProduct, Number(modId));
  }, [selectedProduct]);

  // Подстановка данных для Cascader
  useEffect(() => {
    if (categoriesTreeList?.length) {
      setCategoryOptions([
        ...transformDataToCategory(categoriesTreeList as CategoryTreeType[] || [])
          .filter((opt) => opt?.id !== selectedCategoryOption?.at(0)?.id),
        ...selectedCategoryOption
      ]);
    }
  }, [categoriesTreeList]);

  // Подстановка выбранной категории
  useEffect(() => {
    if (selectedCategoryOption) {
      setCategoryOptions((prevOptions) => {
        return [
          ...prevOptions.filter((opt) => opt.id !== selectedCategoryOption?.at(0)?.id),
          ...selectedCategoryOption
        ];
      });
    }
  }, [selectedCategoryOption]);

  // При открытии/смене модификации
  useEffect(() => {
    setIsResetFileList(false);
    if (selectedModification) {
      setIsResetFileList(true);

      const newFormValues: ProductFieldsFormType = {
        ...fillFormModification(selectedModification)
      };

      form.setFieldsValue(newFormValues);
    }
  }, [selectedModification]);

  const handleFocus = useCallback((): void => setIsFocused?.(true), [setIsFocused]);

  const propertiesValidator = async (_, properties): Promise<void> => {
    if (
      !properties
      || properties.some(({ propertyName, propertyValue }) => !propertyName || !propertyValue)
    ) {
      return Promise.reject(
        new Error("Все свойства должны быть заполнены")
      );
    }
    return Promise.resolve();
  };

  const checkPrice = (_: any, value: { price: number, currency: string }): Promise<void> => {
    if (!value?.currency) {
      return Promise.reject(new Error("Заполните все значения"));
    }
    return Promise.resolve();
  };

  const transformProperty = (obj: Record<string, string>): ProductMasterPropertyType[] => {
    return Object.entries(obj).map(([key, value]) => ({
      propertyName: key,
      propertyValue: value,
    }));
  };

  // Fill form
  const fillForm = (product: ProductType, modificationId: number): void => {
    if (!product) return;

    const { category_bk_id, code, name, modifications, rate_nds } = product;
    const modification: ModificationType = modifications?.find(({ id }: { id: number }) => id === modificationId);

    // Получаем дочернюю категорию
    category_bk_id && addCategoryChainToOptions(category_bk_id);

    const currentValues: IFormFields = form.getFieldsValue();
    const newFormValues: ProductFieldsFormType = {
      productName: name,
      productCode: code,
      categoryBK: [category_bk_id],
      ...(rate_nds === null || isNaN(rate_nds) ? { nds: "no_nds" } : { nds: `${rate_nds}` }),
      ...fillFormModification(modification)
    };

    // Перезаписываем только те поля, которые имеют новые значения
    const fieldsToSet = Object.keys(newFormValues).reduce((acc, key) => {
      switch (key) {
        case "categoryBK":
          if (newFormValues[key]?.at(-1) !== currentValues[key]?.at(-1)) {
            acc[key] = newFormValues[key];
          }
          break;

        default:
          if (newFormValues[key] !== currentValues[key]) {
            acc[key] = newFormValues[key];
          }
          break;
      }
      return acc;
    }, {});

    form.setFieldsValue(fieldsToSet);
    form.validateFields(["categoryBK"]);
  };

  const fillFormModification = (modification?: ModificationType): ProductMasterFormType => {
    if (!modification) return null;

    const canAddProperties: boolean = !!modification?.property;
    const propertiesArray: any[] = canAddProperties ? transformProperty(modification.property) : [];
    const { code, currency, currency_price, is_base, min_count, name, show_in_catalog } = modification;

    return {
      name,
      code,
      minCount: min_count?.toString() || "",
      showInCatalog: show_in_catalog,
      description: modification?.description || "",
      properties: canAddProperties ? propertiesArray : [],
      price: {
        ...(currency_price && { price: currency_price }),
        ...(currency && { currency }),
      },
      isBase: !!is_base,
    } as ProductMasterFormType;
  };

  // Categories
  const fetchCategory = async (id: number): Promise<CategoryTreeType> => {
    const findedCategory: CategoryTreeType = (
      await CategoriesAPI.getCategoriesTPAllTree({ category_ids: [id] })
    ).data.at(0);

    return findedCategory;
  };

  const loadCategoryHandler = async (selectedOptions: OptionType[]): Promise<void> => {
    const targetOption: OptionType = selectedOptions?.at(-1);

    if (targetOption.children) return;

    targetOption.loading = true;

    try {
      const category: CategoryTreeType = await fetchCategory(Number(targetOption.value));

      const transformedChildren: OptionType[] = transformDataToCategory([category]);

      targetOption.loading = false;

      setCategoryOptions((prevOptions) => {
        if (!updateCategory(prevOptions, targetOption.value, transformedChildren)) {
          return [...prevOptions, transformedChildren] as OptionType[];
        }
        return updateCategory([...prevOptions], targetOption.value, transformedChildren);
      });
    } catch (error) {
      targetOption.loading = false;

      handleError("Ошибка при загрузке категорий", error);
    }
  };

  const getProductCategoryChain = async (
    childId: number,
    fetchCategory: (id: number) => Promise<CategoryTreeType>
  ): Promise<OptionType[]> => {
    const chain: OptionType[] = [];
    let currentId: number | undefined = childId;

    while (currentId) {
      const category: CategoryTreeType = await fetchCategory(currentId);
      if (!category) break;
      chain.unshift(transformDataToCategory([category])?.[0]);
      currentId = category.parent;
    }

    return chain;
  };

  const addCategoryChainToOptions = async (childId: number): Promise<OptionType[]> => {
    const chain: OptionType[] = await getProductCategoryChain(childId, fetchCategory);
    if (!chain.length) return null;

    form.setFieldValue("categoryBK", chain.map(({ id }) => id));

    const newOption: OptionType[] = buildCategoryTreeFromChain(chain);

    setSelectedCategoryOption(newOption);

    return newOption;
  };

  // Images
  const addMediaHandler = (file: DraggerFileType): DraggerFileType => {
    const updateRequestBody: ProductUpdateRequestType = {
      modification: {
        id: Number(modId),
        preload_files: [file.responseId],
      }
    };

    updateProductHandler(updateRequestBody);

    return file;
  };

  const deleteMediaHandler = (fileList: DraggerFileType[]): DraggerFileType[] => {
    updateProductHandler({
      modification: {
        id: Number(modId),
        loaded_files: fileList?.map(({ uid }) => uid)
      }
    });

    return fileList;
  };

  // Product
  const createProductHandler = (): void => {
    const { productName, productCode, categoryBK, warehouse } = values;
    const canCreate: boolean = !!productName && !!productCode && !!categoryBK?.at(-1);

    if (canCreate) {
      const createRequestBody: ProductCreateRequestType = {
        name: productName,
        code: productCode,
        category: categoryBK?.at(-1),
        status: ProductStatus.DRAFT,
        warehouse_id: warehouse,
        rate_nds: ndsRate?.toString(),
      };

      dispatch(createProduct(createRequestBody))
        .then((response: ProductType) => {
          notification.info({
            message: "Черновик создан"
          });
          navigate(`${APP_PATHES.IMPORT_MASTER_MANUAL}/${response.id}`);
        })
        .catch((error) => {
          let message: string = "Ошибка при создании товара";
          if (error?.response?.data?.message?.includes("артикул")) {
            message = "Черновик не сохранен";

            form.setFields([{
              name: "productCode",
              value: "",
              errors: [error?.response?.data?.message]
            }]);
          }

          handleError(message, error);
        });
    }
  };

  const updateProductHandler = (body: ProductUpdateRequestType): void => {
    dispatch(updateProduct(Number(dataId), body))
      .catch((error) => {
        let message: string = "Ошибка при обновлении товара";
        if (error?.response?.data?.message?.includes("артикул")) {
          message = "Черновик не сохранен";

          form.setFields([{
            name: "productCode",
            value: "",
            errors: [error?.response?.data?.message]
          }]);
        }
        handleError("Ошибка при обновлении товара", error);
      })
      .finally(() => setIsFocused(false));
  };

  const updateCharc = ({ target }: updateCharcPropsType): void => {
    let fieldName: ProductMasterFieldNameType;
    let fieldValue: string;
    const currentFormValues: IFormFields = form.getFieldsValue();

    if (typeof target === "string" && target !== "properties") {
      fieldName = target as ProductMasterFieldNameType;
      fieldValue = currentFormValues?.[target];
    } else {
      const { id, value, type, checked } = target as HTMLInputElement | CheckboxChangeEventTarget;

      if (id?.includes("properties") || target === "properties") {
        fieldName = "properties";
        fieldValue = type === "checkbox" ? `${checked}` : (value) || {};
      } else {
        fieldName = id as ProductMasterFieldNameType;
        fieldValue = type === "checkbox" ? `${checked}` : (value);
      }
    }

    const hasFieldValue: boolean = !!fieldValue || fieldValue === "";
    const hasValue: boolean = !!currentFormValues?.[fieldName] || currentFormValues?.[fieldName] === "";

    if (!hasFieldValue && !hasValue) return;

    const requestBody: ProductUpdateRequestType = {
      modification: {
        id: Number(modId),
        warehouse_id: Number(currentFormValues?.warehouse)
      }
    };

    const updateCurrencyFields = (price: number, currency: CurrencyCharCodeType): void => {
      if (!!currency && (!isNaN(price) || price === undefined)) {
        Object.assign(requestBody.modification, {
          currency_price: price || 0,
          ...(!!price && { currency }),
        });

        if (!requestBody.modification.currency_price) {
          requestBody.status = ProductStatus.DRAFT;
        }
      }
    };

    const updateProperties = (): void => {
      const properties: ProductMasterPropertyType[] = form.getFieldValue("properties");
      const canUpdate: boolean = !properties
        ?.some(({ propertyName, propertyValue }) => !propertyName || !propertyValue);

      if (canUpdate) {
        requestBody.modification.property = properties
          ?.filter((property: ProductMasterPropertyType) => !!property?.propertyName && !!property?.propertyValue)
          ?.reduce((acc, { propertyName, propertyValue }) => {
            acc[propertyName] = propertyValue;
            return acc;
          }, {} as Record<string, string>);
      }
    };

    const fieldMappings: FieldMappingsType = {
      productName: (value: string) => (value ? requestBody.name = value : null),
      productCode: (value: string) => (value ? requestBody.code = value : null),
      categoryBK: () => (requestBody.category = currentFormValues?.categoryBK?.at(-1)),
      properties: () => updateProperties(),
      name: (value: string) => (value ? requestBody.modification.name = value : null),
      code: (value: string) => (requestBody.modification.code = value),
      minCount: (value: string) => (requestBody.modification.min_count = Number(value)),
      description: (value: string) => (requestBody.modification.description = value),
      price: (value: PriceValueType) => updateCurrencyFields(value?.price, value?.currency),
      nds: (value: string) => (requestBody.rate_nds = value),
      showInCatalog: () => (requestBody.modification.show_in_catalog = (target as CheckboxChangeEventTarget).checked),
      isBase: () => (requestBody.modification.is_base = (target as CheckboxChangeEventTarget).checked),
      photo: null,
    };

    fieldMappings?.[fieldName]?.(fieldValue);

    const isProductHasChanges: boolean = Object.entries(requestBody)
      .some(([key, value]) => key !== "modification"
        && value !== selectedProduct?.[key]
      );
    const isModificationHasChanges: boolean = Object.entries(requestBody.modification)
      .some(([key, value]) => key !== "id"
        && (key !== "warehouse_id" || value !== selectedModification?.warehouse_id)
        && value !== selectedModification?.[key]
      );
    const hasChanges: boolean = isProductHasChanges || isModificationHasChanges;

    if (!hasChanges) {
      setIsFocused(false);
      return;
    };

    (!!dataId || !!dataId && !!modId) ? updateProductHandler(requestBody) : createProductHandler();
  };

  return (
    <div className="ManualPropductForm_wrapper">
      <div className="importProductForm_wrapper">
        <Tooltip title={productTooltip}>
          <Form.Item
            key="productNameField"
            name="productName"
            label="Наименование продукта"
            rules={[
              {
                required: true,
                message: "Это поле обязательно для заполнения",
              },
            ]}
            className="mb-4"
          >
            <Input
              onFocus={handleFocus}
              onBlur={updateCharc}
              size="large"
              placeholder="Введите наименование продукта"
              disabled={isFieldsDisabled}
            />
          </Form.Item>
        </Tooltip>
        <Tooltip title={productTooltip}>
          <Form.Item
            key="productCodeField"
            name="productCode"
            label="Артикул продукта"
            rules={[
              {
                required: true,
                message: "Это поле обязательно для заполнения",
              },
            ]}
            className="mb-4"
          >
            <Input
              onFocus={handleFocus}
              onBlur={updateCharc}
              size="large"
              placeholder="Введите артикул продукта"
              disabled={isFieldsDisabled}
            />
          </Form.Item>
        </Tooltip>
      </div>
      <div className="importModificationForm_wrapper">
        <Tooltip title={productTooltip}>
          <Form.Item
            key="categoryBkField"
            name="categoryBK"
            label="Категория БК"
            rules={[
              {
                required: true,
                message: "Это поле обязательно для заполнения",
              },
            ]}
            className="mb-4"
          >
            <Cascader
              onFocus={handleFocus}
              onBlur={updateCharc}
              size="large"
              options={categoryOptions}
              loadData={loadCategoryHandler}
              placeholder="Найти категорию БК"
              notFoundContent="Категория БК не найдена"
              displayRender={(labels: string[]) => labels?.at(-1)}
              showSearch
              disabled={isFieldsDisabled}
              optionRender={({ label }) => {
                const maxLength: number = 25;
                return (
                  <Tooltip title={label}>
                    <span>
                      {(label as string)?.slice(0, maxLength)}
                      {label?.toString()?.length > maxLength && "..."}
                    </span>
                  </Tooltip>
                );
              }}
            />
          </Form.Item>
        </Tooltip>
        <Form.Item
          key="nameField"
          name="name"
          label="Наименование модификации"
          rules={[
            {
              required: true,
              message: "Это поле обязательно для заполнения",
            },
          ]}
          className="mb-4"
        >
          <Input
            onFocus={handleFocus}
            onBlur={updateCharc}
            size="large"
            placeholder="Введите наименование модификации"
            disabled={isFieldsDisabled || !isEditMode}
          />
        </Form.Item>
        <Form.Item
          key="codeField"
          name="code"
          label="Артикул модификации"
          className="mb-4"
        >
          <Input
            onFocus={handleFocus}
            onBlur={updateCharc}
            size="large"
            placeholder="Введите артикул модификации"
            disabled={isFieldsDisabled || !isEditMode}
          />
        </Form.Item>
        <Form.Item
          key="minCount"
          name="minCount"
          label="Минимальное кол-во для заказа"
          className="mb-4"
        >
          <Input
            onFocus={handleFocus}
            onBlur={updateCharc}
            size="large"
            placeholder="Введите число"
            disabled={isFieldsDisabled || !isEditMode}
          />
        </Form.Item>
        <Form.Item
          key="descriptionField"
          name="description"
          label="Описание модификации"
          className="mb-4"
        >
          <Input
            onFocus={handleFocus}
            onBlur={updateCharc}
            size="large"
            placeholder="Введите описание модификации"
            disabled={isFieldsDisabled || !isEditMode}
          />
        </Form.Item>
        <Form.Item
          required
          name="price"
          label="Цена"
          className="mb-4"
          rules={[{ validator: checkPrice }]}
        >
          <PriceInput
            onFocus={handleFocus}
            onChange={(_value) => updateCharc({ target: "price" })}
            isDisabled={isFieldsDisabled || !isEditMode}
          />
        </Form.Item>
        <Form.Item
          name="nds"
          label="НДС"
          className="mb-4 w-fit"
        >
          <Select
            size="large"
            placeholder="Выберите НДС"
            style={{ minWidth: "140px" }}
            onChange={() => updateCharc({ target: "nds" })}
            options={ndsOptions}
            disabled={isFieldsDisabled || !isEditMode}
          />
        </Form.Item>

        <div className="properties_wrapper mb-4">
          <p>Свойства</p>
          <div className="flex flex-col gap-2">
            <Form.List
              name="properties"
              rules={[{ validator: propertiesValidator }]}
            >
              {(fields, { add, remove }) => (
                <div className="properties_wrapper flex flex-col">
                  {fields.map(({ key, name }) => (
                    <div className="property-row flex gap-2 mb-4" key={key}>
                      <Form.Item
                        name={[name, "propertyName"]}
                        rules={[{ required: true, message: "Введите название свойства" }]}
                        style={{ flex: 1, }}
                        className="m-0"
                      >
                        <Input
                          onFocus={handleFocus}
                          onBlur={updateCharc}
                          size="large"
                          placeholder="Введите название свойства"
                          disabled={isFieldsDisabled || !isEditMode}
                        />
                      </Form.Item>
                      <Form.Item
                        name={[name, "propertyValue"]}
                        rules={[{ required: true, message: "Введите значение свойства" }]}
                        style={{ flex: 1 }}
                        className="m-0"
                      >
                        <Input
                          onFocus={handleFocus}
                          onBlur={updateCharc}
                          size="large"
                          placeholder="Введите значение свойства"
                          disabled={isFieldsDisabled || !isEditMode}
                          showCount
                          maxLength={255}
                        />
                      </Form.Item>
                      <Button
                        type="default"
                        size="large"
                        className="items-center text-indigo-600"
                        onClick={() => {
                          remove(name);
                          updateCharc({ target: "properties" });
                        }}
                        disabled={isFieldsDisabled || !isEditMode}
                      >
                        <TrashIcon />
                      </Button>
                    </div>
                  ))}
                  <div className="flex align-center">
                    <GhostButton
                      size="large"
                      onClickHandler={() => setTimeout(() => {
                        add({ propertyName: null, propertyValue: null });
                      }, 50)}
                      text="+ Добавить свойство"
                      isDisabled={isFieldsDisabled || !isEditMode || isAddDisabled}
                    >
                    </GhostButton>
                  </div>
                </div>
              )}
            </Form.List>
          </div>
        </div>
        <PhotoBlock
          modification={selectedModification}
          addMedia={addMediaHandler}
          deleteMedia={deleteMediaHandler}
          isDisabledFields={isFieldsDisabled || !isEditMode}
          resetFileList={isResetFileList}
        />
        <Form.Item
          key="showInCatalogField"
          name="showInCatalog"
          valuePropName="checked"
          className="ml-0 mb-0"
        >
          <Checkbox
            onChange={updateCharc}
            disabled={isShowInCatalogDisabled}
          >
            Показать в каталоге
          </Checkbox>
        </Form.Item>
        <Form.Item
          key="isBaseField"
          name="isBase"
          valuePropName="checked"
          className="ml-0 mb-0"
        >
          <Checkbox
            onChange={updateCharc}
            disabled={isFieldsDisabled || !isEditMode || selectedModification?.is_base}
          >
            Базовая модификация
          </Checkbox>
        </Form.Item>
      </div>
    </div>
  );
};

export default ImportProductForm;
