import React, { FC, useEffect, useMemo } from "react";
import { NavigateFunction, useNavigate, useParams } from "react-router";
import useFormInstance from "antd/es/form/hooks/useFormInstance";
import { handleError } from "utils/handleError";

import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "redux/store/store";
import { fetchSupplierSettings } from "redux/thunks/suppliers.thunk";
import { clearProduct, fetchModification, fetchProduct } from "redux/thunks/products.thunk";
import { fetchCurrencies } from "redux/thunks/currencies.thunk";
import { fetchCategoriesTPAllTree } from "redux/thunks/categories.thunk";
import { AppStateType } from "redux/reducers/mainReducer";

import PanelBlock from "components/ui/Panel/PanelBlock";
import PanelLayout, { IPanelLayoutProps } from "components/ui/Panel/PanelLayout";
import ImportProductForm from "components/ImportMaster/ImportManual/ImportProductForm";
import { ImportModeType } from "../ImportModePanel";

import ProductModificationsWrapper from "components/Products/ProductModificationsWrapper";
import { IFormFields, ProductMasterFieldNameType } from "pages/ImportMasterPage";

import { APP_PATHES } from "constants/appPathes";

interface IImportManualProps {
  initialSettings?: any;
}

const ImportManual: FC<IImportManualProps> = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate: NavigateFunction = useNavigate();
  const form = useFormInstance<IFormFields>();

  const { dataId, modId } = useParams<{ mode: ImportModeType, dataId?: string, modId?: string }>();

  const PANEL_LAYOUT_PROPS: IPanelLayoutProps = useMemo(() => ({
    title: { content: dataId ? "Редактирование товара" : "Создание товара", size: "large" },
    className: "flex flex-col md:flex-row gap-8"
  }), [dataId]);

  const {
    isFetching,
    selected
  } = useSelector((state: AppStateType) => state.productsSlice.products);

  useEffect(() => {
    dispatch(fetchCurrencies({}));
    dispatch(fetchCategoriesTPAllTree([]));
    dispatch(fetchSupplierSettings());    
  }, []);

  useEffect(() => {
    // Очистка полей при получении новой информации о продукте
    const resetFields: ProductMasterFieldNameType[] = [
      "productName", "productCode", "categoryBK", "name", "code", "minCount",
      "description", "price", "properties", "photo", "showInCatalog", "isBase", "nds"
    ];

    form.resetFields(resetFields);
    if (dataId) {
      dispatch(fetchProduct(Number(dataId)))
        .then(({ modifications }) => {
          const { id: baseModId, warehouse_id } = modifications?.find(({ is_base }) => !!is_base) || {};

          warehouse_id && form.setFieldValue("warehouse", warehouse_id);

          navigate(`${APP_PATHES.IMPORT_MASTER_MANUAL}/${dataId}/${modId || baseModId}`);
        })
        .catch((error) => handleError("Ошибка при получении товара", error));
    } else {
      dispatch(clearProduct());
    }
  }, [dataId]);

  useEffect(() => {
    // Очистка полей при получении новой модификации
    form.resetFields([
      "name", "code", "minCount", "description", "price", "properties", "photo", "showInCatalog", "isBase",
    ]);

    if (modId) {
      dispatch(fetchModification(Number(modId)))
        .catch((error) => {
          handleError("Ошибка при получении модификации", error);
        });
    }
  }, [modId]);

  return (
    <PanelLayout {...PANEL_LAYOUT_PROPS}>
      <PanelBlock
        title={{
          content: (
            <div className="flex justify-between">
              <p>Варианты товара</p>
              <p>{selected?.modifications?.length || 0}/30</p>
            </div>
          )
        }}
        wrapper={{ className: "flex basis-1/4" }}
      >
        <ProductModificationsWrapper isLoading={isFetching} />
      </PanelBlock>
      <PanelBlock wrapper={{ className: "flex basis-3/4" }}>
        <ImportProductForm />
      </PanelBlock>
    </PanelLayout>
  );
};

export default ImportManual;
