import React, { JSX } from "react";
import dayjs from "dayjs";
import Column from "antd/es/table/Column";
import { SorterResult } from "antd/es/table/interface";

export const renderUpdatedAtColumn = (
  renderColumnTitle: (title: string) => JSX.Element,
  sorter: SorterResult<any>,
): JSX.Element => {
  return (
    <Column
      key="Date changes"
      title={renderColumnTitle("Дата обновления")}
      dataIndex="updated_at"
      width={150}
      sorter={{ multiple: 3 }}
      sortOrder={sorter?.field === "updated_at" ? sorter?.order : undefined}
      sortDirections={["ascend", "descend"]}
      showSorterTooltip={false}
      render={(value: string) => <>{dayjs(value).format("DD.MM.YYYY HH:mm")}</>}
    />
  );
};
