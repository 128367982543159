import React, { JSX } from "react";
import { SorterResult } from "antd/es/table/interface";

import Column from "antd/es/table/Column";
import TextWithTooltip from "components/ui/TextWithTooltip/TextWithTooltip";

export const renderCategoryColumn = (
  renderColumnTitle: (title: string) => JSX.Element,
  sorter: SorterResult<any>,
): JSX.Element => {
  return (
    <Column
      key="Category"
      title={renderColumnTitle("Категория")}
      dataIndex="category_supplier"
      width={200}
      onCell={() => ({
        style: {
          whiteSpace: "normal",
          WordWrap: "break-word",
        }
      })}
      sorter={{ multiple: 3 }}
      sortOrder={sorter?.field === "category_supplier" ? sorter?.order : undefined}
      showSorterTooltip={false}
      sortDirections={["ascend", "descend"]}
      render={(value: string) => <TextWithTooltip text={value ?? "-"} className="secondary-color" />}
    />
  );
};
