import React, { JSX } from "react";
import dayjs from "dayjs";
import Column from "antd/es/table/Column";
import { ImportTaskType } from "importTask/types";
import { EntityType } from "../CatalogTable";
import { SorterResult } from "antd/es/table/interface";

export const renderDateTimeColumn = (
  renderColumnTitle: (title: string) => JSX.Element,
  sorter: SorterResult<any>,
  onClickHandler?: (...args: any[]) => void,
): JSX.Element => {
  return (
    <Column
      key="Date_time"
      title={renderColumnTitle("Дата и время")}
      dataIndex="created_at"
      width={150}
      sorter={{ multiple: 3 }}
      sortOrder={sorter?.field === "created_at" ? sorter?.order : undefined}
      showSorterTooltip={false}
      sortDirections={["ascend", "descend"]}
      render={(value: string, entity: EntityType) => {
        const { id, status, source, is_periodic } = entity as ImportTaskType || {};

        const isNavigateToEdit: boolean = "is_periodic" in entity && !is_periodic && status === "Черновик";

        return (
          <div
            className={isNavigateToEdit && !!onClickHandler ? "cursor-pointer" : ""}
            onClick={() => isNavigateToEdit && onClickHandler?.(id, source)}
          >
            {dayjs(value).format("DD.MM.YYYY HH:mm")}
          </div>
        );
      }}
    />
  );
};
