const CookieConfig = {
  DefaultDomain: ".smbconnect.ru",
  DefaultLocalDomain: "localhost",
  DefaultPath: "/",
} as const;

interface CookieOptions {
  name: string;
  value: string;
  days?: number;
}

export const set = ({ name, value, days = 30 }: CookieOptions): void => {
  const date: Date = new Date();
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);

  const isLocalHost: boolean =
    document.location.hostname === CookieConfig.DefaultLocalDomain ||
    document.location.search.includes(CookieConfig.DefaultLocalDomain);

  const cookie: string = `${name}=${encodeURIComponent(value)}`;
  const expires: string = `expires=${date.toUTCString()}`;
  const path: string = `path=${CookieConfig.DefaultPath}`;
  const domain: string = isLocalHost
    ? `domain=${CookieConfig.DefaultLocalDomain}`
    : `domain=${CookieConfig.DefaultDomain}`;
  const cookieParts: string[] = [cookie, expires, path, domain].filter(Boolean);
  const newCookie: string = cookieParts.join("; ");

  document.cookie = newCookie;
};

export const get = (name: string): string | undefined => {
  if (typeof document === "undefined") {
    return undefined;
  }

  const matches: RegExpMatchArray = document.cookie.match(
    new RegExp("(?:^|; )" + name.replace(/([.$*+?{}()[\]^])/g, "\\$1") + "=([^;]*)"),
  );

  const response: string = matches ? decodeURIComponent(matches[1]) : undefined;

  return response;
};

export const remove = (cookieName: string): void => {
  const isLocalHost: boolean =
    document.location.hostname === CookieConfig.DefaultLocalDomain ||
    document.location.search.includes(CookieConfig.DefaultLocalDomain);

  const cookie: string = `${cookieName}=`;
  const expires: string = "expires=Thu, 01 Jan 1970 00:00:00 UTC";
  const path: string = `path=${CookieConfig.DefaultPath}`;
  const domain: string = isLocalHost
    ? `domain=${CookieConfig.DefaultLocalDomain}`
    : `domain=${CookieConfig.DefaultDomain}`;
  const cookieParts: string[] = [cookie, expires, path, domain].filter(Boolean);
  const newCookie: string = cookieParts.join("; ");

  document.cookie = newCookie;
};
