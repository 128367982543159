import React, { JSX, FC, CSSProperties } from "react";
import { Layout } from "antd";
import HeaderNavBar from "./HeaderNavBar/HeaderNavBar";
import HeaderUserInfo from "./HeaderUserInfo/HeaderUserInfo";
import { Link } from "react-router-dom";
import { ReactComponent as Logo } from "assets/icons/logo-white.svg";

import css from "../Sidebar/style.module.css";
import { APP_PATHES } from "constants/appPathes";

interface IHeaderProps {
  isVendor: boolean;
}

const Header: FC<IHeaderProps> = ({isVendor}): JSX.Element => {
  const headerStyle: CSSProperties = {
    textAlign: "center",
    alignContent: "center",
    height: 64,
    lineHeight: "17px",
    borderBottom: "1px solid var(--main-color-secondary)",
  };

  return (
    <Layout.Header style={headerStyle}>
    <div className="flex justify-between items-center">
      {/*<GlobalSearch/> TODO: пока скрываем*/}
      <Link to={APP_PATHES.PLATFORM}>
        <Logo className={css.sidebar_logo} />
      </Link>
      <div className="flex items-center">
        <HeaderNavBar isVendor={isVendor}/>
        <HeaderUserInfo />
      </div>
    </div>
    </Layout.Header>
  );
};

export default Header;
