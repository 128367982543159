import * as CurrenciesAPI from "api/currencies";
import { ThunkAction } from "redux-thunk";
import { AppStateType } from "redux/reducers/mainReducer";
import { AxiosResponse, CancelToken } from "axios";

import {
  fetchCurrenciesRequest,
  fetchCurrenciesSuccess,
  fetchCurrenciesFailure,
} from "../slices/currenciesSlice";

import { CurrencyResponseType, DefaultPaginationRequestType } from "currencies/types";

/** Получение списка товаров */
export const fetchCurrencies = (
  body?: DefaultPaginationRequestType,
  cancelToken?: CancelToken
): ThunkAction<
  Promise<CurrencyResponseType>,
  AppStateType,
  undefined,
  any
> => async (dispatch) => {
  dispatch(fetchCurrenciesRequest(body));
  try {
    const response: AxiosResponse<CurrencyResponseType, any> = await CurrenciesAPI.getCurrencies(body, cancelToken);

    dispatch(fetchCurrenciesSuccess(response?.data));

    return Promise.resolve(response?.data);
  } catch (error: any) {
    dispatch(fetchCurrenciesFailure(error?.message));

    return Promise.reject(error);
  }
};
