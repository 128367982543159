import { CategoryTreeType, ChildrenCategoryType } from "categories/types";

export type OptionType = {
  id: number;
  value: number;
  label: React.ReactNode;
  parent?: string;
  isLeaf?: boolean;
  loading?: boolean;
  children?: OptionType[];
};

export const transformDataToCategory = (data: CategoryTreeType[] | ChildrenCategoryType[]): OptionType[] => {
  return data?.map((category) => {
    if ("children_ids" in category) {
      const { id, name, children_ids } = category as CategoryTreeType;

      return {
        id: id,
        value: id,
        label: name,
        isLeaf: !children_ids || children_ids.length === 0,
        parent: category.parent,
        children: children_ids.length > 0 ? transformDataToCategory(children_ids) : [],
      };
    } else {
      const { id, name, has_children, parent } = category as ChildrenCategoryType;

      return {
        id: id,
        value: id,
        label: name,
        isLeaf: !has_children,
        parent: parent,
        children: undefined,
      };
    }
  }) || [];
};

export const updateCategory = (categories: OptionType[], id: number, newChildren: OptionType[]): OptionType[] => {
  return categories.map((category: OptionType) => {
    if (category.value === id) {
      return {
        ...newChildren?.[0],
      };
    }

    if (category.children && category.children.length > 0) {
      return {
        ...category,
        children: updateCategory(category.children, id, newChildren),
      };
    }

    return category;
  });
};

export const buildCategoryTreeFromChain = (chainOptions: OptionType[]): OptionType[] => {
  const map: Map<any, OptionType> = new Map<any, OptionType>();
  const result: OptionType[] = [];

  chainOptions
    .map((item: OptionType) => {
      map.set(item.value, item);
      return item;
    })
    .forEach((item: OptionType) => {
      if (item.parent) {
        if (map.has(item.parent)) {
          const parent: OptionType = map.get(item.parent);
          const existingChildIndex: number = parent.children.findIndex((child) => child.value === item.value);

          if (existingChildIndex !== -1) {
            parent.children[existingChildIndex] = map.get(item.value);
          } else {
            parent.children.push(map.get(item.value));
          }
        }
      } else {
        result.push(map.get(item.value));
      }
    });

  return result;
};
