import { ImageType, ModificationType } from "products/types";

export const getFirstImage = (
  modification: ModificationType,
  allowedFormats: string[] = [".jpeg", ".jpg", ".png", ".heic", ".webp"],
): { src: string; filename: string } | null => {
  const lowerCaseFormats = allowedFormats.map((format) => format.toLowerCase());

  const imageMedia: ImageType[] = modification?.images?.filter(({ image }) =>
    lowerCaseFormats.some((format) => image.toLowerCase().includes(format))
  );

  if (imageMedia?.length > 0) {
    return { src: imageMedia[0].image, filename: imageMedia[0].filename };
  }

  return null;
};
