import React, { JSX, FC, useState } from "react";
import { DraggerFileType } from "../DragAndDrop";
import useImageUrl from "hooks/useImageUrl";

// icons
import { ReactComponent as DeleteIcon } from "../../../../assets/icons/delete-icon.svg";
import { ReactComponent as FileImageOutlined } from "../../../../assets/icons/file_image_outlined.svg";

import css from "../style.module.css";

interface IImageContainerProps {
  file: DraggerFileType;
  onClickHandler: (file: any) => void;
}

const ImageContainer: FC<IImageContainerProps> = ({file, onClickHandler}): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);

  const imageUrl: string = useImageUrl(file.name, file.url);

  const borderStyle: string = loading ? "1px dashed #d9d9d9" : "";

  const onErrorHandler = (): void => {
    setLoading(false);
    setIsError(true);
  };

  return (
    <div
      key={file.uid}
      title={file.name}
      className="relative object-cover w-full h-0 rounded-lg pb-[100%]"
      style={{ border: borderStyle }}
    >
      {(loading || isError) && (
        <div className="absolute inset-0 flex flex-col justify-center items-center">
          <FileImageOutlined style={{color: "#d9d9d9"}} />
          <p
            className="mt-2 mx-2"
            style={{overflowWrap: "anywhere"}}
          >
            {loading ? "Загружаем..." : ""}
          </p>
        </div>
      )}
      <img
        onLoad={() => setLoading(false)}
        onError={onErrorHandler}
        src={imageUrl}
        alt=""
        className="object-cover absolute inset-0 w-full h-full rounded-lg"
      />
      <DeleteIcon
        className={`absolute cursor-pointer ${css.deleteIcon}`}
        onClick={() => onClickHandler(file)}
      />
    </div>
  );
};

export default ImageContainer;
