// Разделение тысячных
export const formatPrice = (amount: number): string => {
  const [integer, decimal] = amount.toString().split(".");
  const formattedInteger: string = integer.replace(/\B(?=(\d{3})+(?!\d))/g, " ");

  const formattedDecimal: string = decimal
    ? decimal.replace(/(\d{3})(?=\d)/g, "$1 ")
    : "";

  return formattedDecimal ? `${formattedInteger}.${formattedDecimal}` : formattedInteger;
};
