import { combineReducers } from "redux";
import moderatorCommentsSlice from "redux/slices/moderatorCommentsSlice";
import tempDocumentsSlice from "redux/slices/tempDocumentsSlice";
import importTaskSlice from "redux/slices/importTaskSlice";
import warehousesSlice from "redux/slices/warehousesSlice";
import categoriesSlice from "redux/slices/categoriesSlice";
import productsSlice from "redux/slices/productsSlice";
import currenciesSlice from "redux/slices/currenciesSlice";
import accountSlice from "redux/slices/accountSlice";
import suppliersSlice from "redux/slices/suppliersSlice";

const mainReducer = combineReducers({
  accountSlice,
  suppliersSlice,
  moderatorCommentsSlice,
  tempDocumentsSlice,
  importTaskSlice,
  warehousesSlice,
  categoriesSlice,
  productsSlice,
  currenciesSlice,
});

type MainReducerType = typeof mainReducer;
export type AppStateType = ReturnType<MainReducerType>;

export default mainReducer;
