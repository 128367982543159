import React, { JSX } from "react";
import Column from "antd/es/table/Column";
import { CategoryType } from "categories/types";
import { SorterResult } from "antd/es/table/interface";

export const renderSupplierCategoryColumn = (
  renderColumnTitle: (title: string) => JSX.Element,
  sorter: SorterResult<any>,
): JSX.Element => {
  return (
    <Column
      key="Supplier_category"
      title={renderColumnTitle("Категория поставщика")}
      dataIndex="name"
      width={400}
      onCell={() => ({
        style: {
          whiteSpace: "normal",
          WordWrap: "break-word"
        }
      })}
      sorter={{ multiple: 3 }}
      sortOrder={sorter?.field === "name" ? sorter?.order : undefined}
      showSorterTooltip={false}
      sortDirections={["ascend", "descend"]}
      render={(value: string, entity: CategoryType) => {
        const chain: string = entity?.chain?.includes("\\")
          ? entity?.chain?.replace(/\\[^\\]*$/, "").trim()
          : "";

        const renderChain = (): JSX.Element => {
          return (
            <div>
              {chain && <span className="secondary-color">{chain} \ </span>}
              {value}
            </div>
          );
        };

        return value ? renderChain() : <span>-</span>;
      }}
    />
  );
};
