import * as process from "process";
import axios from "../utils/axios";
import { AxiosResponse } from "axios";
import { SupplierSettingsType } from "suppliers/types";

const API_BASE: string | undefined = process.env.REACT_APP_TP_BASE_URL?.trimEnd();

/** Настройки аккаунта поставщика */
export const getSupplierSettings = async (): Promise<AxiosResponse<SupplierSettingsType>> => {
  return axios.GET(API_BASE, "/api/rest/v1/accounts/supplier/settings/");
};

