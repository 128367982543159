import { FC, useRef, useState } from "react";
import { useDispatch } from "react-redux";

import { createTempDocument } from "redux/thunks/tempDocuments.thunk";

import { UploadOutlined } from "@ant-design/icons";
import { Button, Form, message, Upload } from "antd";

import "./antdStyle.scss";

import { AppDispatch } from "redux/store/store";
import { UploadFile } from "antd/es/upload";

type TemporaryDocumentType = {
  id: string;
  document: string;
  file_size: number;
  original_filename: string;
  created_at: string;
};

interface IFileUploadButton {
  acceptType: string;
  setUploadedFileData?: React.Dispatch<React.SetStateAction<TemporaryDocumentType>>;
  maxFilesCount?: number;
  type?: "link" | "default" | "text" | "primary" | "dashed";
  ghost?: boolean;
  maxSize?: number;
  description?: string;
};

const FileUploadButton: FC<IFileUploadButton> = ({
  acceptType,
  setUploadedFileData,
  maxFilesCount = 1,
  type = "default",
  ghost = false,
  maxSize = 0,
  description,
}) => {
  const dispatch = useDispatch<AppDispatch>();

  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const handleChange = ({ fileList }): void => {
    setFileList(fileList);
  };

  const handleError = (messageText: string, error: any): void => {
    message.error(messageText);
    console.error(new Error(error));
  };

  const customUpload = async (options: any): Promise<void> => {
    const { file, onSuccess, onError } = options;

    const fileExtension: string = file.name.split(".").pop()?.toLowerCase();
    const allowedExtensions: string[] = acceptType.split(",").map(type => type.trim().replace(".", "").toLowerCase());

    const newFile: UploadFile = {
      uid: file.uid,
      name: file.name,
    };

    const isAllowedExtensions: boolean = fileExtension && allowedExtensions.includes(fileExtension);
    const isAllowedSize: boolean = maxSize ? (file.size <= maxSize * 1024 * 1024) : true;
    const hasError: boolean = !isAllowedExtensions || !isAllowedSize;

    if (!isAllowedExtensions) {
      newFile.status = "error";
      newFile.error = {
        message: "Ошибка загрузки файла: неверный формат."
      };
      message.error(`${file.name} — неверный формат. Требуется файл в формате ${allowedExtensions.join(", ")}.`, 5);
    }

    if (!isAllowedSize) {
      newFile.status = "error";
      newFile.error = {
        message: `Максимальный размер файла: ${maxSize} MB`,
      };
      message.error(`${file.name} — превышает максимально допустимый размер: ${maxSize} MB.`, 5);
    }

    if (hasError) {
      const newFileList: UploadFile[] = fileList.filter(({ uid }) => uid !== file.uid);
      setFileList([...newFileList, newFile]);

      return;
    } else {
      dispatch(createTempDocument(file))
        .then((document: TemporaryDocumentType) => {
          setUploadedFileData?.(document);
          setFileList((prevList) => [
            ...prevList,
            {
              uid: document.id,
              name: document.original_filename,
              status: "done",
              url: document.document,
            },
          ]);
          onSuccess(document);
        })
        .catch((error: any) => {
          setFileList((prevList) => [
            ...prevList,
            {
              uid: file.id,
              name: file.original_filename,
              status: "error",
              url: file?.document,
            },
          ]);
          handleError("Ошибка при загрузке документа", error);
          onError(error);
        });
    }
  };

  const downloadHandler = (file: UploadFile): void => {
    const downloadUrl: string = file.response.document;
    const a: HTMLAnchorElement = document.createElement("a");
    a.href = downloadUrl;
    a.download = file.name;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return (
    <Form.Item className="m-0 contents" name="fileField" valuePropName="filelist">
      <Upload
        fileList={fileList}
        onChange={handleChange}
        customRequest={customUpload}
        className="flex flex-col"
        accept={acceptType}
        maxCount={maxFilesCount}
        showUploadList={{
          showDownloadIcon: true,
          showRemoveIcon: true,
        }}
        onDownload={downloadHandler}
      >
        <div className="flex items-center gap-4">
          <Button
            type={type}
            icon={<UploadOutlined />}
            className="align-center"
            size="middle"
            ghost={ghost}
          >
            Выбрать файл
          </Button>
          {description && <p className="ghost">{description}</p>}
        </div>
      </Upload>
    </Form.Item>
  );
};

export default FileUploadButton;
