import React, { JSX, FC, Dispatch } from "react";
import { APP_PATHES, EXTERNAL_PATHES } from "constants/appPathes";
import { NavigateFunction, useNavigate } from "react-router";
import { Tab } from "../TabsWithCounters/TabsWithCounters";

// components
import PrimaryButton from "components/ui/PrimaryButton/PrimaryButton";
import GhostButton from "components/ui/GhostButton/GhostButton";
import EmptyBlock from "../../EmptyBlock/EmptyBlock";

// icons
import { ReactComponent as LoupeIcon } from "assets/icons/search-square-icon.svg";

interface IProductsTableStubProps {
  setActiveTab: Dispatch<React.SetStateAction<string>>;
  style?: React.CSSProperties
}

const ProductsTableStub: FC<IProductsTableStubProps> = ({ setActiveTab, style }): JSX.Element => {
  const navigate: NavigateFunction = useNavigate();

  const handleNavigate = (): void => navigate(APP_PATHES.IMPORT_MASTER);
  return (
    <EmptyBlock
      icon={<LoupeIcon className="mb-4" />}
      style={style || { height: "calc(100vh - 35rem)", minHeight: "400px" }}
      title="Товаров и категорий нет"
      description={
        <>
          Для добавления товаров перейдите в раздел
          <br />
          <span
            className="blue-color cursor-pointer"
            onClick={() => setActiveTab(Tab.uploading)}
          >
            Загруженные файлы
          </span>. Условия для поставщиков
          <br />
          можно посмотреть в <a href="#" className="blue-color hover:blue-color">КБО</a>
        </>
      }
      btn={
        <div className="flex mb-4">
          <PrimaryButton text="Загрузить" onClickHandler={handleNavigate} />
          <a
            rel="noreferrer"
            className="ml-3"
            href={`${EXTERNAL_PATHES.TP_URL}/media/other/Каталог._Инструкция.pdf`}
            target={"_blank"}
          >
            <GhostButton
              size="large"
              text="Инструкция"
              onClickHandler={() => { }}
            />
          </a>
        </div>
      }
      hint="Размещение товаров в каталоге — Бесплатно"
    />
  );
};

export default ProductsTableStub;
