import React, { FC, JSX, useEffect, useState } from "react";
import { useForm } from "antd/es/form/Form";
import useCancelableDebounce from "hooks/useCancelableDebounce";
import axios from "axios";

// types
import { DefaultOptionType } from "antd/es/select";
import { EditPropsType } from "../CatalogTable";
import { CategoryType } from "categories/types";
import { SorterResult } from "antd/es/table/interface";

// redux
import { InitialCategoriesStateType } from "redux/slices/categoriesSlice";
import { useDispatch, useSelector } from "react-redux";
import { AppStateType } from "redux/reducers/mainReducer";
import { AppDispatch } from "redux/store/store";
import { fetchTPCategories } from "redux/thunks/categories.thunk";

// components
import { Form, Typography } from "antd";
import SelectWithButton from "../../../ui/SelectWithButton/SelectWithButton";
import Column from "antd/es/table/Column";

export type ComparisonType = {
  idTPCategory: number;
  nameTPCategory: string;
};

interface IEditControlsProps {
  categoryId: number;
  comparison: ComparisonType;
  editOptions: EditPropsType;
}

const EditControls: FC<IEditControlsProps> = ({
  categoryId,
  comparison,
  editOptions,
}) => {
  const categories: InitialCategoriesStateType = useSelector(
    (state: AppStateType) => state.categoriesSlice
  );

  const { edit, save, editingKey, setEditingKey } = editOptions ?? {};
  const { idTPCategory, nameTPCategory } = comparison ?? {};

  const [form] = useForm<any>();

  const { setFieldsValue } = form;
  const dispatch = useDispatch<AppDispatch>();

  const [options, setOptions] = useState<DefaultOptionType[]>(null);
  const [selectedValue, setSelectedValue] = useState<number>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>(null);

  useEffect(() => {
    if (categories?.categoriesTP?.list) {
      setOptions(categories?.categoriesTP?.list);
      setIsLoading(false);
    }
  }, [categories?.categoriesTP?.list]);

  useEffect(() => {
    searchValue !== null && getTPCategories({ search: searchValue });
  }, [searchValue]);

  useEffect(() => {
    setSelectedValue(idTPCategory);
    setFieldsValue({
      tpCategory: {
        value: idTPCategory,
        label: nameTPCategory
      }});

    options?.length && idTPCategory && setOptions((prev) => prev?.some(({value}) => value === idTPCategory)
      ? prev
      : [...prev, { value: idTPCategory, label: nameTPCategory }]
    );
  }, [editingKey]);

  const { debouncedFunction: getTPCategories } = useCancelableDebounce((params, token) => {
    setIsLoading(true);

    dispatch(fetchTPCategories(params as { search: string }, token))
      .catch((error: any) => !axios.isCancel(error))
      .finally(() => setIsLoading(false));
  });

  const handleDelete = (): void => setEditingKey("");

  const handleEdit = (): void => {
    if (!options?.length) {
      setIsLoading(true);

      idTPCategory && setOptions([{ value: idTPCategory, label: nameTPCategory }]);
    }

    edit(categoryId);
  };

  const handleSelect = (value: number): void => setSelectedValue(value);

  const handleSave = (): void => save(selectedValue);

  const handleSearchCategories = (search: string) => setSearchValue(search);

  return categoryId?.toString() === editingKey ? (
    <Form className="flex items-baseline gap-4 w-full">
      <SelectWithButton
        name="tpCategory"
        value={selectedValue}
        options={options}
        placeholder="Выберите категорию"
        showSearch
        onClickHandler={handleSave}
        onSearchHandler={handleSearchCategories}
        onChangeHandler={handleSelect}
        wrapStyle={{ width: "69%", maxWidth: 330 }}
        selectStyle={{ width: "85%" }}
        isDisabledBtn={isLoading || !selectedValue}
      />
      <Typography.Text
        className="blue-color cursor-pointer"
        onClick={handleDelete}
      >
        Отменить
      </Typography.Text>
    </Form>
  ) : (
    <p
      onClick={handleEdit}
      className="blue-color cursor-pointer"
    >
      Изменить
    </p>
  );
};

export const renderEditableBKCategoryColumn = (
  renderColumnTitle: (title: string) => JSX.Element,
  editOptions: EditPropsType,
  sorter: SorterResult<any>,
): JSX.Element => {
  const { editingKey } = editOptions ?? {};

  return (
    <Column
      key="Editable_bk_category"
      title={renderColumnTitle("Привязка к категории  Бизнес Коннект")}
      dataIndex="category_name"
      width={400}
      sorter={{multiple: 3}}
      sortOrder={sorter?.field === "category_name" ? sorter?.order : undefined}
      showSorterTooltip={false}
      sortDirections={["ascend", "descend"]}
      onCell={() => ({
        style: {
          whiteSpace: "normal",
          WordWrap: "break-word"
        }
      })}
      render={(value: string, record: CategoryType) => {
        const renderEmptyCategory = (): JSX.Element => <span className="danger-color">Отсутствует</span>;

        return (
          <div className="flex items-center">
            {record?.id?.toString() !== editingKey && (
              <div className="mr-3">{value ?? renderEmptyCategory()}</div>
            )}
            <EditControls
              categoryId={record?.id}
              comparison={{ idTPCategory: record?.category, nameTPCategory: record?.category_name }}
              editOptions={editOptions}
            />
          </div>
        );
      }}
    />
  );
};
