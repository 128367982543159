export const serializeSorterFields = (field: string): string => {
  const sorterFields: Record<string, string> = {
    name: "name",
    category_supplier: "supplier_category",
    category_bk: "category",
    modifications: "sort_price",
    status: "status",
    created_at: "created_at",
    updated_at: "updated_at",
    update_period: "update_period",
    source: "source",
    catalog: "catalog",
    category_name: "category_name_sort",
    qty_products: "qty",
  };

  return sorterFields[field];
};
