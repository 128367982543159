import { createSlice } from "@reduxjs/toolkit";
import { CurrencyResponseType, CurrencyType } from "currencies/types";

type DefautDataStateType = {
  error: string | null;
  isFetching: boolean;
};

export interface InitialProductsStateType extends DefautDataStateType {
  list: CurrencyType[];
  count: number;
}

const initialState: InitialProductsStateType = {
  list: null,
  count: null,
  error: null,
  isFetching: false,
};

const currenciesSlice = createSlice({
  name: "currencies",
  initialState,
  reducers: {
    /** Получение списка продуктов
     * - GET /currency/all/ */
    fetchCurrenciesRequest(state, _action) {
      state.error = null;
      state.isFetching = true;
    },
    fetchCurrenciesSuccess(state, action: { payload: CurrencyResponseType; type: any }) {
      state.list = action.payload?.results;
      state.count = action.payload?.total;
      state.error = null;
      state.isFetching = false;
    },
    fetchCurrenciesFailure(state, action) {
      state.list = null;
      state.error = action.payload;
      state.isFetching = false;
    },
  },
});

const { actions, reducer } = currenciesSlice;

export const {
  fetchCurrenciesRequest,
  fetchCurrenciesSuccess,
  fetchCurrenciesFailure
} = actions;

export default reducer;
