export const calcDeclensionByCount = (count: number, words: string[]): string => {
  const a: number = Math.abs(count) % 100;
  const b: number = a % 10;

  if (a > 10 && a < 20) { return words[2]; }
  if (b > 1 && b < 5) { return words[1]; }
  if (b === 1) { return words[0]; }

  return words[2];
};

export const getDeletionWordForm = (count: number, words: [string, string]): string => {
  const lastDigit: number = count % 10; // Последняя цифра числа
  const lastTwoDigits: number = count % 100; // Последние две цифры числа

  if (lastDigit === 1 && lastTwoDigits !== 11) {
    return words[0];
  } else {
    return words[1];
  }
};
