import React, { JSX, FC, useState, useEffect, Dispatch, SetStateAction } from "react";
import { setCategoriesMatchAuto } from "redux/thunks/categories.thunk";
import { useDispatch } from "react-redux";
import { AppDispatch } from "redux/store/store";
import { handleError } from "utils/handleError";
import { formatCategories } from "../../TableFilters/utils/formatCategories";
import { ComparisonFiltersValuesType } from "app/types";
import { CategoryType } from "categories/types";
import { StaticFiltersType } from "../../TableFilters/utils/staticFilters";
import isEqual from "lodash/isEqual";

// components
import FiltersStaticTags from "../../../ui/Filters/FiltersStaticTags/FiltersStaticTags";
import PrimaryButton from "../../../ui/PrimaryButton/PrimaryButton";
import FiltersDrawer from "../../../ui/Filters/FiltersDrawer/FiltersDrawer";
import { Divider, Form, FormInstance, notification } from "antd";
import ComparisonFilters from "../../TableFilters/ComparisonFilters";
import FiltersDrawerTags from "../../../ui/Filters/FiltersDrawerTags/FiltersDrawerTags";
import SearchFilterPanel from "../../SearchFilterPanel/SearchFilterPanel";

interface IComparisonFiltersPanelProps {
  form: FormInstance<ComparisonFiltersValuesType>;
  values: ComparisonFiltersValuesType;
  showAutoMatchBtn: boolean;
  deleteComparisons: (values: CategoryType[]) => void;
  setSearchValue: Dispatch<SetStateAction<string>>;
  selectedRows: CategoryType[];
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  isFiltersConfirmed: boolean;
  isStaticFilterConfirmed: boolean;
  setIsFiltersConfirmed: Dispatch<SetStateAction<boolean>>;
  setIsStaticFilterConfirmed: Dispatch<SetStateAction<boolean>>;
  staticFilters: StaticFiltersType[];
  setStaticFilters: Dispatch<SetStateAction<StaticFiltersType[]>>;
  previousFilters: ComparisonFiltersValuesType;
  setPreviousFilters: Dispatch<SetStateAction<ComparisonFiltersValuesType>>;
}

const ComparisonFiltersPanel: FC<IComparisonFiltersPanelProps> = ({
  form,
  values,
  showAutoMatchBtn,
  deleteComparisons,
  setSearchValue,
  selectedRows,
  setIsLoading,
  isFiltersConfirmed,
  isStaticFilterConfirmed,
  setIsFiltersConfirmed,
  setIsStaticFilterConfirmed,
  staticFilters,
  setStaticFilters,
  previousFilters,
  setPreviousFilters,
}): JSX.Element => {
  const dispatch = useDispatch<AppDispatch>();

  const [isShowFiltersDrawer, setIsShowFiltersDrawer] = useState<boolean>(false);
  const [filters, setFilters] = useState<ComparisonFiltersValuesType>({});

  useEffect(() => {
    if (values && Object.keys(previousFilters)?.length !== Object.keys(values)?.length) {
      setPreviousFilters((prev) => ({ ...prev, ...values }));
    }
  }, [values]);

  useEffect(() => {
    if (isFiltersConfirmed || isStaticFilterConfirmed) {
      setPreviousFilters(values);

      setFilters(() => {
        const cleanObject: ComparisonFiltersValuesType = Object.fromEntries(
          Object.entries(values).filter(([_, value]) => value !== undefined)
        );

        const supplierCategories: Record<string, string> = formatCategories(
          "supplierCategory",
          cleanObject?.supplierCategory
        ) ?? {};

        const categoriesBK: Record<string, string> = formatCategories("categoryBK", cleanObject?.categoryBK) ?? {};

        return {
          ...(cleanObject.qtyProductsFrom
              ? { qtyProductsFrom: `Кол-во товаров от: ${cleanObject.qtyProductsFrom}` }
              : {}
          ),
          ...(cleanObject.qtyProductsTo
              ? { qtyProductsTo: `Кол-во товаров до: ${cleanObject.qtyProductsTo}` }
              : {}
          ),
          ...(Object.keys(supplierCategories)?.length ? { ...supplierCategories } : {}),
          ...(Object.keys(categoriesBK)?.length ? { ...categoriesBK } : {}),
        };
      });
    }
  }, [isFiltersConfirmed, isStaticFilterConfirmed]);

  const handleOnSearch = ({ search }): void => setSearchValue(search);

  const handleOnShowFiltersDrawer = (): void => setIsShowFiltersDrawer(true);

  const resetFilters = (): void => {
    form.resetFields();
    setFilters({});
    setPreviousFilters(values);
  };

  const selectedRowsWithComparisonsCount: number = selectedRows
    ?.filter(({ category }) => Boolean(category))?.length;
  const disabledSubmit: boolean = !Object.keys(values ?? {})?.length || isEqual(previousFilters ?? {}, values ?? {});
  const disabledClearAll: boolean = Object.values(previousFilters).every(
    (value) => !value || (Array.isArray(value) && !value.length)
  );

  const matchAutomatically = (): void => {
    setIsLoading(true);

    dispatch(setCategoriesMatchAuto(0.55))
      .then(() => notification.success({ message: "Категории успешно сопоставлены" }))
      .catch((error) => handleError(
        "При автоматическом сопоставлении категорий произошла ошибка, пожалуйста, повторите позже.",
        error
      ))
      .finally(() => setIsLoading(false));
  };

  return (
    <>
      <div className="flex">
        <div className="flex">
          {/*TODO пока скрываем*/}
          {/*{!!selectedRows?.length && (*/}
          {/*  <PrimaryButton*/}
          {/*    className="mr-3"*/}
          {/*    text={`Удалить (${selectedRows?.length})`}*/}
          {/*    danger*/}
          {/*    onClickHandler={() => showDeleteModal(selectedRows)}*/}
          {/*  />*/}
          {/*)}*/}
          {!!selectedRowsWithComparisonsCount && (
            <PrimaryButton
              className="mr-3"
              danger
              text={`Удалить привязку (${selectedRowsWithComparisonsCount})`}
              onClickHandler={() => deleteComparisons(selectedRows)}
            />
          )}
        </div>
        <SearchFilterPanel
          placeholder="Найти по наименованию категории"
          onSearch={handleOnSearch}
          onShowFiltersDrawer={handleOnShowFiltersDrawer}
        />
      </div>
      <div className="flex justify-between mb-3">
        <FiltersStaticTags
          staticFilters={staticFilters}
          setStaticFilters={setStaticFilters}
          setIsFiltersConfirmed={setIsStaticFilterConfirmed}
          isSingleActiveMode
        />
        {showAutoMatchBtn && (
          <PrimaryButton
            text="Авто. сопоставление категорий"
            size="middle"
            onClickHandler={matchAutomatically}
          />
        )}
        <FiltersDrawer
          form={form}
          isOpen={isShowFiltersDrawer}
          disabledSubmit={disabledSubmit}
          disabledClearAll={disabledClearAll}
          resetFilters={resetFilters}
          setIsFiltersOpen={setIsShowFiltersDrawer}
          setIsFiltersConfirmed={setIsFiltersConfirmed}
        >
          <Form form={form}>
            <ComparisonFilters form={form} values={values}/>
          </Form>
        </FiltersDrawer>
      </div>
      {!!Object.keys(filters)?.length && (
        <Divider type="horizontal" className="m-3"/>
      )}
      <FiltersDrawerTags
        form={form}
        filters={filters}
        setFilters={setFilters}
        resetFilters={resetFilters}
        setIsFiltersConfirmed={setIsFiltersConfirmed}
      />
    </>
  );
};

export default ComparisonFiltersPanel;
