import React, { JSX } from "react";

import Column from "antd/es/table/Column";
import TextWithTooltip from "components/ui/TextWithTooltip/TextWithTooltip";
import { ReactComponent as PaperClipIcon } from "assets/icons/paper-clip.svg";

import { ImportTaskType } from "importTask/types";
import { notification } from "antd";

export const renderFileColumn = (renderColumnTitle: (title: string) => JSX.Element): JSX.Element => {
  const downloadFile = async (url: string, filename: string): Promise<void> => {
    try {
      const response: Response = await fetch(url, { method: "GET" });
      const blob: Blob = await response.blob();
      const link: HTMLAnchorElement = document.createElement("a");

      link.href = window.URL.createObjectURL(blob);
      link.download = filename;
      link.click();
    } catch (error) {
      notification.error({ message: "Ошибка загрузки файла" });
      console.error("Ошибка загрузки файла", error);
    }
  };

  return (
    <Column
      key="File"
      title={renderColumnTitle("Файл")}
      dataIndex="file"
      width={200}
      ellipsis
      render={(_value, entity: ImportTaskType) => (
        <a
          onClick={() => downloadFile(entity?.file_link, entity?.filename)}
          target="_blank"
          rel="noreferrer"
          className="blue-color"
        >
          <PaperClipIcon className="mr-2" style={{ display: "inline" }}/>
          <TextWithTooltip text={entity?.filename} />
        </a>
      )}
    />
  );
};
