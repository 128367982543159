import React, { JSX } from "react";
import Column from "antd/es/table/Column";
import { SorterResult } from "antd/es/table/interface";

export const renderPeriodicityColumn = (
  renderColumnTitle: (title: string) => JSX.Element,
  sorter: SorterResult<any>,
): JSX.Element => {
  return (
    <Column
      key="Periodicity"
      dataIndex="update_period"
      width={110}
      title={renderColumnTitle("Количество обновлений")}
      sorter={{ multiple: 3 }}
      sortOrder={sorter?.field === "update_period" ? sorter?.order : undefined}
      showSorterTooltip={false}
      sortDirections={["ascend", "descend"]}
      render={(value: number) => (
        <div className="text-center">{value || "-"}</div>
      )}
    />
  );
};
