export const DEV_EXTERNAL_PATHES = {
  TP_URL: "https://dev.www.smbconnect.ru",
  IT_URL: "https://dev.it.smbconnect.ru",
  CATALOG_URL: "https://dev.catalog.smbconnect.ru",
};

export const EXTERNAL_PATHES = {
  TP_URL: "https://www.smbconnect.ru",
  IT_URL: "https://it.smbconnect.ru",
  CATALOG_URL: "https://catalog.smbconnect.ru",
};

const TP_PATHES = {
  LOGIN: "/login",
  TRADES: "/trades",
  REPORTS: "/reports",
  WORKFLOW: "/workflow",
  PLATFORM: "/new/platform",
  CART: "/new/platform/cart",
  CATALOG: "/new/platform/catalog",
  SUPPLIERS: "/new/platform/suppliers",
  ORDERS: "/new/platform/orders",
  SMART_BUSINESS: "/new/platform/smart-business",
  SETTINGS: "/new/platform/settings",
  HELP_MAIN: "/help/main_ru",
  CHAT: "/chat",
  WALLET: "/wallet",
};

const CATALOG_PATHES = {
  GENERAL: "/",
  IMPORT: "/import",
  IMPORT_MASTER: "/import-master",
  IMPORT_MASTER_MANUAL: "/import-master/manual",
  IMPORT_MASTER_EXCEL: "/import-master/xls",
  IMPORT_MASTER_YML: "/import-master/yml",
};

export const APP_PATHES = {
  ...CATALOG_PATHES,
  ...TP_PATHES,
};
