import React, { JSX, FC } from "react";

import useImageUrl from "hooks/useImageUrl";
import { getFirstImage } from "components/Products/getFirstImage";
import ImageContainer from "components/ui/ImageContainer/ImageContainer";

import css from "./style.module.scss";

import { ModificationType } from "products/types";
import { Tooltip } from "antd";
import { NavigateFunction, useNavigate, useParams } from "react-router";
import { ImportModeType } from "components/ImportMaster/ImportModePanel";
import { APP_PATHES } from "constants/appPathes";

interface IModificationCardProps {
  modification: ModificationType;
}

const ModificationCard: FC<IModificationCardProps> = ({
  modification,
}): JSX.Element => {
  const navigate: NavigateFunction = useNavigate();
  const { dataId, modId } = useParams<{ mode: ImportModeType, dataId?: string, modId?: string }>();

  const imageUrl: string = useImageUrl(
    getFirstImage(modification)?.filename ?? "",
    getFirstImage(modification)?.src ?? ""
  );

  const isSelected: boolean = modification?.id === Number(modId);
  const maxLength: number = 25;

  const onSelectHandler = (): void => {
    navigate(`${APP_PATHES.IMPORT_MASTER_MANUAL}/${dataId}/${modification?.id}`);
  };

  return (
    <div
      className={`
        modificationCard_wrapper
        flex flex-col w-full bg-white card-radius px-4 py-3 mb-2 cursor-pointer overflow-hidden
        ${css.card} 
        ${isSelected ? css.selected : ""}
      `}
      onClick={onSelectHandler}
      id={modification?.id?.toString()}
    >
      <div className={`flex justify-between items-start ${css.header}`}>
        <div className="flex items-center">
          <div className={css.logo}>
            <ImageContainer
              alt="Фото"
              src={imageUrl}
              key={getFirstImage(modification)?.filename}
            />
          </div>
          <div className={`ml-3 ${css.body}`}>
            <div className="secondary-color">{modification?.code}</div>
            <div className="text-14-m overflow-hidden lg:text-nowrap">
              <Tooltip title={modification?.name?.length > maxLength && modification?.name}>
                <span>{modification?.name?.slice(0, maxLength)}{modification?.name?.length > maxLength && "..."}</span>
              </Tooltip>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModificationCard;
