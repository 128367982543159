import * as process from "process";
import axios from "../utils/axios";
import { AxiosResponse } from "axios";
import { AccountType } from "account/types";

const API_BASE: string | undefined = process.env.REACT_APP_API_BASE_URL?.trimEnd();

/** Параметры аккаунта пользователя */
export const getAccountInfo = async (): Promise<AxiosResponse<AccountType>> => {
  return axios.GET(API_BASE, "/api/v1/account/me/");
};
