import React, { JSX, FC, useState, useEffect, Dispatch, SetStateAction } from "react";
import { ProductsFiltersValuesType, ProductsTagsFiltersType } from "app/types";
import { DefaultOptionType } from "antd/es/select";
import { formatCategories } from "../../TableFilters/utils/formatCategories";
import { ProductType } from "products/types";
import isEqual from "lodash/isEqual";
import isNil from "lodash/isNil";
import { calcDeclensionByCount, getDeletionWordForm } from "utils/сalcDeclensionByCount";
import { formatPrice } from "utils/formatPrice";
import { useSelector } from "react-redux";
import { AppStateType } from "redux/reducers/mainReducer";

// components
import SearchFilterPanel from "../../SearchFilterPanel/SearchFilterPanel";
import FiltersDrawer from "../../../ui/Filters/FiltersDrawer/FiltersDrawer";
import ProductsFilters from "../../TableFilters/ProductsFilters";
import FiltersDrawerTags from "../../../ui/Filters/FiltersDrawerTags/FiltersDrawerTags";
import PrimaryButton from "../../../ui/PrimaryButton/PrimaryButton";
import { Form, FormInstance, Select } from "antd";

import css from "../style.module.css";

interface IUploadedProductsFiltersPanelProps {
  form: FormInstance<ProductsFiltersValuesType>;
  values: ProductsFiltersValuesType;
  selectedRows: ProductType[];
  isLoading: boolean;
  previousFilters: ProductsFiltersValuesType;
  setPreviousFilters: Dispatch<SetStateAction<ProductsFiltersValuesType>>;
  isFiltersConfirmed: boolean;
  showDeleteModal: (values: ProductType[], text: string) => void;
  setSearchValue: Dispatch<SetStateAction<string>>;
  setIsFiltersConfirmed: Dispatch<SetStateAction<boolean>>;
  countProductsForDelete: number;
}

const options: DefaultOptionType[] = [
  { label: "Портал Бизнес Коннект", value: 0 },
  { label: "Вендор Менеджмент", value: 1 }
];

const words: string[] = ["товар", "товара", "товаров"];

const UploadedProductsFiltersPanel: FC<IUploadedProductsFiltersPanelProps> = ({
  form,
  values,
  selectedRows,
  isLoading,
  previousFilters,
  setPreviousFilters,
  isFiltersConfirmed,
  showDeleteModal,
  setSearchValue,
  setIsFiltersConfirmed,
  countProductsForDelete,
}): JSX.Element => {
  const { minPrice, maxPrice } = useSelector((state: AppStateType) => state.productsSlice.products);

  const [isShowFiltersDrawer, setIsShowFiltersDrawer] = useState<boolean>(false);
  const [filters, setFilters] = useState<ProductsTagsFiltersType>({});

  useEffect(() => {
    if (!isNil(minPrice) && !isNil(maxPrice) && previousFilters.price.some((el) => isNil(el))) {
      setPreviousFilters((prev) => ({
        ...prev,
        price: [minPrice, maxPrice]
      }));
    }

    const updatePriceFilter = (newPrice: [number, number]): void => {
      setPreviousFilters((prev) => ({
        ...prev,
        price: newPrice
      }));

      form.setFieldsValue({ price: newPrice });
    };

    // Обработка кейса получения нового диапазона цен и выхода примененной цены из диапазона
    if (values?.price) {
      const [min, max] = values.price;
      const newPrice: [number, number] = [
        min < minPrice ? minPrice : min,
        max > maxPrice ? maxPrice : max
      ];

      if (newPrice[0] !== min || newPrice[1] !== max) {
        updatePriceFilter(newPrice);
      }
    }
  }, [minPrice, maxPrice]);

  useEffect(() => {
    if (values && Object.keys(previousFilters)?.length !== Object.keys(values)?.length) {
      setPreviousFilters((prev) => ({ ...prev, ...values }));
    }
  }, [values]);

  useEffect(() => {
    if (isFiltersConfirmed) {
      setPreviousFilters(values);

      setFilters(() => {
        const cleanObject: ProductsFiltersValuesType = Object.fromEntries(
          Object.entries(values).filter(([_, value]) => value !== undefined)
        );

        const supplierCategories: Record<string, string> = formatCategories(
          "supplierCategory",
          cleanObject?.supplierCategory
        ) ?? {};
        const categoriesBK: Record<string, string> = formatCategories("categoryBK", cleanObject?.categoryBK) ?? {};
        const needAddPrice: boolean = values.price?.[0] !== minPrice || values.price?.[1] !== maxPrice;

        return {
          ...(cleanObject.published ? { published: "Размещен" } : {}),
          ...(cleanObject.draft ? { draft: "Черновик" } : {}),
          ...(cleanObject.error ? { error: "Ошибка" } : {}),
          ...(cleanObject.price && needAddPrice ? {
            price: `Цена: ${formatPrice(cleanObject.price?.[0])} ₽ - ${formatPrice(cleanObject.price?.[1])} ₽`
          } : {}),
          ...(cleanObject.date ? {
            date: `Обновлено: ${values.date?.[0]?.format("DD.MM.YYYY")} - ${values.date?.[1]?.format("DD.MM.YYYY")}`,
          } : {}),
          ...(Object.keys(supplierCategories)?.length ? { ...supplierCategories } : {}),
          ...(Object.keys(categoriesBK)?.length ? { ...categoriesBK } : {}),
        };
      });
    }
  }, [isFiltersConfirmed]);

  const disabledSubmit: boolean = !Object.keys(values ?? {})?.length || isEqual(previousFilters ?? {}, values ?? {});
  const disabledClearAll: boolean = Object.entries(previousFilters).every(([key, value]) => {
    if (key === "price") {
      return value[0] === minPrice && value[1] === maxPrice;
    }

    return !value || (Array.isArray(value) && !value.length);
  });

  const handleOnSearch = ({ search }): void => setSearchValue(search);

  const handleOnShowFiltersDrawer = (): void => setIsShowFiltersDrawer(true);

  const resetFilters = (): void => {
    form.resetFields();
    setFilters({});
    setPreviousFilters(values);
  };

  const confirmText: string = `Будет ${getDeletionWordForm(countProductsForDelete, ["удален", "удалено"])} 
  ${countProductsForDelete} ${calcDeclensionByCount(countProductsForDelete, words)} без возможности восстановления`;

  return (
    <>
      <div className="flex">
        {!!selectedRows?.length && (
          <PrimaryButton
            className="mr-3"
            isDisabled={isLoading}
            text={`Удалить (${countProductsForDelete})`}
            onClickHandler={() => showDeleteModal(selectedRows, confirmText)}
          />
        )}
        <Select
          size="large"
          options={options}
          className={`mr-3 ${css.select}`}
          defaultValue={options[0].value}
          disabled={true} // TODO: пока блокируем выбор каталога
        />
        <SearchFilterPanel
          placeholder="Найти по наименованию товара"
          onSearch={handleOnSearch}
          onShowFiltersDrawer={handleOnShowFiltersDrawer}
        />
        <FiltersDrawer
          form={form}
          isOpen={isShowFiltersDrawer}
          disabledSubmit={disabledSubmit}
          disabledClearAll={disabledClearAll}
          resetFilters={resetFilters}
          setIsFiltersOpen={setIsShowFiltersDrawer}
          setIsFiltersConfirmed={setIsFiltersConfirmed}
        >
          <Form form={form}>
            <ProductsFilters form={form} values={values}/>
          </Form>
        </FiltersDrawer>
      </div>
      <FiltersDrawerTags
        form={form}
        filters={filters}
        setFilters={setFilters}
        resetFilters={resetFilters}
        setIsFiltersConfirmed={setIsFiltersConfirmed}
        defaultValues={{ price: [minPrice, maxPrice] }}
      />
    </>
  );
};

export default UploadedProductsFiltersPanel;
