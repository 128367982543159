import { FC, useState, useEffect, useCallback } from "react";
import { useWatch } from "antd/es/form/Form";

import {
  FormInstance,
} from "antd";
import PanelLayout, { IPanelLayoutProps } from "components/ui/Panel/PanelLayout";

import { ImportMasterFormType } from "pages/ImportMasterPage";
import useFormInstance from "antd/es/form/hooks/useFormInstance";
import FileUploadBlock from "./FileUploadBlock";
import SettingsBlock from "./SettingsBlock";

export const SETTINGS_TYPE = {
  isPeriodic: "is_periodic",
  updateRest: "rests",
  updatePrice: "prices",
  updateProperty: "attributes",
  clearProducts: "clear_existing",
  firstLineSkip: "first_line_skip",
  logIn: "log_in",
};

interface IImportYMLProps {
  initialSettings?: any;
}

const ImportYML: FC<IImportYMLProps> = () => {
  const form: FormInstance<ImportMasterFormType> = useFormInstance();
  const values = useWatch<ImportMasterFormType>([], form);

  const PANEL_LAYOUT_PROPS: IPanelLayoutProps = {
    title: { content: "Загрузка через YML", size: "large" },
    description: {
      content: <>
        YML (Yandex Market Language) - это стандарт, разработанный Yandex
        для приема и публикации информации в базе данных Yandex.Market.
        <a
          className="blue-color pl-2"
          href="https://yandex.ru/support/partnermarket/export/yml.html"
          target="blank"
        >
          Подробнее
        </a>
      </>
    },
    className: "flex flex-col md:flex-row gap-5"
  };

  const [uploadMode, setUploadMode] = useState<"file" | "url">("file");
  const [uploadSettings, setUploadSettings] = useState<any[]>([]);

  useEffect(() => {
    !!uploadSettings && form.setFieldValue("settings", uploadSettings.map((value) => value));
  }, [form, uploadSettings]);

  return (
    <PanelLayout {...PANEL_LAYOUT_PROPS}>
      <FileUploadBlock
        needAuth={values?.settings?.includes(SETTINGS_TYPE.logIn)}
        isPeriodic={values?.settings?.includes(SETTINGS_TYPE.isPeriodic)}
        setUploadMode={setUploadMode}
        uploadMode={uploadMode}
      />
      <SettingsBlock uploadMode={uploadMode} />
    </PanelLayout>
  );
};

export default ImportYML;