import dayjs from "dayjs";

export const formatDateRange = (
  fieldNames: [string, string],
  range?: [dayjs.Dayjs, dayjs.Dayjs]
): Record<string, string> => range
  ? {
    [fieldNames[0]]: range?.[0]?.format("YYYY-MM-DD"),
    [fieldNames[1]]: range?.[1]?.format("YYYY-MM-DD")
  }
  : {};
