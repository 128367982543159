import { AxiosResponse } from "axios";
import { KboOrEulaType } from "terms/types";
import axios from "utils/axios";

const TP_API_BASE: string | undefined = process.env.REACT_APP_TP_BASE_URL?.trimEnd();

/** Запрос КБО */
export const getKbo = async (): Promise<any> => {
  const response = await axios.GET(
    TP_API_BASE, "/api/rest/v1/kbo/get_kbo/",
  );

  return parseKboOrEula(response.data);
};

/** Запрос EULA */
export const getEula = async (): Promise<any> => {
  const response = await axios.GET(
    TP_API_BASE, "/api/rest/v1/kbo/get_eula/",
  );

  return parseKboOrEula(response.data);
};

/** Запрос документа по id */
export const getOtherDocument = async (docId: number): Promise<any> => {
  const response: AxiosResponse<KboOrEulaType> = await axios.GET(
    TP_API_BASE, `/api/rest/v1/kbo/${docId}/get_other_doc/`,
  );

  return parseKboOrEula(response.data);
};

/** Подтверждение КБО */
export const postAgreeKbo = async (): Promise<any> => {
  const response = await axios.POST(
    TP_API_BASE, "/api/rest/v1/account/agree_kbo/",
  );

  return parseKboOrEula(response.data);
};

/** Подтверждение EULA */
export const postAgreeEula = async (): Promise<any> => {
  const response = await axios.POST(
    TP_API_BASE, "/api/rest/v1/account/agree_eula/",
  );

  return parseKboOrEula(response.data);
};

export const getOtherDoc = async (otherDocId: number): Promise<any> => {
  const response = await axios.GET(
    TP_API_BASE, `/api/rest/v1/kbo/${otherDocId}/get_other_doc/`
  );

  return parseKboOrEula(response.data);
};

export const postOtherDoc = async (otherDocId: number, otherDocResponse: string): Promise<any> => {
  const response = await axios.POST(
    TP_API_BASE, `/api/rest/v1/kbo/${otherDocId}/response/`, { response: otherDocResponse }
  );

  return parseOtherDocResponse(response.data);
};

const parseKboOrEula = (data): KboOrEulaType => ({
  id: data.id,
  docType: data.doc_type,
  otherDocsResponseOptions: data?.other_docs_response_options?.split(","),
  document: data.document,
  version: data.version,
  dateStart: data.date_start,
  dateEnd: data.date_end,
  status: data.status,
  createdAt: data.created_at,
  title: data.title,
  docText: data.doc_text,
  link: data.link,
  hideForSuppliers: data.hide_for_suppliers,
  changeUser: data.change_user,
});

const parseOtherDocResponse = (data) => ({
  result: data.result,
});
