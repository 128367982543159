import { AxiosResponse } from "axios";
import { ThunkAction } from "redux-thunk";
import * as cookieControls from "utils/cookieUtils";
import { AppStateType } from "../reducers/mainReducer";
import * as AccountAPI from "api/account";
import * as TermsAPI from "api/terms";
import {
  fetchAccountRequest,
  fetchAccountSuccess,
  fetchAccountFailure,

  checkSiteTermsRequest,
  checkSiteTermsSuccess,
  checkSiteTermsFailure,

  checkOtherDocsRequest,
  checkOtherDocsSuccess,
  checkOtherDocsFailure,

  agreedKboRequest,
  agreedKboSuccess,
  agreedKboFailure,

  agreedEulaRequest,
  agreedEulaSuccess,
  agreedEulaFailure,
  
  agreedOtherDocRequest,
  agreedOtherDocSuccess,
  agreedOtherDocFailure,

  handleLogout,
} from "../slices/accountSlice";
import { AccountType } from "account/types";
import { KboOrEulaType } from "terms/types";

/** Параметры аккаунта пользователя */
export const fetchAccount = (): ThunkAction<
  Promise<AccountType>,
  AppStateType,
  undefined,
  any
> => async (dispatch) => {
  dispatch(fetchAccountRequest());
  try {
    const response: AxiosResponse<{name: string, email: string}, any> =
      await AccountAPI.getAccountInfo();

    dispatch(fetchAccountSuccess(response?.data));

    return Promise.resolve(response?.data);
  } catch (error: any) {
    dispatch(fetchAccountFailure(error?.message));

    return Promise.reject(error);
  }
};

export const checkSiteTerms = (otherDocsIds: number[] = []): ThunkAction<
  Promise<Record<string, KboOrEulaType | KboOrEulaType[]>>,
  AppStateType,
  undefined,
  any
> => async (dispatch) => {
  dispatch(checkSiteTermsRequest());

  try {
    const kbo: KboOrEulaType = await TermsAPI.getKbo();
    const eula: KboOrEulaType = await TermsAPI.getEula();
    const documents: KboOrEulaType[] = otherDocsIds?.length 
      ? await dispatch(fetchOtherDocuments({ docIds: otherDocsIds }))
      : [];

    dispatch(checkSiteTermsSuccess({ kbo, eula, documents }));

    return Promise.resolve({ kbo, eula, documents });
  } catch (error) {
    dispatch(checkSiteTermsFailure);

    return Promise.reject(error);
  }
};

export const fetchOtherDocuments = ({
  docIds
}): ThunkAction<
  Promise<KboOrEulaType[]>,
  AppStateType,
  undefined,
  any
> => async (dispatch) => {
  dispatch(checkOtherDocsRequest());
  try {
    const documents: KboOrEulaType[] = await Promise.all(docIds?.map(async (id) =>
      new Promise((resolve, reject) => {
        const data: Promise<KboOrEulaType> = TermsAPI.getOtherDocument(id);

        data ? resolve(data) : reject(data);

        return data;
      })
    ));

    dispatch(checkOtherDocsSuccess(documents));

    return Promise.resolve(documents);
  } catch (error) {
    dispatch(checkOtherDocsFailure(error));

    return Promise.reject(error);
  }
};

/** Подтверждение КБО */
export const agreedKbo = (): ThunkAction<
  Promise<any>,
  AppStateType,
  undefined,
  any
> => async (dispatch) => {
  dispatch(agreedKboRequest());
  try {
    const response = await TermsAPI.postAgreeKbo();

    dispatch(agreedKboSuccess(response?.data || response));

    return Promise.resolve(response?.data || response);
  } catch (error) {
    dispatch(agreedKboFailure(error));
    return Promise.reject(error);
  }
};

/** Подтверждение EULA */
export const agreedEula = (): ThunkAction<
  Promise<any>,
  AppStateType,
  undefined,
  any
> => async (dispatch) => {
  dispatch(agreedEulaRequest());
  try {
    const response = await TermsAPI.postAgreeEula();

    dispatch(agreedEulaSuccess());

    return Promise.resolve(response);
  } catch (error) {
    dispatch(agreedEulaFailure(error));
    return Promise.reject(error);
  }
};

/** Подтверждение документа */
export const agreedOtherDoc = ({
  id,
  otherDocResponse
}): ThunkAction<
  Promise<any>,
  AppStateType,
  undefined,
  any
> => async (dispatch) => {
  dispatch(agreedOtherDocRequest());

  try {
    const response: AxiosResponse<KboOrEulaType> = await TermsAPI.postOtherDoc(id, otherDocResponse);

    dispatch(agreedOtherDocSuccess(id));
  
    return Promise.resolve(response?.data || response);
  } catch (error) {
    dispatch(agreedOtherDocFailure(error));

    return Promise.reject(error);
  }
};

/** Деавторизация */
export const logout = (): ThunkAction<
  Promise<any>,
  AppStateType,
  undefined,
  any
> => async (dispatch) => {
  try {
    cookieControls.remove("authtoken");

    dispatch(handleLogout());
  
    return Promise.resolve();
  } catch (error) {
    console.error(error);

    return Promise.reject(error);
  }
};

