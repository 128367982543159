import { SorterResult } from "antd/es/table/interface";
import { serializeSorterFields } from "./serializeSorterFields";

export const generateSorter = (sorter: SorterResult<any> | SorterResult<any>[]): string[] => {
  if (!sorter) {
    return [];
  }

  if (Array.isArray(sorter)) {
    return sorter.map((item) => generateSorter(item))?.flat();
  } else if (sorter?.field && sorter?.order) {
    const sortOrder: string = sorter.order === "descend" ? "-" : "";

    return [`${sortOrder}${serializeSorterFields(sorter.field as string)}`];
  }

  return null;
};

export default generateSorter;
