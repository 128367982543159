import React, { JSX, FC, useState, useEffect } from "react";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { ComparisonFiltersValuesType } from "app/types";

import { Collapse, CollapseProps, Form, FormInstance, Input } from "antd";
import FiltersCheckbox from "./FiltersCheckbox";
import FilterWrapper from "../../ui/Filters/FiltersDrawer/FilterWrapper";
import CategoryFilters from "./CategoryFilters";

interface IComparisonFiltersProps {
  form: FormInstance<ComparisonFiltersValuesType>;
  values: ComparisonFiltersValuesType;
}

const ComparisonFilters: FC<IComparisonFiltersProps> = ({ form, values }): JSX.Element => {
  const [isMatched, setIsMatched] = useState<boolean>(false);
  const [isNotMatched, setIsNotMatched] = useState<boolean>(false);

  useEffect(() => {
    setIsMatched(values?.isMatched);
    setIsNotMatched(values?.isNotMatched);
  }, [values?.isMatched, values?.isNotMatched]);

  const renderCheckboxes = (): JSX.Element | JSX.Element[] => {
    const handleCheckboxChange = (field: "isMatched" | "isNotMatched", checked: boolean): void => {
      const resetFieldKey: string = field === "isMatched" ? "isNotMatched" : "isMatched";

      form.setFieldsValue({
        [field]: checked,
        ...(checked ? { [resetFieldKey]: false } : {}),
      });

      if (field === "isMatched") {
        setIsMatched(checked);
        checked && setIsNotMatched(false);
      } else {
        setIsNotMatched(checked);
        checked && setIsMatched(false);
      }
    };

    return (
      <>
        <FiltersCheckbox
          key={1}
          label="Да"
          name="isMatched"
          checked={isMatched}
          handleChange={(e: CheckboxChangeEvent) => handleCheckboxChange("isMatched", e.target.checked)}
        />
        <FiltersCheckbox
          key={2}
          label="Нет"
          name="isNotMatched"
          checked={isNotMatched}
          handleChange={(e: CheckboxChangeEvent) => handleCheckboxChange("isNotMatched", e.target.checked)}
        />
      </>
    );
  };

  const collapseItems: CollapseProps["items"] = [
    {
      key: "1",
      label: "По наличию привязки к категории БК",
      children: renderCheckboxes(),
      className: "p-0 font-bold",
    },
  ];

  return (
    <div>
      <Collapse
        ghost
        defaultActiveKey={["1"]}
        items={collapseItems}
        bordered={false}
        expandIconPosition="end"
        className="mb-5"
      />
      <FilterWrapper label="По кол-ву товаров в категории">
        <div className="flex justify-between gap-2">
          <Form.Item className="m-0" name="qtyProductsFrom" initialValue="">
            <Input
              size="large"
              placeholder="От"
            />
          </Form.Item>
          <Form.Item className="m-0" name="qtyProductsTo" initialValue="">
            <Input
              size="large"
              placeholder="До"
            />
          </Form.Item>
        </div>
      </FilterWrapper>
      <CategoryFilters/>
    </div>
  );
};

export default ComparisonFilters;
