import React, { JSX } from "react";
import Column from "antd/es/table/Column";
import { Tag } from "antd";
import { EntityType } from "../CatalogTable";
import { ImportTaskType } from "importTask/types";
import { SorterResult } from "antd/es/table/interface";

export const renderFileTypeColumn = (
  renderColumnTitle: (title: string) => JSX.Element,
  sorter: SorterResult<any>,
  onClickHandler?: (...args: any[]) => void,
): JSX.Element => {
  return (
    <Column
      key="Source"
      title={renderColumnTitle("Тип файла")}
      dataIndex="source"
      width={90}
      sorter={{ multiple: 3 }}
      sortOrder={sorter?.field === "source" ? sorter?.order : undefined}
      showSorterTooltip={false}
      sortDirections={["ascend", "descend"]}
      render={(value, entity: EntityType) => {
        const { id, status, source, is_periodic } = entity as ImportTaskType || {};

        const isNavigateToEdit: boolean = "is_periodic" in entity && !is_periodic && status === "Черновик";

        return (
          <Tag
            color="default"
            className={isNavigateToEdit && !!onClickHandler ? "cursor-pointer" : ""}
            onClick={() => isNavigateToEdit && onClickHandler?.(id, source)}
          >
            {value}
          </Tag>
        );
      }}
    />
  );
};
