import { FC } from "react";

import {
  Input,
  Form,
} from "antd";
import { LinkOutlined } from "@ant-design/icons";
import AuthSettingsBlock from "../AuthSettingsBlock";

import css from "../style.module.scss";

interface IUploadPeriodicBlockProps {
  isPeriodic: boolean;
  needAuth: boolean;
}

const UploadPeriodicBlock: FC<IUploadPeriodicBlockProps> = ({ isPeriodic, needAuth }) => {
  return (
    <div className="flex flex-col gap-2 w-full">
      <div className="upload_wrapper">
        <div className="input_wrapper">
          <p><span className={css.red}>*</span> Ссылка на файл YML</p>
          <Form.Item className="m-0" name="fileLink">
            <Input
              className="upload_input"
              placeholder="Введите ссылку на файл YML"
              suffix={<LinkOutlined style={{ color: "rgba(0,0,0,.45)" }} />}
              size="large"
            />
          </Form.Item>
        </div>

        {isPeriodic && (
          <p className="ghost">Файл загружается каждые 6 часов</p>
        )}
      </div>
      <AuthSettingsBlock needAuth={needAuth} />
    </div>
  );
};

export default UploadPeriodicBlock;