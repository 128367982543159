import React, { JSX, FC } from "react";
import { Checkbox, Form } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";

interface IFiltersCheckboxProps {
  label: string;
  name: string;
  checked: boolean;
  handleChange: (e: CheckboxChangeEvent ) => void;
}

const FiltersCheckbox: FC<IFiltersCheckboxProps> = ({
  label,
  name,
  checked,
  handleChange,
}): JSX.Element => {
  return (
    <Form.Item name={name} className="my-0" initialValue={false}>
      <Checkbox onChange={handleChange} checked={checked}>
        <span className="font-normal">{label}</span>
      </Checkbox>
    </Form.Item>
  );
};

export default FiltersCheckbox;
