import React, { FC } from "react";
import { Layout } from "antd";
import Sidebar from "components/Sidebar/Sidebar";
import Header from "components/Header/Header";
import { EXTERNAL_PATHES } from "constants/appPathes";

interface ILayoutProps {
  children: React.ReactNode;
}

const MainLayout: FC<ILayoutProps> = ({ children }) => {
  const host: string = window.location.host;
  const vendorUrl: URL = new URL(EXTERNAL_PATHES.IT_URL);
  const isVendor: boolean = host.includes(vendorUrl.host);

  return (
    <Layout className="min-h-screen">
      <Layout>
        <Layout>
          <Header isVendor={isVendor} />
        </Layout>
        <Layout>
          <Sidebar isVendor={isVendor} />
          <Layout.Content className="bg-white">{children}</Layout.Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default MainLayout;
