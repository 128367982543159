import React, { JSX, ReactNode } from "react";

import Column from "antd/es/table/Column";
import { ImportTaskType } from "importTask/types";

export const renderActionsColumn = (renderActions: (entity: any, idx: number) => ReactNode): JSX.Element => {
  return (
    <Column
      key="Action"
      title=""
      dataIndex="action"
      width={100}
      fixed="right"
      render={(
        _value: string,
        entity: ImportTaskType,
        index: number
      ) => {
        const isShow = () => {
          if ("is_periodic" in entity && !entity.is_periodic) {
            return entity?.status === "Черновик";
          }

          return !("category_name" in entity && !entity.category_name);
        };

        return isShow() && renderActions((entity as ImportTaskType), index);
      }}
    />
  );
};
